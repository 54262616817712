import React, { Component } from 'react'
import { mergeAccount } from '../../redux/actions/accounts.js'
import ConfirmationButton from './components/ConfirmationButton.jsx'
import AccountSelect from '../../components/select/AccountSelect.jsx'
import { connect } from 'react-redux'
import { fetchAccount } from '../../redux/actions/accounts.js'
import { redirectTo } from '../../helpers/redirectTo.js'
import Spinner from 'react-md-spinner';
import Select from 'react-select'

class AccountMergeView extends Component {
  constructor(props) {
    super(props)

    this.state = { mergeIntoId: null }
  }

  onChange = (name, value) => {
    this.setState({ [name]: value.value })
  }

  componentDidMount() {
    const { dispatch, match: { params: { id } } }  = this.props
    dispatch(fetchAccount(id))
  }

  onSuccess = () => {
    const { mergeIntoId } = this.state
    alert('Merge Successful')
    redirectTo(`/accounts/${mergeIntoId}`)
  }

  handleMerge() {
    const { mergeIntoId } = this.state
    const { account, dispatch } = this.props
    const onError   = (error)    => alert('Merge Failed: ' + error.responseJSON['account'].join(', '))
    dispatch(mergeAccount(account.id, mergeIntoId, this.onSuccess, onError))
  }

  render() {
    const { mergeIntoId } = this.state
    const { account } = this.props

    return (
      account.id ?
      <div>
        <div className='row'>
          <h4>MERGE ACCOUNT {account.name}</h4>
          <div className='small-12 columns'>
            <label>
              <Select value={{ label: account.name, value: account.id }} disabled={true} />
            </label>
          </div>
        </div>
        <br />
        <div className='row'>
          <div className='small-12 columns'>
            <label>
              Select Account to merge into. (!Non-reversible action!)
              <AccountSelect name='mergeIntoId' onChange={this.onChange} value={mergeIntoId} />
            </label>
          </div>
        </div>
        <br />
        <div className='row'>
          <div className='small-12 columns'>
            <ConfirmationButton
              _key={mergeIntoId}
              className='tiny'
              buttonText='Merge'
              confirmationText='MERGE'
              onSubmit={e => {
                this.handleMerge()
              }}
            />
          </div>
        </div>
      </div>
      : <Spinner />
    )
  }

}

const mapStateToProps = (state) => {
  return {
    account: state.accounts.current.account
  }
}
export default connect(mapStateToProps, )(AccountMergeView)
import ReactTable from 'react-table'

const AccountsTableComponent = (props) => {
  const { data, columns, limit } = props
  return (
    <div>
      <ReactTable className='-striped -highlight' data={data} columns={columns} showPagination={false} defaultPageSize={limit} />
    </div>
  )
}

export default AccountsTableComponent

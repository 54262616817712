import React from 'react'
import PlacesAutocomplete, { renderGeocodeSuccess, getLatLng, geocodeByAddress, geocodeByPlaceId } from 'react-places-autocomplete'

const GooglePlaceSelect = (props) => {
  const { name, value, lengthThreshold, onChange, onSelect, onBlur  } = props

  const handleChange = value => {
    onChange(name, value)
  }

  const handleBlur = () => {
    onBlur(name, true)
  }

  const handleSelect = (address, placeId) => {
    geocodeByPlaceId(placeId)
      .then(results => {
        var addressHash = {};
        results[0].address_components.forEach(function(addressComponent){
          addressComponent.types.forEach(function(type){
            if (type == "street_number") {addressHash.streetNumber = addressComponent.long_name}
            if (type == "route") {addressHash.street = addressComponent.long_name}
            if (type == "locality") {addressHash.city = addressComponent.long_name}
            if (type == "administrative_area_level_1") {addressHash.state = addressComponent.long_name}
            if (type == "postal_code") {addressHash.zip = addressComponent.long_name}
            if (type == "neighborhood") {addressHash.neighborhood = addressComponent.long_name}
            if (type == "administrative_area_level_2") {addressHash.county = addressComponent.long_name}
            if (type == "country") {addressHash.country = addressComponent.long_name}
          });
        });
        if (addressHash.streetNumber != null && addressHash.street != null) {addressHash.line1 = addressHash.streetNumber + " " + addressHash.street};
        if (addressHash.neighborhood == null) {addressHash.neighborhood = addressHash.city};

        handleChange(address)
        onSelect(addressHash)
      })
  }


  const inputProps = { name, value, onChange: handleChange, onBlur: handleBlur, autoComplete: "new-password"  }

  const myStyles = {
    root: {
      position: 'relative',
      paddingBottom: '0px',
    },
    input: {
      display: 'inline-block',
      width: '100%',
      padding: '10px',
    },
    autocompleteContainer: {
      position: 'absolute',
      top: '100%',
      backgroundColor: '#FAFAFA',
      backgroundImage: 'url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zd…J2ZSI+PHBvbHlnb24gcG9pbnRzPSI1Ljk5MiwwIDIuOTkyLDMgLTAuMDA4LDAgIi8+PC9zdmc+)',
      backgroundPosition: '100% center',
      borderTop: '1px solid #CCC',
      borderBottom: '2px solid #CCC',
      borderLeft: '2px solid #CCC',
      borderRight: '1px solid #CCC',
      width: '100%',
      zIndex: '1000'
    },
    acContainer: {
      position: 'absolute',
      top: '100%',
      backgroundColor: 'white',
      border: '1px solid #555555',
      width: '100%',
      zIndex: '1000'
    },
    autocompleteItem: {
      backgroundColor: '#ffffff',
      padding: '10px',
      color: '#555555',
      cursor: 'pointer',
    },
    autocompleteItemActive: {
      backgroundColor: '#fafafa'
    },
  }

  return (
    <PlacesAutocomplete
      styles={myStyles}
      inputProps={inputProps}
      onSelect={handleSelect}
      onEnterKeyDown={handleSelect}
      onError={(status, clear) => { console.log(status); clear() }}
      shouldFetchSuggestions={({ value }) => value.length > lengthThreshold}
    />
  )
}

class Wrapper extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    return <GooglePlaceSelect {...this.props} />
  }
}

export default Wrapper

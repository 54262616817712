const initialState = {
  current: { 
    contact: null,
  },
  all: {
    loading: false,
    contacts: [],
    offset: 0,
    totalCount: 0
  },
}

export function contacts(state = initialState, action) {
  const { payload } = action
  const { current, all } = state

  switch (action.type) {
    case 'FETCH_CONTACTS_REQUEST':
      return { ...state, all: { ...all, loading: true } };

    case 'FETCH_CONTACTS_SUCCESS':
      const { contacts, count, offset } = payload
      return { ...state, all: { contacts: contacts.map(contact => ({ ...contact.attributes })), totalCount: count, offset, loading: false } };

    case 'CLEAR_CONTACTS_OFFSET':
      return { ...state, all: { ...all, offset: 0, totalCount: 0 } };

    default:
      return state;
  }
}
const comment = 'LT L'
const long = 'YYYY-MM-DD[T]HH:mm:ss'
const full_with_utc = 'YYYY-MM-DD HH:mm Z'
const full_12 = 'YYYY-MM-DD HH:mm A'
const full_12_other = 'MMMM Do YYYY h:mm:ss a'
const full = 'YYYY-MM-DD HH:mm'
const base = 'YYYY-MM-DD'
const short_date = 'MMM D'
const short_time = 'HH:mm'
const short_time_12 = 'HH:mm A'
const short_lower_time_12 = 'hh:mm A'
const short_time_with_seconds = 'HH:mm:ss'
const short_weekday = 'dddd'

export {
  comment,
  full_with_utc,
  full_12,
  full_12_other,
  full,
  base,
  short_date,
  short_time,
  short_weekday,
  short_time_12,
  short_lower_time_12,
  short_time_with_seconds,
  long
}

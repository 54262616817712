const initialState = {
  current: {
    serviceException: {},
    serviceExceptions: [],
  },
  all: {
    loading: false,
    offset: 25,
    totalCount: 0,
    page: 0
  },
}

export function serviceExceptions(state = initialState, action) {
  const { payload } = action
  const { current, all } = state

  switch (action.type) {
    case 'FETCH_SERVICE_EXCEPTIONS_REQUEST':
      return { ...state, all: { ...all, loading: true } };

    case 'FETCH_SERVICE_EXCEPTIONS_SUCCESS':
      const serviceExceptions = payload.service_exceptions

      return {...state, current: {...current, serviceExceptions: serviceExceptions}, all: { ...all, loading: false}};

    case 'FETCH_SERVICE_EXCEPTION_REQUEST':
      return { ...state, all: { ...all, loading: true } };

    case 'FETCH_SERVICE_EXCEPTION_SUCCESS':
      const serviceException = payload.service_exception

      return { ...state, current: { ...current, serviceException}, all: { ...all, loading: false } };

    default:
      return state;
  }
}
const dayNumbersOptions = [
  { value: 1, label: 'Monday' },
  { value: 2, label: 'Tuesday' },
  { value: 3, label: 'Wednesday' },
  { value: 4, label: 'Thursday' },
  { value: 5, label: 'Friday' },
  { value: 6, label: 'Saturday' },
  { value: 0, label: 'Sunday' }
]

const paymentMethods = {
  check: 0,
  cash: 10
}
const timeZone = 'America/Los_Angeles'


export {
  dayNumbersOptions,
  paymentMethods,
  timeZone
}
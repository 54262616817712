import React, { Component } from 'react'
import { connect } from 'react-redux'
import {fetchCurrentUser} from "../../redux/actions/users"
import {fetchOrderPayment} from "../../redux/actions/payments"

class PaymentsShowView extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    const { dispatch, match } = this.props

    const orderId = match.params.orderId
    const paymentId = match.params.id

    dispatch(fetchCurrentUser())
    dispatch(fetchOrderPayment(orderId, paymentId))
  }

  render() {
    if (!this.props.payment) return null

    const { payment } = this.props

    return (
      <div className='PaymentsShowView'>
        <img className='checkImage' src={payment.check_image} />
      </div>
    )
  }
}

const mapStateToProps = (state, _props) => {
  const currentUser = state.currentUser.user
  const payment = state.payments.current
  const { order } = state.orders.current

  return {
    currentUser,
    order,
    payment
  }
}
const connectedPaymentsShowView = connect(mapStateToProps,)(PaymentsShowView)
export default connectedPaymentsShowView
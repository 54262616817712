import $ from 'jquery'
import Routes from '../../routes.js.erb'

function fetchWarehousesRequest() {
  return {
    type: 'FETCH_WAREHOUSES_REQUEST',
  }
}

function fetchWarehousesSuccess(response) {
  return {
    type: 'FETCH_WAREHOUSES_SUCCESS',
    payload: response,
  }
}

function fetchWarehousesFailure(response) {
  return {
    type: 'FETCH_WAREHOUSES_FAILURE',
    error: response.error,
  }
}

export function fetchWarehouses() {
  const url = Routes.warehouses_path({format: 'json'})

  return (dispatch) => {
    dispatch(fetchWarehousesRequest());

    $.getJSON(url)
      .then(json => { dispatch(fetchWarehousesSuccess(json)) })
      .catch(response => dispatch(fetchWarehousesFailure(response)));
  }
}
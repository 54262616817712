import React, {Component} from "react"

class UserSignInView extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    return (
      <div>
        <div className="row">
          <br />
          <div className="small-12 columns">
            <h3 className="subheader">Log In</h3>
          </div>
        </div>
        <div className="row">
          <br />
          <div className="small-12 columns">
            <div className="signup-panel">
              <div className="row collapse">
                <div className="small-2 columns">
                  <span className="prefix"><i className="fi-mail"></i></span>
                </div>
                <div className="small-10  columns">
                  <input name="user[email]" type="text" placeholder="email" />
                </div>
              </div>
              <div className="row collapse">
                <div className="small-2 columns ">
                  <span className="prefix"><i className="fi-lock"></i></span>
                </div>
                <div className="small-10 columns ">
                  <input name="user[password]" type="password" placeholder="password" />
                </div>
              </div>
              <div className="row collapse">
                <div className="small-2 columns ">
                  <span className="prefix"><i className="fi-foot"></i></span>
                </div>
                <div className="small-10 columns ">
                  <select name="user[remember_me]" defaultValue="true" >
                    <option value="true">Yes, remember me.</option>
                    <option value="false">No, don't remember me.</option>
                  </select>
                </div>
              </div>
              <a href="/users/saml/sign_in" className="alert">Login With Google</a>
              <input className="button right" type="submit" />
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default UserSignInView

import React, { Component} from 'react'
import moment from 'moment'
import numeral from 'numeral'
import ARWarningComponent from './ARWarningComponent.jsx'

const AccountRecievableSummaryComponent = ({ arDate, arBreakDown }) => {
  const due_0_30  = arBreakDown['0-30']
  const due_30_60 = arBreakDown['31-60']
  const due_60_90 = arBreakDown['61-90']
  const due_90_plus = arBreakDown['90+']
  const total = arBreakDown['total']
  const current = arBreakDown['current']
  const pastDue = arBreakDown['past_due']

  const balanceDue = (due_0_30 ||0) + (due_30_60 || 0) + (due_60_90 || 0) + (due_90_plus || 0)

  return (
    <div>
        <div className='subheader'>
          Account Receivable Summary
          <small>Payments were last updated {moment(arDate).format('LLL')}</small>
        </div>

        <div>
          {balanceDue ? <ARWarningComponent balanceDue={balanceDue} /> : null}
        </div>
        <div className='row'>
          <div className="medium-2 columns">
            <label>Total Due
              <input readOnly type="text" value={numeral(total).format('$0,0.00')} />
            </label>
          </div>
          <div className="medium-2 columns">
            <label>Past Due
              <input readOnly type="text" value={numeral(pastDue).format('$0,0.00')} />
            </label>
          </div>
          <div className="medium-2 columns">
            <label>0-30
              <input readOnly type="text" value={numeral(due_0_30).format('$0,0.00')} />
            </label>
          </div>
          <div className="medium-2 columns">
            <label>31-60
              <input readOnly type="text" value={numeral(due_30_60).format('$0,0.00')} />
            </label>
          </div>
          <div className="medium-2 columns">
            <label>61-90
              <input readOnly type="text" value={numeral(due_60_90).format('$0,0.00')} />
            </label>
          </div>
          <div className="medium-2 columns">
            <label>90+
              <input readOnly type="text" value={numeral(due_90_plus).format('$0,0.00')} />
            </label>
          </div>
        </div>
    </div>
  )
}

export default AccountRecievableSummaryComponent
import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import { base, short_time } from '../../timeFormats'
import ServerErrors from "../../components/form/ServerErrors"
import FreightShipmentFormComponent from "./components/FreightShipmentComponent"
import { fetchDeliveryTypes, fetchDefaultDeliveryType } from '../../redux/actions/deliveryTypes'
import { fetchCurrentUser } from "../../redux/actions/currentUser"
import { isCallback } from "../../helpers/isCallback"
import { snakeCaseHash } from "../../helpers/snakeCaseHash"
import { createFreightShipment } from "../../redux/actions/freightShipments"

class FreightShipmentsNewView extends Component {
  constructor(props) {
    super(props)

    this.state = {
      carrierReferenceNumber: null,
      estimatedCost: null,
      estimatedVolumeBarrels: null,
      estimatedCaseEquivalents: null,
      estimatedWeightPounds: null,
      estimatedCubics: null,
      estimatedPallets:null,
      deliveryrouteId: null,
      deliveryTypeId: null,
      shippedDate: null,
      shippedTime: null,
      signedBolReceived: false
    }
  }

  componentDidMount() {
    const { dispatch } = this.props

    dispatch(fetchCurrentUser())
    dispatch(fetchDefaultDeliveryType())
    dispatch(fetchDeliveryTypes())
  }

  onChange = (name, value, callback) => {
    this.setState({ [name]: value }, () => {
      if (callback && isCallback(callback)) {
        callback()
      }
    })
  }

  onSubmit = (values) => {
    const {
      carrierReferenceNumber,
      estimatedCost,
      estimatedVolumeBarrels,
      estimatedCaseEquivalents,
      estimatedWeightPounds,
      estimatedCubics,
      estimatedPallets,
      deliveryTypeId,
      shippedDate,
      shippedTime,
      signedBolReceived
    } = values

    const { deliveryrouteId } = this.state
    const { dispatch, defaultDeliveryType } = this.props

    const getDeliveryTypeId = () => {
      if (deliveryTypeId === undefined) {
        return defaultDeliveryType.id
      } else {
        return deliveryTypeId
      }
    }

    const getShippedAt = (shippedDate, shippedTime) => {
      return moment(shippedDate + ' ' + shippedTime).format(base + ' ' + short_time)
    }

    const freight_shipment = snakeCaseHash({
      carrierReferenceNumber,
      deliveryrouteId,
      estimatedCost,
      estimatedVolumeBarrels,
      estimatedCaseEquivalents,
      estimatedWeightPounds,
      estimatedCubics,
      estimatedPallets,
      signedBolReceived,
      deliveryTypeId: getDeliveryTypeId(),
      shippedAt: getShippedAt(shippedDate, shippedTime),
      status: 'pending'
    })

    dispatch(createFreightShipment({ freight_shipment }))
  }

  render() {
    const { dispatch, serverErrors, currentUser, defaultDeliveryType, defaultDeliveryTypes } = this.props

    if (!defaultDeliveryTypes || !defaultDeliveryType ) return null

    const {
      carrierReferenceNumber,
      estimatedCost,
      estimatedVolumeBarrels,
      estimatedCaseEquivalents,
      estimatedWeightPounds,
      estimatedCubics,
      estimatedPallets,
      deliveryrouteId,
      deliveryTypeId,
      shippedDate,
      shippedTime,
      signedBolReceived
    } = this.state

    return (
      <div>
        <ServerErrors serverErrors={serverErrors} />
        <FreightShipmentFormComponent
          page={'new'}
          dispatch={dispatch}
          currentUser={currentUser}
          carrierReferenceNumber={carrierReferenceNumber}
          estimtedCost={estimatedCost}
          estimatedVolumeBarrels={estimatedVolumeBarrels}
          estimatedCaseEquivalents={estimatedCaseEquivalents}
          estimatedWeightPounds={estimatedWeightPounds}
          estimatedCubics={estimatedCubics}
          estimatedPallets={estimatedPallets}
          deliveryrouteId={deliveryrouteId}
          deliveryTypeId={deliveryTypeId || defaultDeliveryType.id}
          shippedDate={shippedDate}
          shippedTime={shippedTime}
          signedBolReceived={signedBolReceived}
          onChange={this.onChange}
          onSubmit={this.onSubmit}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    currentUser: state.currentUser.user,
    defaultDeliveryType: state.deliveryTypes.default,
    defaultDeliveryTypes: state.deliveryTypes.all,
    serverErrors: state.freightShipments.errors
  }
}

const connectedFreightShipmentsNewView = connect(mapStateToProps,)(FreightShipmentsNewView)
export default connectedFreightShipmentsNewView

import React, { Component } from 'react'
import mapboxgl from 'mapbox-gl'

mapboxgl.accessToken = 'pk.eyJ1Ijoidmlrc2l0IiwiYSI6ImNqaGppdmcwNzJqNmszY3BlbmR0emtxaGYifQ.8ZZWXJlhAvFZOpNwplDE6w'

class Map extends React.Component {
  constructor(props) {
    super(props)

    const { clusterMode } = props
    this.state = { clusterMode }
  }

  componentDidMount() {
    const { mapStyle, center, zoom, onLoad } = this.props

    this.map = new mapboxgl.Map({
      container: this.mapContainer,
      center: center,
      zoom: zoom,
      style: mapStyle || 'mapbox://styles/mapbox/streets-v9'
    })

    onLoad(this.map)
  }

  componentWillUnmount() {
    this.map.remove();
  }

  componentWillReceiveProps(nextProps) {
    const { featuresData, clusterMode, setClustering, zoom } = nextProps

    if (!featuresData) return

    const map = this.map

    const clusteredAccountsLayer = map.getSource('accounts')
    clusteredAccountsLayer && clusteredAccountsLayer.setData({
      "type": "FeatureCollection",
      "features": featuresData
    })

    const unclusteredAccountsLayer = map.getSource('single-accounts')
    unclusteredAccountsLayer && unclusteredAccountsLayer.setData({
      "type": "FeatureCollection",
      "features": featuresData
    })

    if (clusterMode != this.state.clusterMode) {
      this.setState({ clusterMode }, () => setClustering(map, clusterMode))
    }

    if (zoom != map.getZoom()) map.setZoom(zoom)
  }

  render() {
    const style = {
      height: "600px",
      width: "100%"
    }

    return <div style={style} ref={el => this.mapContainer = el} />
  }
}

export default Map

import CustomSelect from '../../../components/select/base/CustomSelect.jsx'

const ServiceExceptionCategoriesExeptionableSelect = (props) => {
  const allProps = { ...props, selector: (state) => state['serviceExceptionCategories']['current']['types'] }

  return (
    <CustomSelect { ...allProps } />
  )
}

export default ServiceExceptionCategoriesExeptionableSelect
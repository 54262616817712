import React from 'react';
import createReactClass from 'create-react-class';
import { base, short_weekday } from '../../timeFormats'
import moment from 'moment';

var RailsOrderDateFormGroup = createReactClass({
  getInitialState: function () {
    var dateValue = moment(this.props.value);
    return {value: dateValue.format( base), dayname:dateValue.format(short_weekday)}
  },

  handleChange: function (evt) {
    var actual = evt.target.value;
    var dateValue = moment(actual);
    this.setState({value: dateValue.format( base), dayname:dateValue.format(short_weekday)});
  },

  render: function () {
    var inputAddonStyle = {
      minWidth: '100px'
    };

    var dateInput;

    if(this.props.readOnly == true ) {
      dateInput = <input readOnly type="date" onChange={this.handleChange} name={this.props.name + "[" + this.props.attr + "]"} defaultValue={this.state.value} />;
    } else {
      dateInput = <input type="date" onChange={this.handleChange} name={this.props.name + "[" + this.props.attr + "]"} defaultValue={this.state.value} />;
    }

    return (
      <div className="row">
        <div className="small-12 columns">
          <div className="row collapse">
            <label>{this.props.description}</label>
            <div className="small-6 columns">
              {dateInput}
            </div>
            <div className="small-6 columns">
              <span className="postfix">{this.state.dayname}</span>
            </div>
          </div>
        </div>
      </div>
    )
  }
});

export default RailsOrderDateFormGroup

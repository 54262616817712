import React, { Component} from "react"
import Select from "react-select"
import moment from "moment"
import { short_time, short_lower_time_12, short_time_with_seconds } from "../../../timeFormats"

class AccountTimeWindow extends Component {
  constructor(props) {
    super(props)

    this.handleChange = this.handleChange.bind(this)
  }

  handleChange(index, name, val) {
    const { onChange } = this.props

    onChange(index, name, val)
  }

  render() {
    const {id, index, editing, submitting, timeWindow, dayNumberOptions, onRemoveWindow, trashStyle} = this.props
    const {day_of_week, start, finish, destroy} = timeWindow

    if (destroy === 1) return null

    const label = dayNumberOptions.find(day => day.value === day_of_week)['label']
    const startFormatted = moment(start, short_time_with_seconds).format(short_lower_time_12)
    const finishFormatted = moment(finish, short_time_with_seconds).format(short_lower_time_12)

    return (
      <tr>
        <td>
          {editing
            ? <Select
              value={day_of_week}
              options={dayNumberOptions}
              placeholder="Select a day..."
              noResultsText="Loading days..."
              onChange={val => this.handleChange(index, 'day_of_week', val.value)}
              disabled={submitting}
            />
            : label
          }
        </td>
        <td>
          {editing
            ? <input
              autoFocus
              disabled={submitting}
              type={'time'}
              name='start'
              value={start}
              style={{margin: 0}}
              onChange={e => this.handleChange(index, e.target.name, e.target.value)}/>
            : startFormatted
          }
        </td>
        <td>
          {editing
            ? <input
              disabled={submitting}
              type={'time'}
              name='finish'
              value={finish}
              style={{margin: 0}}
              onChange={e => this.handleChange(index, e.target.name, e.target.value)}/>
            : finishFormatted
          }
        </td>
        <td>
          <span style={trashStyle} onClick={_e => onRemoveWindow(id)}><i className="fi-trash"></i></span>
        </td>
      </tr>
    )
  }
}

export default AccountTimeWindow

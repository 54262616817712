import { redirectTo } from "../../helpers/redirectTo"
import { uuidv4 } from "../../helpers/uuidv4"
import $ from "jquery"

function fetchOrderPaymentsRequest() {
  return {
    type: 'FETCH_ORDER_PAYMENTS_REQUEST'
  }
}

function fetchOrderPaymentsSuccess(payload) {
  return {
    type: 'FETCH_ORDER_PAYMENTS_SUCCESS',
    payload
  }
}

export function fetchPayments(orderId) {
  const url = Routes.api_v3_order_payments_path(orderId, {format: 'json'})

  return (dispatch) => {
    dispatch(fetchOrderPaymentsRequest())

    $.ajax({
      url,
      method: 'GET',
      dataType: 'json'
    }).then(response => {
      dispatch(fetchOrderPaymentsSuccess(response))
    }).catch(err => {
      console.log(err)
    })
  }
}
function fetchOrderPaymentRequest() {
  return {
    type: 'FETCH_ORDER_PAYMENT_REQUEST'
  }
}

function fetchOrderPaymentSuccess(payload) {
  return {
    type: 'FETCH_ORDER_PAYMENT_SUCCESS',
    payload
  }
}

export function fetchOrderPayment(orderId, paymentId){
  const url = Routes.order_payment_path(orderId, paymentId)

  return (dispatch) => {
    dispatch(fetchOrderPaymentRequest())

    $.ajax({
      url,
      method: 'GET',
      dataType: 'json'
    }).then(response => {
      dispatch(fetchOrderPaymentSuccess(response))
    }).catch(err => {
      console.log(err)
    })
  }
}

export function createPayments(params, orderId) {
  const url = Routes.api_v3_payments_path({format: 'json'})
  const data = { batch_payment: { uuid: uuidv4(), ...params } }

  return (dispatch) => {
    $.ajax({
      url,
      data,
      method: 'POST',
      dataType: 'json'
    }).then(_response => {
      redirectTo(Routes.order_path(orderId))
    }).catch(error => {
      console.log(error)
    })
  }
}

function uploadCommentAttachmentRequest(id) {
  return {
    type: 'UPLOAD_PAYMENT_ATTACHMENT_REQUEST',
    payload: id
  }
}

function uploadCommentAttachmentSuccess(id) {
  return {
    type: 'UPLOAD_PAYMENT_ATTACHMENT_SUCCESS',
    payload: id
  }
}

export function uploadAttachment(id, url, formData, onSuccess, onError) {
  return (dispatch) => {
    dispatch(uploadCommentAttachmentRequest(id))

    $.ajax({
      url,
      method: 'POST',
      data: formData,
      processData:  false,
      contentType:  false,
      success: (response) => {
        onSuccess(response);
        dispatch(uploadCommentAttachmentSuccess(id))
      },
      error: (response) => {
        onError(response)
      },
    })
  }
}

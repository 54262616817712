import $ from 'jquery'
import Routes from '../../routes.js.erb'

function fetchAccountTypesRequest() {
  return {
    type: 'FETCH_ACCOUNT_TYPES_REQUEST',
  }
}

function fetchAccountTypesSuccess(payload) {
  return {
    type: 'FETCH_ACCOUNT_TYPES_SUCCESS',
    payload
  }
}

function fetchAccountTypesFailure(response) {
  return {
    type: 'FETCH_ACCOUNT_TYPES_FAILURE',
    error: response.error,
  }
}

export function fetchAccountTypes() {
  const url = Routes.account_types_path({format: 'json'})

  return (dispatch) => {
    dispatch(fetchAccountTypesRequest());

    $.getJSON(url)
      .then(json => { dispatch(fetchAccountTypesSuccess(json)) })
      .catch(response => dispatch(fetchAccountTypesFailure(response)));
  }
}
const initialState = {
  all: [],
  loading: false
}

export function accountGroups(state = initialState, action) {
  const { payload } = action

  switch (action.type) {
    case 'FETCH_ACCOUNT_GROUPS_REQUEST':
      return { ...state, loading: true };

    case 'FETCH_ACCOUNT_GROUPS_SUCCESS':
      const { account_groups } = payload
      return { ...state, loading: false, all: account_groups };

    case 'FETCH_ACCOUNT_GROUPS_FAILURE':
      return { ...state, loading: false, error: 'fetch users failure' };

    default:
      return state;
  }
}

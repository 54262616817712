import React, { Component } from 'react'
import { connect } from 'react-redux'
import queryString from 'query-string'

import MapReportView from './reports/map/MapReportView.jsx'

import { fetchAccounts } from '../../redux/actions/accounts.js'
import { fetchAccountGroups } from '../../redux/actions/accountGroups.js'
import { fetchAccountTypes } from '../../redux/actions/accountTypes.js'
import { fetchGls } from '../../redux/actions/gls.js'
import { fetchSkus } from '../../redux/actions/skus.js'
import { fetchTags } from '../../redux/actions/tags.js'
import { fetchUsers } from '../../redux/actions/users.js'
import { fetchWarehouses } from '../../redux/actions/warehouses.js';

class Kpi extends Component {
  constructor(props) {
    super(props);
    const { reportViewType } = props

    this.state = { reportViewType, reportProps: {} }

    this.onNavClick = this.onNavClick.bind(this)
  }

  componentDidMount() {
    this.props.dispatch(fetchUsers())
    this.props.dispatch(fetchAccounts('map')) // map has everything that quick needs as well
    this.props.dispatch(fetchAccountGroups())
    this.props.dispatch(fetchAccountTypes())
    this.props.dispatch(fetchSkus())
    this.props.dispatch(fetchGls())
    this.props.dispatch(fetchWarehouses())
    this.props.dispatch(fetchTags('SKU_TAGS'))
    this.props.dispatch(fetchTags('ACCOUNT_TAGS'))
    this.props.dispatch(fetchTags('USER_TAGS'))
    this.parseUrlParams()
  }

  parseUrlParams() {
    const params = queryString.parse(location.search, {arrayFormat: 'bracket'})
    for (let key in params) {
      const posArray = params[key]
      if (Array.isArray(posArray)) {
        params[key] = posArray.map(e => parseInt(e))
      }
    }
    this.setState({ reportProps: params })
  }

  onNavClick(e) {
    const reportViewType = e.target.id
    const url = window.location.origin + window.location.pathname
    history.pushState(null, null, url)
    this.setState({ reportViewType, reportProps: {} })
  }

  renderReportView() {
    const reportViewType = this.state.reportViewType || this.props.reportViewType || this.state.reportProps.reportViewType
    const reportProps = this.state.reportProps
    const { dispatch } = this.props

    switch (reportViewType) {
      case 'map':
        return <MapReportView dispatch={dispatch} {...reportProps} />
      default:
        return 'Please select a report type'
    }
  }

  render() {

    return (
      <div>
        <div className="row fullWidth">
          <div className="large-12 columns">
            <h3 className="subheader">Reporting</h3>
            <hr />
          </div>
        </div>
        <div className="row fullWidth">
          <div className="large-2 columns">
            <ul className="side-nav kpi-menu">
              <li><a id="table" onClick={this.onNavClick}>Tabular Reports</a></li>
              <li className="divider"></li>
              <li><a id="chart" onClick={this.onNavClick}>Chart Reports</a></li>
              <li className="divider"></li>
              <li><a id="map" onClick={this.onNavClick}>Map Reports</a></li>
            </ul>
          </div>
          <div className="large-10 columns">
            <div className="row">
              {this.renderReportView()}
            </div>
          </div>
        </div>
      </div>
    );
  }

}

const mapStateToProps = (state) => {
  return {
    reportViewType: state.kpi.reportViewType,
  }
}

const connectedKpi = connect(mapStateToProps,)(Kpi)
export default connectedKpi

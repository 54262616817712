import React from 'react';
import PropTypes from 'prop-types'
import store from './redux/store.js';
import $ from 'jquery'
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom'

import AccountForm from './views/accounts/AccountForm.jsx';
import AccountShowView from './views/accounts/AccountShowView.jsx';
import AccountsIndexView from './views/accounts/AccountsIndexView.jsx';
import AccountMergeView from './views/accounts/AccountMergeView.jsx';
import ContactsIndexView from './views/contacts/ContactsIndexView.jsx';
import KpiReport from './views/kpi/KpiReport.jsx'
import FreightShipmentsIndexView from './views/freightShipments/FreightShipmentsIndexView.jsx';
import FreightShipmentsNewView from './views/freightShipments/FreightShipmentsNewView.jsx';
import FreightShipmentsEditView from './views/freightShipments/FreightShipmentsEditView.jsx';
import NotificationsIndexView from './views/notifications/NotificationsIndexView.jsx';
import OrdersIndexView from './views/orders/OrdersIndexView.jsx';
import OrdersNewView from './views/orders/OrdersNewView.jsx';
import OrdersShowView from './views/orders/OrdersShowView.jsx';
import OrdersAttachmentView from './views/orders/OrdersAttachmentView.jsx';
import OrdersApproveView from './views/orders/OrdersApproveView.jsx';
import OrdersUnapproveView from './views/orders/OrdersUnapproveView.jsx';
import OrdersBookView from './views/orders/OrdersBookView.jsx';
import OrdersCompleteView from './views/orders/OrdersCompleteView.jsx';
import OrdersUncompleteView from './views/orders/OrdersUncompleteView.jsx';
import OrdersShipView from './views/orders/OrdersShipView.jsx';
import OrdersUnshipView from './views/orders/OrdersUnshipView.jsx';
import OrdersEditView from './views/orders/OrdersEditView.jsx';
import PaymentsNewView from './views/payments/PaymentsNewView.jsx';
import PaymentsShowView from './views/payments/PaymentsShowView.jsx';
import OrdersReceiptView from './views/orders/OrdersReceiptView.jsx';
import ServiceExceptionCategoriesEditView from './views/serviceExceptionCategories/ServiceExceptionCategoriesEditView.jsx';
import ServiceExceptionCategoriesShowView from './views/serviceExceptionCategories/ServiceExceptionCategoriesShowView.jsx';
import ServiceExceptionCategoriesIndexView from './views/serviceExceptionCategories/ServiceExceptionCategoriesIndexView.jsx';
import VisitNewView from './views/visits/VisitNewView.jsx'
import VisitEditView from './views/visits/VisitEditView.jsx'
import VisitShowView from './views/visits/VisitShowView.jsx'
import VisitsIndexView from './views/visits/VisitsIndexView.jsx'
import UserSignInView from './views/users/UserSignInView.jsx';

const Root = ({ store }) => (
  <Provider store={store}>
    <Router>
      <Switch>
        <Route path="/kpi" component={KpiReport} />

        <Route path="/accounts/new" component={AccountForm} />
        <Route path="/accounts/:id/edit" component={AccountForm} />
        <Route path="/accounts/:id/merge" component={AccountMergeView} />
        <Route path="/accounts/:id" component={AccountShowView} />
        <Route path="/accounts/" component={AccountsIndexView} />

        <Route path="/freight_shipments/new" component={FreightShipmentsNewView} />
        <Route path="/freight_shipments/:id/edit" component={FreightShipmentsEditView} />
        <Route path="/freight_shipments/" component={FreightShipmentsIndexView} />

        <Route path="/notifications/" component={NotificationsIndexView} />

        <Route path="/visits/new" component={VisitNewView} />
        <Route path="/visits/:id/edit" component={VisitEditView} />
        <Route path="/visits/:id" component={VisitShowView} />
        <Route path="/visits/" component={VisitsIndexView} />

        <Route path="/orders/:id/payments/new" component={PaymentsNewView} />
        <Route path="/orders/:orderId/payments/:id" component={PaymentsShowView} />

        <Route path="/orders/new" component={OrdersNewView} />
        <Route path="/orders/:id/attachments/new" component={OrdersAttachmentView} />
        <Route path="/orders/:id/approve" component={OrdersApproveView} />
        <Route path="/orders/:id/unapprove" component={OrdersUnapproveView} />
        <Route path="/orders/:id/book" component={OrdersBookView} />
        <Route path="/orders/:id/complete" component={OrdersCompleteView} />
        <Route path="/orders/:id/uncomplete" component={OrdersUncompleteView} />
        <Route path="/orders/:id/ship" component={OrdersShipView} />
        <Route path="/orders/:id/unship" component={OrdersUnshipView} />
        <Route path="/orders/:id/edit" component={OrdersEditView} />
        <Route path="/orders/:id/receipt" component={OrdersReceiptView} />
        <Route path="/orders/:id" component={OrdersShowView} />
        <Route path="/orders/" component={OrdersIndexView} />

        <Route path="/contacts/" component={ContactsIndexView} />

        <Route path="/service_exception_categories/:id/edit" component={ServiceExceptionCategoriesEditView} />
        <Route path="/service_exception_categories/:id" component={ServiceExceptionCategoriesShowView} />
        <Route path="/service_exception_categories" component={ServiceExceptionCategoriesIndexView} />

        <Route path="/users/sign_in" component={UserSignInView} />
      </Switch>
    </Router>
  </Provider>
)

Root.propTypes = {
  store: PropTypes.object.isRequired
}

$(document).ready(() => {
  const reactAppRoot = document.getElementById('react-app-root')

  if (!!reactAppRoot) {
    render(<Root store={store} />, reactAppRoot)
  }
})

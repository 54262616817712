import moment from "moment"
import {base} from "../../timeFormats"

export function suggestDatesFor(collection) {
  return collection.map(accountTimeWindow => {
    const suggestDate = (suggestedWeekDay) => {
      const currentWeekday =  moment().weekday()

      let suggestedWeekDayDiff
      if (currentWeekday === suggestedWeekDay) {
        suggestedWeekDayDiff = 7
      }
      if (currentWeekday > suggestedWeekDay) {
        suggestedWeekDayDiff = 7 - (currentWeekday - suggestedWeekDay)
      }
      if (currentWeekday < suggestedWeekDay) {
        suggestedWeekDayDiff = suggestedWeekDay - currentWeekday
      }

      return moment().add(suggestedWeekDayDiff, 'days').format(base)
    }

    const { id, start, finish, day_of_week, memo } = accountTimeWindow
    const date = suggestDate(day_of_week)

    return {
      date,
      startTime: start,
      endTime: finish,
      _id: id,
      _destroy: 0
    }
  })
}
const initialState = {
  all: []
}

export function accountTags(state=initialState, action) {
  const { payload } = action

  switch (action.type) {
    case 'FETCH_ACCOUNT_TAGS_SUCCESS':
      return { ...state, all: payload }

    default:
      return state;
  }
}



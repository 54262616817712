import { connect } from 'react-redux'
import React, { Component} from "react"
import $ from "jquery"
import UploadAttachmentLink from "../../components/upload/UploadAttachmentLink"
import { fetchOrder, createOrderAttachment } from "../../redux/actions/orders"
import { isCallback } from "../../helpers/isCallback"
import {snakeCaseHash} from "../../helpers/snakeCaseHash"


class OrdersAttachmentView extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    const that = this
    const { dispatch, match } = this.props
    const orderId = match.params.id

    dispatch(fetchOrder(orderId))

    $(document).ready(function() {
      $(document).on('click', 'a.upload', (e) => {
        const fileInput = $(e.target).siblings('input[type="file"]')[0]
        $(fileInput).click()
      })

      $(document).on('change', 'input[type="file"]', (e) => {
        const payment = $(e.target).parentsUntil('.Payments')[2]
        const link = $(payment).find('a.upload')[0]
        const file = e.target.files[0]
        const fileReader = new FileReader()

        fileReader.addEventListener("load", (e) => {
          that.onChange('proofOfDelivery', e.target.result, () => {
            dispatch(createOrderAttachment(orderId, snakeCaseHash(that.state)))
          })
        });

        fileReader.readAsDataURL(file)
        $(link).html(file.name)
      })
    })
  }

  onChange = (name, value, callback) => {
    this.setState({ [name]: value }, () => {
      if (callback && isCallback(callback)) {
        callback()
      }
    })
  }

  render() {
    const { order } = this.props

    if (!order) return null

    return (
      <div>
        <div className='row'>
          <div className='small-12 columns'>
            <h3 className='subheader'>Upload Document for Order #{order.id}</h3>
          </div>
        </div>
        <div className='row'>
          <div className='small-6 columns'>
            <label>Proof of delivery</label>
            <UploadAttachmentLink
              text={'Upload Photo or Pdf document'}
              style={{'display': 'inline-block', 'verticalAlign': 'sub'}}
            />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    order: state.orders.current.order,
    currentUser: state.currentUser.user,
    serverErrors: state.errors.orders.create || {}
  }
}

const connectedOrdersAttachmentView = connect(mapStateToProps,)(OrdersAttachmentView)
export default connectedOrdersAttachmentView
import $ from 'jquery'
import Routes from '../../routes.js.erb'
import { redirectTo } from '../../helpers/redirectTo.js'
import { snakeCaseHash } from '../../helpers/snakeCaseHash.js'

function fetchVisitRequest() {
  return {
    type: 'FETCH_VISIT_REQUEST'
  }
}

function fetchVisitSuccess(payload) {
  return {
    type: 'FETCH_VISIT_SUCCESS',
    payload
  }
}

export function fetchVisit(id) {
  const url = Routes.visit_path(id, {format: 'json'})

  return (dispatch) => {
    dispatch(fetchVisitRequest())

    $.getJSON(url)
      .then(payload => {
        dispatch(fetchVisitSuccess(payload))
      })
      .catch(response => console.log(response));
  }
}

function fetchVisitsRequest() {
  return {
    type: 'FETCH_VISITS_REQUEST',
  }
}

export function clearVisitsOffset() {
  return {
    type: 'CLEAR_VISITS_OFFSET'
  }
}

function fetchVisitsSuccess(payload) {
  return {
    type: 'FETCH_VISITS_SUCCESS',
    payload
  }
}

export function fetchVisits(params) {
  const url = Routes.visits_path({format: 'json'})
  const data = snakeCaseHash(params)

  return (dispatch) => {
    dispatch(fetchVisitsRequest());

    $.getJSON(url, data)
      .then(payload => {
        dispatch(fetchVisitsSuccess(payload))
      })
      .catch(response => console.log(response));
  }
}

export function createVisit(visit) {
  const url = Routes.visits_path({format: 'json'})
  const data = { visit }

  return (_dispatch) => {
    $.ajax({
      url,
      data,
      type: 'POST',
      dataType: 'json'
    }).then(response => {
      redirectTo(Routes.edit_visit_path(response.id))
    }).catch(error => {
      console.log(error.responseJSON)
    })
  }
}

function fetchPosCordsSuccess(payload) {
  return {
    type: 'FETCH_POS_CORDS_SUCCESS',
    payload
  }
}

export function fetchPosCords(options) {
  return (dispatch) => {
    navigator.geolocation.getCurrentPosition(
      (pos) => {
        dispatch(fetchPosCordsSuccess(pos.coords))
      },
      err => console.log(err),
      options
    )
  }
}
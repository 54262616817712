import React, { Component } from 'react'
import { connect } from 'react-redux'
import { get as lodashGet } from 'lodash'
import MapComponent from './MapComponent.jsx'
import TableComponent from './TableComponent.jsx'
import MapReportFormComponent from './MapReportFormComponent.jsx'
import { CSVDownloader } from '../../../../components/csv/CSVDownloader.jsx';
import { getFeatures, onClick } from './layers/accountsWithNameAndId.js'
import { updateUrl } from "../../../../helpers/updateUrl"
import { fetchAccounts } from '../../../../redux/actions/accounts.js'


class MapReportView extends Component {
  constructor(props) {
    super(props)

    const defaultProps = this.defaultProps

    this.state = { ...defaultProps, view: 'map', featuresData: null }

    this.onMapLoad = this.onMapLoad.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
    this.setFeaturesData = this.setFeaturesData.bind(this)
    this.toggleAttr = this.toggleAttr.bind(this)
    this.getDataForCSV = this.getDataForCSV.bind(this)
    this.renderReport = this.renderReport.bind(this)
    this.syncStateWithMapState = this.syncStateWithMapState.bind(this)
    this.syncUrlWithState = this.syncUrlWithState.bind(this)
  }

  defaultProps = {
    lng: -122.4209327,
    lat: 37.7931114,
    zoom: 10,
    clusterMode: false,
    filters: {
      accountTypes: [],
      warehouses: [],
      accountSalespersons: []
    },
    reportViewType: 'map'
  }

  componentDidMount() {
    this.props.dispatch(fetchAccounts("map"))
    const _ = this.defaultProps

    const { zoom, lng, lat, clusterMode, accountTypes, warehouses, accountSalespersons } = this.props
    const filters = {
      accountTypes: accountTypes || _.accountTypes,
      warehouses: warehouses || _.warehouses,
      accountSalespersons: accountSalespersons || _.accountSalespersons
    }

    this.setState({ zoom: zoom || _.zoom, lng: lng || _.lng, lat: lat || _.lat, clusterMode: (clusterMode || _.clusterMode).toString() === 'true', filters })
  }

  syncStateWithMapState(map) {
    const zoom = map.getZoom()
    const center = map.getCenter()
    const { lng, lat } = center

    this.setState({ zoom, lng, lat }, () => this.syncUrlWithState())
  }

  syncUrlWithState() {
    const { dispatch } = this.props
    const { reportViewType, view, lng, lat, zoom, clusterMode, filters } = this.state
    dispatch(updateUrl({ reportViewType, view, lng, lat, zoom, clusterMode, ...filters }))
  }

  toggleAttr(attr) {
    const currentVal = this.state[attr]
    const lookup = {
      view: {
        'map'   : 'table',
        'table' : 'map'
      },
      clusterMode: {
        true    : false,
        false   : true
      }
    }

    const nextVal = lookup[attr][currentVal]

    this.setState({ [attr]: nextVal }, () => {
      const featuresData = this.getFeaturesData()
      this.setFeaturesData(featuresData)
      this.syncUrlWithState()
    })
  }

  onSubmit(newFilters) {
    const { filters, lng, lat, zoom, clusterMode } = this.state

    const updated = {}
    Object.keys(filters).map(key => {
      updated[key] = newFilters[key] && newFilters[key].map(el => el.value && el.value || el)
    })

    this.setState({ filters: updated }, () => {
      const featuresData = this.getFeaturesData()
      this.setFeaturesData(featuresData)
      this.syncUrlWithState()
    })
  }

  filterAccounts(accounts, filters={}) {
    const attributeLookup = {
      accountTypes: 'accountType.id',
      warehouses: 'warehouse.id',
      accountSalespersons: 'salesperson.id'
    }

    let selectedAccounts = accounts.slice()
    Object.entries(filters).map(keyValPair => {
      const [key, val] = keyValPair
      const attributePath = attributeLookup[key]

      if (val && val.length > 0) {
        const lookupAttrFn = (account) => { return lodashGet(account, attributePath) }
        selectedAccounts = selectedAccounts.filter(account => val.includes(lookupAttrFn(account)))
      }
    })

    return selectedAccounts
  }

  getFeaturesData() {
    const { accounts } = this.props
    const { filters }  = this.state
    const selectedAccounts = this.filterAccounts(accounts, filters)
    return getFeatures(selectedAccounts)
  }

  setFeaturesData(featuresData) {
    this.setState({ featuresData })
  }

  componentWillReceiveProps(nextProps) {
    /*
    const { zoom, lng, lat, clusterMode, accountTypes, warehouses, accountSalespersons } = nextProps
    console.log('all next props')
    console.log(nextProps)
    */
  }

  setClustering(map, clusterMode) {
    if (clusterMode) {
      map.setLayoutProperty('clusters', 'visibility', 'visible')
      map.setLayoutProperty('cluster-count', 'visibility', 'visible')
      map.setLayoutProperty('single-markers', 'visibility', 'none')
    } else {
      map.setLayoutProperty('clusters', 'visibility', 'none')
      map.setLayoutProperty('cluster-count', 'visibility', 'none')
      map.setLayoutProperty('single-markers', 'visibility', 'visible')
    }
  }


  onMapLoad(map) {
    const self = this
    const { accounts } = this.props
    const { filters, clusterMode } = this.state

    const clusterRadius = 50

    map.on('load', () => {

      map.addSource('accounts', {
        type: 'geojson',
        data: {
          type: "FeatureCollection",
          features: self.getFeaturesData(accounts)
        },
        cluster: true,
        clusterMaxZoom: 14,
        clusterRadius: clusterRadius
      })

      map.addSource('single-accounts', {
        type: 'geojson',
        data: {
          type: "FeatureCollection",
          features: self.getFeaturesData(accounts)
        },
      })



      map.addLayer({
          id: "clusters",
          type: "circle",
          source: "accounts",
          filter: ["has", "point_count"],
          paint: {
              "circle-color": "#ff4612",
              "circle-radius": [
                  "step",
                  ["get", "point_count"],
                  20,
                  100,
                  30,
                  750,
                  40
              ]
          },
        layout: {
          "visibility": "none"
        }
      });

      map.addLayer({
        id: "cluster-count",
        type: "symbol",
        source: "accounts",
        filter: ["has", "point_count"],
        layout: {
          "text-field": "{point_count_abbreviated}",
          "text-font": ["DIN Offc Pro Medium", "Arial Unicode MS Bold"],
          "text-size": 12,
          "visibility": "none"
        }
      });

      map.addLayer({
        id: "markers",
        type: "circle",
        source: "accounts",
        filter: ["!has", "point_count"],
        paint: {
          "circle-color": "#ff4612",
          "circle-radius": 7
        },
        layout: {
          "visibility": "visible"
        }
      });

      map.addLayer({
        id: "single-markers",
        type: "circle",
        source: "single-accounts",
        paint: {
            "circle-color": "#ff4612",
            "circle-radius": 7
        },
        layout: {
          visibility: "visible"
        }
      });


      self.setClustering(map, clusterMode)
    })


    map.on('click', 'markers', (e) => onClick(e, map))
    map.on('mouseenter', 'markers', () => { map.getCanvas().style.cursor = 'pointer' })
    map.on('mouseleave', 'markers', () => { map.getCanvas().style.cursor = '' })
    map.on('mouseenter', 'clusters', () => { map.getCanvas().style.cursor = 'pointer' })
    map.on('mouseleave', 'clusters', () => { map.getCanvas().style.cursor = '' })
    map.on('zoomend', () => self.syncStateWithMapState(map))
    map.on('dragend', () => self.syncStateWithMapState(map))
    map.setMaxZoom(17)

    map.on('click', function(e) {
      const cluster = map.queryRenderedFeatures(e.point, { layers: ["clusters"] });
      const longLat = map.unproject(e.point)

      if (cluster[0]) {

        const pointsInCluster = self.getFeaturesData(accounts, filters).filter((f) => {
          const pointPixels = map.project(f.geometry.coordinates)
          const pixelDistance = Math.sqrt(
            Math.pow(e.point.x - pointPixels.x, 2) +
            Math.pow(e.point.y - pointPixels.y, 2)
          )

          return Math.abs(pixelDistance) <= clusterRadius;
        })

        const n = pointsInCluster.length
        const currentZoom = map.getZoom()
        let nextZoom = 10
        if (n > 50) {
          nextZoom = 11
        } else if (n > 20) {
          nextZoom = 12
        } else if (n > 13) {
          nextZoom = 13
        } else {
          nextZoom = 14
        }

        if (currentZoom >= nextZoom) nextZoom = currentZoom + 1

        map.flyTo({ center: longLat, zoom: nextZoom })
      }
    });

  }

  renderReport() {
    const { accounts } = this.props
    const { featuresData, view, filters, clusterMode, zoom, lng, lat } = this.state

    const filteredAccounts = this.filterAccounts(accounts, filters)

    if (view === 'map') {
      const center = [parseFloat(lng), parseFloat(lat)]

      return (
        <div className="small-12 medium-12 large-12">
          {accounts.length > 0 && 
            <MapComponent
              featuresData={featuresData}
              center={center}
              zoom={parseFloat(zoom)}
              clusterMode={clusterMode}
              onLoad={this.onMapLoad}
              setClustering={this.setClustering}
            />}
        </div>
      )
    } else if (view === 'table') {
      return (
        <TableComponent className="small-12 medium-12 large-12" accounts={filteredAccounts} />
      )
    }
  }

  getDataForCSV() {
    const { filters } = this.state
    const { accounts } = this.props
    const filteredAccounts = this.filterAccounts(accounts, filters)

    const headers = [
      "Account Name",
      "Account Type",
      "Salesperson",
      "Warehouse",
      "One Line Address",
      "Latitude",
      "Longitude"
    ]
    let data = [headers]
    filteredAccounts.forEach(account => {
      const { name, accountType, salesperson, warehouse, address, geometry } = account
      const [longitude, latitude] = geometry.coordinates
      data.push([ name, accountType.name, salesperson.name, warehouse.name, address.one_line_address, latitude, longitude ])
    })

    return data
  }


  render() {
    const { view, clusterMode, filters } = this.state
    const toggleViewText = view === 'map' ? 'List View' : 'Map View'
    const toggleClusterModeText = clusterMode ? 'Uncluster' : 'Cluster'

    const csvData = this.getDataForCSV()

    return (
      <div className="small-12 columns">
        <MapReportFormComponent onSubmit={this.onSubmit} {...filters} />
        <div className="right">
          <button disabled={view === 'table'}onClick={e => this.toggleAttr('clusterMode')} className="tiny">{toggleClusterModeText}</button>
          &nbsp;&nbsp;
          <button className="button tiny" onClick={e => this.toggleAttr('view')}>{toggleViewText}</button>
          &nbsp;&nbsp;
          <CSVDownloader data={csvData} />
        </div>
        {this.renderReport()}
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    accounts: state.kpi.accounts || [],
  }
}

export default connect(mapStateToProps, )(MapReportView)

const ARWarningComponent = ({ balanceDue }) => {
  return (
    <div>
      <div className='row'>
        <div className='small-12 columns'>
          <h2>Past Due Warning</h2>
          <div data-alert className='alert-box alert radius'>
            <p className='lead'>Yikes, this account has invoices which are more than 30 past due!</p>
            <p>By submitting this order, you are:</p>
            <ol>
              <li>Informing the account of this issue prior to the delivery</li>
              <li>{'Arranging for a payment of $' + balanceDue + ' to be made on or before the delivery'}</li>
            </ol>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ARWarningComponent
import $ from 'jquery'
import Routes from '../../routes.js.erb'
import { snakeCaseHash } from "../../helpers/snakeCaseHash"
import { updateUrl } from "../../helpers/updateUrl.js"
import { redirectTo } from "../../helpers/redirectTo"
import { isCallback } from "../../helpers/isCallback"

export function mergeAccount({ accountId, otherAccountId, reverseFlag, successCallback, errorCallback }) {
  const url = reverseFlag ? Routes.reverse_merge_account_path(accountId, {format: 'json'}) : Routes.merge_account_path(accountId, {format: 'json'})
  const data = { other_account_id: otherAccountId }

  return (_dispatch) => {
    $.ajax({
      url,
      data,
      type: 'GET',
      dataType: 'json',
      success: (response) => successCallback(response),
      error: (error) => errorCallback(error)
    })
  }
}

export function rejectMerge({ accountId, successCallback, errorCallback }) {
  const url = Routes.account_path(accountId, {format: 'json'})
  const data = { account: { 'merged_into_id': null }}

  return (_dispatch) => {
    $.ajax({
      url,
      data,
      type: 'PATCH',
      dataType: 'json',
      success: (response) => successCallback(response),
      error: (error) => errorCallback(error)
    })
  }
}

function fetchAccountsRequest() {
  return {
    type: 'FETCH_ACCOUNTS_REQUEST',
  }
}

function fetchAccountsSuccess(payload) {
  return {
    type: 'FETCH_ACCOUNTS_SUCCESS',
    payload
  }
}

function fetchAccountsFailure(response) {
  return {
    type: 'FETCH_ACCOUNTS_FAILURE',
    error: response.error,
  }
}

export function fetchAccounts(view_context, q = null) {
  const url = Routes.accounts_path({format: 'json'})
  const data = { view_context, q }

  return (dispatch) => {
    dispatch(fetchAccountsRequest());

    $.getJSON(url, data)
      .then(response => dispatch(fetchAccountsSuccess(response)))
      .catch(response => dispatch(fetchAccountsFailure(response)));
  }
}

function fetchAccountsFullRequest() {
  return {
    type: 'FETCH_ACCOUNTS_FULL_REQUEST',
  }
}

function fetchAccountsFullSuccess(payload) {
  return {
    type: 'FETCH_ACCOUNTS_FULL_SUCCESS',
    payload
  }
}

export function fetchAccountsFull(params) {
  const url = Routes.accounts_path({format: 'json'})
  const data = snakeCaseHash(params)

  return (dispatch) => {
    dispatch(fetchAccountsFullRequest());
    updateUrl(params)

    $.getJSON(url, data)
      .then(payload => { dispatch(fetchAccountsFullSuccess(payload)) })
      .catch(response => dispatch(fetchAccountsFailure(response)));
  }
}

function fetchAccountsForCsvDownloadRequest() {
  return {
    type: 'FETCH_ACCOUNTS_FOR_CSV_DOWNLOAD_REQUEST',
  }
}

function fetchAccountsForCsvDownloadSuccess(payload) {
  return {
    type: 'FETCH_ACCOUNTS_FOR_CSV_DOWNLOAD_SUCCESS',
    payload
  }
}

export function fetchAccountsForCSVDownload(params) {
  const url = Routes.accounts_path({format: 'json'})
  const data = snakeCaseHash(params)

  return (dispatch) => {
    dispatch(fetchAccountsForCsvDownloadRequest())

    $.getJSON(url, data)
      .then(payload => { dispatch(fetchAccountsForCsvDownloadSuccess(payload)) })
      .catch(error => console.log(error))
  }
}

export function getAccount() {
  return {
    type: 'IGNORE'
  }
}

export function clearAccountsFullOffset() {
  return {
    type: 'CLEAR_ACCOUNTS_FULL_OFFSET'
  }
}

function fetchAccountSuccess(payload) {
  return {
    type: 'FETCH_ACCOUNT_SUCCESS',
    payload
  }
}

function fetchAccountFailure(response) {
  return {
    type: 'FETCH_ACCOUNT_FAILURE',
    error: response.error,
  }
}

export function fetchAccount(id, callback = null) {
  const url = Routes.account_path(id, {format: 'json'})

  return (dispatch) => {
    $.getJSON(url)
      .then(response => {
        dispatch(fetchAccountSuccess(response))
        if (callback && isCallback(callback)) callback(response.attributes)
      })
      .catch(response => dispatch(fetchAccountFailure(response)))
  }
}

function createAccountError(payload) {
  return {
    type: 'CREATE_ACCOUNT_ERROR',
    payload
  }
}

export function createAccount(accountHash) {
  const url = Routes.accounts_path({format: 'json'})

  return (dispatch) => {
    const address = accountHash.address
    delete accountHash.address
    const account = snakeCaseHash({ ...accountHash, addressAttributes: address })
    const data = { account }

    $.ajax({
      url,
      data,
      type: 'POST',
      dataType: 'json'
    }).then(account => {
      redirectTo(Routes.account_path(account.id))
    }).catch(error => {
      console.log(error.responseJSON)
      dispatch(createAccountError(error.responseJSON))
    })
  }
}

export function updateAccount(id, accountHash) {
  return (dispatch) => {
    const address = accountHash.address
    delete accountHash.address
    delete accountHash._address
    const account = snakeCaseHash({ ...accountHash, addressAttributes: address })
    const data = { account }
    const url = Routes.account_path(id, {format: 'json'})

    $.ajax({
      url,
      data,
      type: 'PATCH',
      dataType: 'json'
    }).then(_response => {
      redirectTo(url)
    }).catch(error => {
      console.log(error.responseJSON)
      dispatch(createAccountError(error.responseJSON))
    })
  }
}

function fetchAccountTimeWindowsRequest() {
  return {
    type: 'FETCH_ACCOUNT_TIME_WINDOWS_REQUEST'
  }
}

function fetchAccountTimeWindowsSuccess(payload) {
  return {
    type: 'FETCH_ACCOUNT_TIME_WINDOWS_SUCCESS',
    payload
  }
}

export function fetchAccountTimeWindows(accountId, callback = null) {
  const url = Routes.account_time_windows_path(accountId, {format: 'json'})

  return (dispatch) => {
    dispatch(fetchAccountTimeWindowsRequest())

    $.getJSON(url)
      .then(payload => {
        dispatch(fetchAccountTimeWindowsSuccess(payload))
        callback(payload)
      })
      .catch(error => console.log(error))
  }
}

export function preloadAccount(payload) {
  return (dispatch) => {
    dispatch({
      type: 'PRELOAD_ACCOUNT',
      payload
    })
  }
}
import React, { Component } from 'react'
import ReactPaginate from 'react-paginate'

class Paginate extends Component {
  onPageChange = (e) => {
    const { selected } = e
    const { limit } = this.props
    const offset = Math.ceil(selected * limit)

    this.props.onPageChange(offset, selected)
  }

  render() {
    const { dataName, totalCount, limit, page, offset, children } = this.props

    const lower = page*limit + 1
    const _upper = (page+1)*limit
    const upper = totalCount > _upper ? _upper : totalCount
    const totalPageCount = Math.ceil(totalCount / limit)

    if (totalCount === 0) return null

    return (
        <div className="small-12 medium-12">
          <div>Displaying {dataName} <strong>{lower}</strong> - <strong>{upper}</strong> of <strong>{totalCount}</strong> in total</div>
          <br />
          <div className="text-center">
          { totalPageCount > 1 &&
            <ReactPaginate
              initialPage={page}
              previousLabel={"< Prev"}
              nextLabel={"Next >"}
              breakLabel={<a href="">...</a>}
              breakClassName={"break-me"}
              pageCount={totalPageCount}
              marginPagesDisplayed={2}
              pageRangeDisplayed={5}
              onPageChange={this.onPageChange}
              containerClassName={"pagination small-offset-1 small-10 medium-10 medium-offset-1 large-6 large-offset-3"}
              subContainerClassName={"pages pagination"}
              activeClassName={"active"} />
          }
          </div>
          {children}
          <br />
        </div>
    )
  }
}

export default Paginate

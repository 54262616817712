
export function uniqueBy(array, getter) {
  const result = []  
  const significants = []
  array.forEach(el => {
    const significant = (typeof(getter) === 'function') ? getter(el) : el[getter]
    if (!significants.includes(significant)) {
      significants.push(significant)
      result.push(el)
    }
  })
  return result
}
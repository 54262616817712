import $ from 'jquery'
import Routes from '../../routes.js.erb'

function fetchProspectTypesRequest() {
  return {
    type: 'FETCH_PROSPECT_TYPES_REQUEST',
  }
}

function fetchProspectTypesSuccess(payload) {
  return {
    type: 'FETCH_PROSPECT_TYPES_SUCCESS',
    payload
  }
}

function fetchProspectTypesFailure(response) {
  return {
    type: 'FETCH_PROSPECT_TYPES_FAILURE',
    error: response.error,
  }
}

export function fetchProspectTypes() {
  const url = Routes.prospect_types_path({format: 'json'})

  return (dispatch) => {
    dispatch(fetchProspectTypesRequest());

    $.getJSON(url)
      .then(response => { dispatch(fetchProspectTypesSuccess(response)) })
      .catch(response => dispatch(fetchProspectTypesFailure(response)));
  }
}
import $ from 'jquery'
import Routes from '../../routes.js.erb'
import { updateUrl } from "../../helpers/updateUrl.js"
import { snakeCaseHash } from '../../helpers/snakeCaseHash.js'


function fetchContactsRequest() {
  return {
    type: 'FETCH_CONTACTS_REQUEST'
  }
}

function fetchContactsSuccess(payload) {
  return {
    type: 'FETCH_CONTACTS_SUCCESS',
    payload
  }
}

export function fetchContacts(params) {
  const url = Routes.contacts_path({format: 'json'})
  const data = snakeCaseHash(params)

  return (dispatch) => {
    dispatch(fetchContactsRequest())
    updateUrl(params)

    $.getJSON(url, data)
      .then(response => {
        dispatch(fetchContactsSuccess(response))
      })
  }
}

export function clearContactsOffset() {
  return {
    type: 'CLEAR_CONTACTS_OFFSET'
  }
}
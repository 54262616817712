import React from 'react';
import { withFormik, Form, Field } from 'formik'


const IdsFilter = ({ values, errors, touched, setFieldValue, setFieldTouched }) => {
  const onChange = (name, val) => {
    setFieldValue(name, val)
  }

  return (
    <Form>
      <br />
      <div className='idsFilter'>
        <div className='row fullWidth'>
          <div className='medium-12 columns'>
            <label>Ids
              <Field
                className={'inputStringField'}
                name="ids"
                type="string"
                value={values.ids}
                onChange={e => onChange('ids', e.target.value)}
                onBlur={setFieldTouched}
                error={errors.ids}
                placeholder="Enter order ids separated by comma"
              />
              {errors.ids && touched.ids && <small className="error">{errors.ids}</small>}
            </label>
          </div>
        </div>
        <br />
        <div className='row fullWidth'>
          <div className='medium-12 columns'>
            <button className='button right'>Submit</button>
          </div>
        </div>
      </div>
    </Form>
  )
}

export default withFormik({
  mapPropsToValues(props) {
    const { ids } = props.currentParams
    return {
      ids
    }
  },

  handleSubmit(values, { props }) {
    const { ids } = values
    const params = {
      ids
    }

    props.onSubmit(params)
  }

})(IdsFilter)

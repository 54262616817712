import ReactTable from 'react-table'
import moment from 'moment'
import { capitalize } from 'lodash'
import numeral from 'numeral'

const OrdersTableComponent = (props) => {
  const { orders, defaultPageSize } = props
  const columns = [
    {
      Header:     'Date',
      id:         'effective_date',
      accessor:   d => moment(d.effective_date).format("MM/DD/YY"),
      sortMethod: (a, b) => { return new Date(a).getTime() < new Date(b).getTime() }
    },
    {
      Header:     'Invoice',
      id:         'invoice_number',
      accessor:   d => [d.id, d.invoice_number],
      Cell:       row => <a href={`/orders/${row.value[0]}`}>{row.value[1]}</a>
    },

    {
      Header:     'Status',
      id:         'status',
      accessor:   d => capitalize(d.status)
    },
    {
      Header:     'Total',
      id:         'effective_amount',
      accessor:   d => parseFloat(d.effective_amount).toFixed(2),
      Cell:       row => <span>{numeral(row.value).format('$0,0.00')}</span>
    }
  ]

  return (
    <div className="small-12 columns">
      <ReactTable className='-striped -highlight' data={orders} columns={columns} showPagination={false} defaultPageSize={defaultPageSize} />
    </div>
  )
}

export default OrdersTableComponent

import moment from "moment"

export function timeWindowsAttributesFor(timeWindows) {
  return timeWindows.map(requestedTimeWindow => {
    const { id, startTime, endTime, date, _destroy } = requestedTimeWindow
    const momentStart  = moment(startTime, 'HH:mm')
    const momentFinish = moment(endTime, 'HH:mm')
    const startOfToday = moment().startOf('day')
    const start_seconds_since_midnight = momentStart.diff(startOfToday, 'seconds')
    const finish_seconds_since_midnight = momentFinish.diff(startOfToday, 'seconds')

    return {
      id,
      start_seconds_since_midnight,
      finish_seconds_since_midnight,
      day_of_week: moment(date).weekday(),
      _destroy
    }
  })
}

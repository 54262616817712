import React from 'react';
import { withFormik, Form, Field } from 'formik'

import DateInput from '../../../components/form/input/DateInput.jsx'
import AccountSelect from '../../../components/select/AccountSelect.jsx'
import UserSelect from '../../../components/select/UserSelect.jsx'
import WarehouseSelect from '../../../components/select/WarehouseSelect.jsx'
import AccountTypeSelect from '../../../components/select/AccountTypeSelect.jsx'
import DeliveryTypeSelect from '../../../components/select/DeliveryTypeSelect.jsx'
import OrderStatusSelect from '../../../components/select/OrderStatusSelect.jsx'
import FormikSelect from '../../../components/select/base/FormikSelect.jsx'

const StandardFilter = ({ values, errors, touched, setFieldValue, setFieldTouched, dispatch, fetchAccount }) => {
  const onChange = (name, val) => {
    setFieldValue(name, val)
  }

  return (
  <Form>
    <br />
    <div className='StandardFilter'>
      <div className=' row fullWidth'>
        <div className='medium-6 columns'>
          <DateInput name='startDate' value={values.startDate} onChange={val => onChange('startDate', val)} />
        </div>
        <div className='medium-6 columns'>
          <DateInput name='endDate' value={values.endDate} onChange={val => onChange('endDate', val)} />
        </div>
      </div>
      <div className='row fullWidth'>
        <div className='medium-12 columns'>
          <label>Account Select
            <AccountSelect
              name='accountIds'
              multi={true}
              value={values.accountIds}
              dispatch={dispatch}
              onChange={onChange}
              onBlur={setFieldTouched}
              error={errors.accountIds}
              touched={touched.accountIds}
            />
            {errors.accountIds && touched.accountIds && <small className='error'>{errors.accountIds}</small>}
          </label>
        </div>
      </div>
      <br />
      <div className='row fullWidth'>
        <div className='medium-12 columns'>
          <label>Salesperson
            <UserSelect
              name='salespersonIds'
              multi={true}
              value={values.salespersonIds}
              onChange={onChange}
              onBlur={setFieldTouched}
              error={errors.salespersonIds}
              touched={touched.salespersonIds}
            />
            {errors.salespersonIds && touched.salespersonIds && <small className='error'>{errors.salespersonIds}</small>}
          </label>
        </div>
      </div>
      <br />
      <div className='row fullWidth'>
        <div className='medium-12 columns'>
          <label>Order Status
            <OrderStatusSelect
              name='orderStatusIds'
              multi={true}
              value={values.orderStatusIds}
              onChange={onChange}
              onBlur={setFieldTouched}
              error={errors.orderStatusIds}
              touched={touched.orderStatusIds}
            />
            {errors.orderStatusIds && touched.orderStatusIds && <small className='error'>{errors.orderStatusIds}</small>}
          </label>
        </div>
      </div>
      <br />
      <div className='row fullWidth'>
        <div className='medium-12 columns'>
          <label>Account Type
            <AccountTypeSelect
              name='accountTypeIds'
              multi={true}
              value={values.accountTypeIds}
              onChange={onChange}
              onBlur={setFieldTouched}
              error={errors.accountTypeIds}
              touched={touched.accountTypeIds}
            />
            {errors.accountTypeIds && touched.accountTypeIds && <small className='error'>{errors.accountTypeIds}</small>}
          </label>
        </div>
      </div>
      <br />
      <div className='row fullWidth'>
        <div className='medium-12 columns'>
          <label>Delivery Type
            <DeliveryTypeSelect
              name='deliveryTypeIds'
              multi={true}
              value={values.deliveryTypeIds}
              onChange={onChange}
              onBlur={setFieldTouched}
              error={errors.deliveryTypeIds}
              touched={touched.deliveryTypeIds}
            />
            {errors.orderTypeIds && touched.orderTypeIds && <small className='error'>{errors.orderTypeIds}</small>}
          </label>
        </div>
      </div>
      <br />
      <div className='row fullWidth'>
        <div className='medium-12 columns'>
          <label>Warehouse
            <WarehouseSelect
              name='warehouseIds'
              multi={true}
              value={values.warehouseIds}
              onChange={onChange}
              onBlur={setFieldTouched}
              error={errors.warehouseIds}
              touched={touched.warehouseIds}
            />
            {errors.warehouseIds && touched.warehouseIds && <small className='error'>{errors.warehouseIds}</small>}
          </label>
        </div>
      </div>
      <br />
      <div className='row fullWidth'>
        <div className='medium-12 columns'>
          <label>Credit Hold</label>
          <Field
            type="checkbox"
            name="creditHold"
            style={{margin: 0}}
            onChange={(_e) => onChange('creditHold', !values.creditHold)}
            value={values.creditHold}
            onBlur={setFieldTouched}
            error={errors.creditHold}
            touched={touched.creditHold}
          />
        </div>
      </div>
      <br />
      <div className='row fullWidth'>
        <div className='medium-12 columns'>
          <label>Limit
            <FormikSelect
              name='limit'
              multi={false}
              value={values.limit}
              options={[{ label: 10, value: 10 }, { label: 50, value: 50 }, { label: 100, value: 100 }, { label: 200, value: 200 }, { label: 3000, value: 3000 }]}
              onChange={setFieldValue}
              onBlur={setFieldTouched}
              error={errors.salespersonIds}
              touched={touched.salespersonIds}
            />
            {errors.limit && touched.limit && <small className="error">{errors.limit}</small>}
          </label>
        </div>
      </div>
      <br />
      <div className='row fullWidth'>
        <div className='medium-12 columns'>
          <button className='button right'>Submit</button>
        </div>
      </div>
    </div>
  </Form>
  )
}

export default withFormik({
  mapPropsToValues(props) {
    const {
      accountIds,
      salespersonIds,
      orderStatusIds,
      warehouseIds,
      accountTypeIds,
      deliveryTypeIds,
      creditHold,
      startDate,
      endDate,
      limit
    } = props.currentParams
    return {
      accountIds: accountIds || [],
      salespersonIds: salespersonIds || [],
      orderStatusIds: orderStatusIds || [],
      warehouseIds: warehouseIds || [],
      accountTypeIds: accountTypeIds || [],
      deliveryTypeIds: deliveryTypeIds || [],
      creditHold: creditHold || false,
      limit: { label: limit, value: limit },
      startDate: startDate,
      endDate: endDate
    }
  },

  handleSubmit(values, { props }) {
    const {
      accountIds,
      salespersonIds,
      orderStatusIds,
      warehouseIds,
      accountTypeIds,
      deliveryTypeIds,
      creditHold,
      limit,
      startDate,
      endDate
    } = values
    const params = {
      accountIds: accountIds.map(e => e.value),
      salespersonIds: salespersonIds.map(e => e.value),
      orderStatusIds: orderStatusIds.map(e => e.value),
      warehouseIds: warehouseIds.map(e => e.value),
      accountTypeIds: accountTypeIds.map(e => e.value),
      deliveryTypeIds: deliveryTypeIds.map(e => e.value),
      creditHold: creditHold || false,
      startDate: startDate,
      endDate: endDate,
      limit: limit.value
    }
    props.onSubmit(params)
  }
})(StandardFilter)

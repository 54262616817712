import React from 'react';
import createReactClass from 'create-react-class';
import moment from 'moment';
import { base } from '../../timeFormats'

var ProductShow = createReactClass({

  render: function () {
    return (
      <div>
        <div className="row">
          <div className="small-12 columns">
            <h3 className="subheader">{this.props.product.name}</h3>
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns">
            <label>Sellable?
              <input readOnly type="text" name="product[volume]" defaultValue={this.props.product.sellable == true ? "Yes" : "No"} />
            </label>
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns">
            <label>Batch Identifier
              <input readOnly type="text" name="product_inventory_count" defaultValue={this.props.product.batch_identifier} />
            </label>
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns">
            <label>Batch Date
              <input readOnly type="date" name="product_inventory_count" defaultValue={moment(this.props.product.batch_date).format( base)} />
            </label>
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns">
            <label>Available To Promise
              <input readOnly type="text" name="product_inventory_count" defaultValue={this.props.available} />
            </label>
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns">
            <label>Volume
              <input readOnly type="text" name="product[volume]" defaultValue={this.props.product.volume} />
            </label>
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns">
            <label>Beer?
              <input readOnly type="text" name="product[volume]" defaultValue={this.props.product.beer == true ? "Yes" : "No"} />
            </label>
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns">
            <label>Keg?
              <input readOnly type="text" name="product[volume]" defaultValue={this.props.product.keg == true ? "Yes" : "No"} />
            </label>
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns">
            <label>Deposit?
              <input readOnly type="text" name="product[volume]" defaultValue={this.props.product.deposit == true ? "Yes" : "No"} />
            </label>
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns">
            <label>Product Family?
              <input readOnly type="text" name="product[volume]" defaultValue={this.props.product.product_family == null ? "No Product Family" : this.props.product.product_family.name} />
            </label>
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns">
            <label>SKU
              <input readOnly type="text" name="product[sku]" defaultValue={this.props.product.sku == null ? "No SKU" : this.props.product.sku.name} />
            </label>
          </div>
        </div>
        <ProductPricesBox productPrices={this.props.product.prices}/>
        <div className="row">
          <div className="small-12 columns">
            <a href={"/products/"+this.props.product.id+"/edit"} className="button small right">Edit This Product</a>
            <a href="/products" className="button small right">View All Products</a>
            <a href="/products/new" className="button small right">Add New Product</a>
          </div>
        </div>
      </div>

    )
  }
});

var ProductPricesBox = createReactClass({

  getInitialState: function () {
    var newProductPrice = [
    { amount: null,
      county: "San Francisco",
      destroy: 0
    }
    ];
    var productPrices = this.props.productPrices.length == 0 ? newProductPrice : this.props.productPrices;
    return {
      productPrices: productPrices
    }
  },

  onAddProductPrice: function (evt) {
    var productPrice = [
    { amount: null,
      suggestion: null,
      destroy: 0
    }
    ];
    this.setState({productPrices: this.state.productPrices.concat(productPrice)});
  },

  onRemoveProductPrice: function (index) {
    var newProductPrices = this.state.productPrices;
    newProductPrices[index].destroy = 1;
    this.setState({newProductPrices: newProductPrices});
  },

  render: function () {
    return (
      <div>
        <div className="row">
          <div className="small-12 columns">
            <h4 className="subheader">Pricing</h4>
          </div>
        </div>

        {this.state.productPrices.map(function(productPrice, index) {
          var boundClick = this.onRemoveProductPrice.bind(this, index);
          return <ProductPriceWrapper index={index} onRemoveProductPrice ={boundClick} key={index} productPrice={productPrice}/>;
        },this)}

        <div className="row">
          <div className="small-12 columns">
            <button type="button" onClick = {this.onAddProductPrice} className="button tiny">Add Additional Price</button>
            <hr />
          </div>
        </div>
      </div>
    )
  }
});

var ProductPriceWrapper = createReactClass({
  render: function() {
    var inputPartial;
    if (this.props.productPrice.destroy == 1) {
      inputPartial = <DestroyProductPrice index={this.props.index} productPrice={this.props.productPrice} />;
    } else {
      inputPartial = <ProductPrice index={this.props.index} onRemoveProductPrice={this.props.onRemoveProductPrice} productPrice={this.props.productPrice} />;
    }
    return (
      <div>
        {inputPartial}
      </div>
    )
  }
});


var DestroyProductPrice = createReactClass({
  render: function () {
    var inputName = "product[prices_attributes][" + this.props.index + "]";
    return (
        <div>
          <input readOnly type="hidden" name={inputName + "[amount]"} value={this.props.productPrice.amount} />
          <input readOnly type="hidden" name={inputName + "[county]"} value={this.props.productPrice.county} />
          <input readOnly type="hidden" name={inputName + "[id]"} value={this.props.productPrice.id} />
          <input readOnly type="hidden" name={inputName + "[_destroy]"} value={1} />
        </div>
    )
  }
});

var ProductPrice = createReactClass({
  onRemoveProductPrice: function (evt) {
    this.props.onRemoveProductPrice(evt);
  },

  render: function () {
    var inputName = "product[prices_attributes][" + this.props.index + "]";
    var trashStyle = {
      color: '#f04124',
      fontSize: '20px'
    };
    return (
      <div className='row'>
        <div className="medium-6 columns">
          <label>Price</label>
          <input readOnly type="text" name={inputName + "[amount]"} defaultValue={this.props.productPrice.amount} />
        </div>
        <div className="medium-6 columns">
          <div className="row collapse">
            <label>Territory
              <input readOnly type="text" name="product[volume]" defaultValue={this.props.productPrice.county} />
            </label>
          </div>
        </div>
        <input readOnly type="hidden" name={inputName + "[id]"} value={this.props.productPrice.id} />
        <input readOnly type="hidden" name={inputName + "[_destroy]"} value={0} />
      </div>
    )
  }
});

export default ProductShow;

import React, { Component } from 'react'
import { connect } from 'react-redux'

import AccountFormComponent from './components/AccountFormComponent.jsx'

import { fetchAccount } from '../../redux/actions/accounts.js'
import ServerErrors from "../../components/form/ServerErrors"


class AccountForm extends Component {
  componentDidMount() {
    const { dispatch, accountId } = this.props

    if (accountId) dispatch(fetchAccount(accountId))
  }

  render() {
    const { accountId, serverErrors, account, dispatch } = this.props

    return (
      <div className="AcountForm">
        <ServerErrors serverErrors={serverErrors} />
        {
         accountId
         ? account.name && <AccountFormComponent id={accountId} account={account} dispatch={dispatch} />
         : <AccountFormComponent account={account} dispatch={dispatch} />
        }
      </div>
    )

  }
}

const mapStateToProps = (state, props) => {
  const accountId = props.match.params.id

  return {
    accountId,
    serverErrors: state.errors.accounts.create || {},
    account: state.accounts.current.account
  }
}

const connectedAccountForm = connect(mapStateToProps,)(AccountForm);
export default connectedAccountForm

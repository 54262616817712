import React, { Component } from 'react';
import createReactClass from 'create-react-class';
import Select from 'react-virtualized-select'
import axios from 'axios'

const UserTagAccountItem = ({ user, associatedUsers, getUsers, onChange, onRemove }) => {
  const trashStyle = {
    color: '#f04124',
    fontSize: '20px'
  }

  const commonProps = {
    name: "tag[tagged][]",
  }

  let selectProps = {}
  if (user.edit) {
    selectProps = { ...commonProps, async: true, onChange: onChange, loadOptions: getUsers, placeholder: "Select an item.." }
  } else {
    selectProps = { ...commonProps, value: user.id, options: associatedUsers }
  }

  return (
    <div className='row'>
      <div className="row-collapse">
        <div className="medium-10 columns">
            <Select {...selectProps} />
        </div>
        <div className="medium-2 columns">
          <span className="postfix">
            <a href="#" style={trashStyle} onClick={onRemove}><i className="fi-trash"></i></a>
          </span>
        </div>
      </div>
    </div>
  )
}


export default class UserTagForm extends Component {
  constructor(props) {
    super(props)

    const { name, associated_users } = this.props;

    this.state = { 
      name: name,
      associatedUsers: associated_users.map((user) => {
        return { id: user.id, value: user.id, label: [user.first_name, user.last_name].join(' '), edit: false }
      }),
      newItem: { value: null, label: null, edit: true },
    }
  }

  getUsers(term="") {
    return axios.get(`/users.json?quick=true&q=${term}`)
    .then((json) => {
      const users = json['data']['users'].map((user) => {
        return { value: user.id, label: [user.first_name, user.last_name].join(' '), edit: false }
      })

      return { options: users }
    })
  }

  onAddNew() {
    const { associatedUsers, newItem } = this.state
    this.setState({ associatedUsers: associatedUsers.concat(newItem) })
  }

  onRemoveItem(index) {
    const newAssociatedUsers = this.state.associatedUsers.filter((item, i) => index != i)
    this.setState({ associatedUsers: newAssociatedUsers })
  }

  onSelectValueChange(index, newUser) {
    const users = this.state.associatedUsers.slice(0)
    users[index] = { id: newUser.value, value: newUser.value, label: newUser.label, edit: false }
    this.setState({ associatedUsers: users })
  }

  onTitleChange(e) {
    this.setState({ name: e.target.value })
  }

  render() {
    const { name, associatedUsers } = this.state

    const userItems = associatedUsers.map((user, index) => {
      return (
        <div key={index}>
          <UserTagAccountItem user={user} associatedUsers={associatedUsers} getUsers={this.getUsers} onChange={this.onSelectValueChange.bind(this, index)} onRemove={this.onRemoveItem.bind(this, index)} />
        </div>
      )
    })

    return (
      <div>
        { userItems }

        <div className="row">
          <div className="small-12 columns">
            <button type="button" onClick = {this.onAddNew.bind(this)} className="button tiny">Add New User</button>
            <hr />
          </div>
        </div>
      </div>
    )
  }
}

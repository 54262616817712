import React from 'react';
import createReactClass from 'create-react-class';
import { long, short_weekday } from '../../timeFormats'
import moment from 'moment';

var RailsDatetimeFormGroup = createReactClass({

  getInitialState: function () {
    var dateValue = this.props.value == null ? moment() : moment(this.props.value);
    return {value: dateValue.format( long), dayname:dateValue.format(short_weekday)}
  },

  handleChange: function (evt) {
    var actual = evt.target.value;
    var dateValue = moment(actual);
    this.setState({value: dateValue.format( long), dayname:dateValue.format(short_weekday)});
  },

  render: function () {
    return (
      <div className="row">
        <div className="small-12 columns">
          <div className="row collapse">
            <label>{this.props.description}</label>
            <div className="small-6 columns">
              <input type="datetime-local" onChange={this.handleChange} name={this.props.name + "[" + this.props.attr + "]"} defaultValue={this.state.value} />
            </div>
            <div className="small-6 columns">
              <span className="postfix">{this.state.dayname}</span>
            </div>
          </div>
        </div>
      </div>
    )
  }
});

export default RailsDatetimeFormGroup

const initialstate = {
  current: null,
  all: [],
  loading: false,
}

export function payments(state= initialstate, action) {
  const { payload } = action

  switch (action.type) {
    case 'FETCH_ORDER_PAYMENTS_REQUEST':
      return { ...state, loading: true }

    case 'FETCH_ORDER_PAYMENTS_SUCCESS':
      const { payments } = payload

      return { ...state, loading: false, all: payments }

    case 'FETCH_ORDER_PAYMENT_REQUEST':
      return { ...state, loading: true }

    case 'FETCH_ORDER_PAYMENT_SUCCESS':
      const { payment } = payload

      return { ...state, loading: false, current: payment }
    default:
      return state
  }
}
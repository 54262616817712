import { connect } from 'react-redux'
import OrdersEditView from './OrdersEditView.jsx'


class OrdersUnapproveView extends OrdersEditView {
  constructor(props, context) {
    const localProps = {
      ...props,
      initialState: {
        status: 'pending'
      }
    }

    super(localProps, context)
  }
}

const mapStateToProps = (state) => {
  return {
    page: 'unapprove',
    order: state.orders.current.order,
    currentUser: state.currentUser.user,
    skus: state.skus.all || [],
    serverErrors: state.errors.orders.create || {}
  }
}

const connectedOrdersUnapproveView = connect(mapStateToProps)(OrdersUnapproveView)
export default connectedOrdersUnapproveView
import moment from 'moment'
import {base, short_lower_time_12, short_time_with_seconds} from '../../../timeFormats'

const OrderTimeWindow = ({ id, key, date, startTime, endTime, handleChange, onRemoveWindow, disabled }) => {
  const dayname = moment(date).format('dddd')
  const className = disabled ? 'small-12 columns' :'small-10 columns'
  const dateFormatted = moment(date).format(base)
  const startFormatted = moment(startTime, short_time_with_seconds).format(short_lower_time_12)
  const endFormatted = moment(endTime, short_time_with_seconds).format(short_lower_time_12)

  const trashStyle = {
    color: '#f04124',
    fontSize: '20px'
  }

  const onChange = (name, val) => {
    handleChange(id, name, val)
  }

  return (
    <div className='row' key={key}>
      <div className='medium-6 columns'>
        <div className='row collapse'>
          <label>Requested Date</label>
          <div className='small-6 columns'>
            <input
              type='date'
              name='date'
              defaultValue={dateFormatted}
              disabled={disabled}
              onChange={e => onChange('date', e.target.value)}
            />
          </div>
          <div className='small-6 columns'>
            <span className='postfix'>{dayname}</span>
          </div>
        </div>
      </div>
      <div className='medium-3 columns'>
        <label>Start Time</label>
        <input
          name='startTime'
          className={'disabledTimeInput'}
          type={!disabled ? 'time' : 'string' }
          disabled={disabled}
          defaultValue={!disabled ? startTime : startFormatted}
          onChange={e => onChange('startTime', e.target.value)}
        />
      </div>
      <div className='medium-3 columns'>
        <div className='row collapse'>
          <label>Finish Time</label>
          <div className={className}>
            <input
              className={'disabledTimeInput'}
              name='endTime'
              type={!disabled ? 'time' : 'string' }
              disabled={disabled}
              defaultValue={!disabled ? endTime : endFormatted}
              onChange={e => onChange('endTime', e.target.value)}
            />
          </div>
          {!disabled ?
            <div className='small-2 columns'>
              <span className='postfix'>
                <a style={trashStyle} onClick={_e => onRemoveWindow(id)}>
                  <i className='fi-trash'></i>
                </a>
              </span>
            </div>
            : null}
        </div>
      </div>
    </div>
  )
}

export default OrderTimeWindow
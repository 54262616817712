import queryString from 'query-string'

const parseValueLambdaConfig = {
  'string':   (rawVal, acc) => rawVal,
  'array':    (rawVal, acc) => acc.push(rawVal),
  'int':      (rawVal, acc) => parseInt(rawVal),
  'intArray': (rawVal, acc) => acc.push(parseInt(rawVal)),
}

export function parseParams(url, paramsListWithConfig, generalConfig= { arrayFormat: 'bracket' }) {
  const processedParams = { }
  const rawParams = queryString.parse(url, generalConfig)
  for (let param in paramsListWithConfig) {
    const config = paramsListWithConfig[param]
    const parseAs = config.parseAs || 'string'
    const parseValLambda = parseValueLambdaConfig[parseAs]
    const val = parseValLambda(rawParams[param])
    processedParams[param] = val
  }
  return processedParams
}



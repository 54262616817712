import CustomSelect from './base/CustomSelect.jsx'
import { fetchUsers } from '../../redux/actions/users.js'

const UserSelect = (props) => {
  const allProps = { ...props, fetchOptionsAction: () => fetchUsers(), selector: (state) => state["kpi"]["users"] }
  return (
    <CustomSelect { ...allProps } />
  )
}

export default UserSelect

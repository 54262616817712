import moment from 'moment'
import React, { Component } from 'react'
import OrderTimeWindow from './OrderTimeWindow.jsx'
import { removeNestedRecord } from "../../../helpers/removeNestedRecord"
import { base } from '../../../timeFormats'

class RequestedDeliveryTimeWindowsWrapper extends Component {
  constructor(props) {
    super(props)

    this.state = { count: 0 }
  }

  onAddWindow = () => {
    const { handleChange, timeWindows } = this.props
    const { count } = this.state
    const getSuggestedDateOfWeek = () => {
      if (moment().day() === 5) {
        return moment().add(3,'days').format(base)
      } else {
        return moment().add(1,'days').format(base)
      }
    }
    const emptyTimeWindow = {
      _id: count + 1,
      startTime: '09:00',
      endTime: '17:00',
      date: getSuggestedDateOfWeek(),
      _destroy: 0
    }

    this.setState({ count: count + 1 }, () => {
      handleChange('timeWindows', [...timeWindows, emptyTimeWindow])
    })
  }

  onRemoveWindow = (id) => {
    const { handleChange, timeWindows } = this.props
    const timeWindowsWithRemoved = timeWindows.map((record) => {
      if (record._id != id) {
        return record
      } else {
        return removeNestedRecord(record)
      }
    }).filter(el => el != null)

    handleChange('timeWindows', timeWindowsWithRemoved)
  }

  handleChange = (id, name, value) => {
    const { handleChange, timeWindows } = this.props
    const updatedTimeWindows = timeWindows.map(tw => {
      if (tw._id != id) return tw

      return { ...tw, [name]: value }
    })
    handleChange('timeWindows', updatedTimeWindows)
  }

  render() {
    const{ timeWindows } = this.props

    return (
      <div>
        <div className='row'>
          <div className='small-12 columns'>
            <h4 className='subheader'>Requested Delivery Time Windows</h4>
            <button type='button' onClick={this.onAddWindow} className='button tiny'>Add Additional Delivery Window</button>
          </div>
        </div>
        <div className='row'>
          <div className='small-12 columns'>
            {timeWindows.map((timeWindow, i) => {
              if (timeWindow._destroy === 1) return null

              const { _id, id, date, startTime, endTime } = timeWindow
              return (
                <OrderTimeWindow
                  key={_id || id}
                  id={_id}
                  date={date}
                  startTime={startTime}
                  endTime={endTime}
                  handleChange={this.handleChange}
                  onRemoveWindow={this.onRemoveWindow}
                />
              )
            })}
          </div>
        </div>
        <hr />
      </div>
    )
  }
}


export default RequestedDeliveryTimeWindowsWrapper
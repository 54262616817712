import { fetchDistributors } from '../../redux/actions/distributors.js'
import CustomSelect from './base/CustomSelect.jsx'

const DistributorSelect = (props) => {
  const allProps = { ...props, fetchOptionsAction: () => fetchDistributors(), selector: (state) => state.distributors.all }
  return (
    <CustomSelect { ...allProps } />
  )
}

export default DistributorSelect

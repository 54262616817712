import React, { Component } from 'react'
import { connect } from 'react-redux'
import Paginate from '../../components/paginate'
import Spinner from 'react-md-spinner'
import moment from 'moment'

import { fetchVisits, clearVisitsOffset }  from '../../redux/actions/visits.js'
import { fetchCurrentUser }  from '../../redux/actions/users.js'
import VisitsSearchForm from './components/VisitsSearchFormComponent.jsx'
import VisitsTable from './components/VisitsTableComponent.jsx'
import {full_with_utc} from "../../timeFormats"


class VisitsIndexView extends Component {
  constructor(props) {
    super(props)

    this.state = { currentParams: { limit: 50 }, visits: [] }

    this.searchVisits = this.searchVisits.bind(this)
    this.handlePageClick = this.handlePageClick.bind(this)
  }

  componentDidMount() {
    const { currentParams } = this.state
    const { limit } = currentParams
    const { dispatch } = this.props

    dispatch(fetchCurrentUser())
    dispatch(fetchVisits({ limit, offset: 0, viewContext: 'index' }))
  }

  searchVisits(params) {
    const { dispatch } = this.props
    this.setState({ currentParams: params }, () => {
      dispatch(clearVisitsOffset())
      dispatch(fetchVisits({ ...params, viewContext: 'index' }))
    })
  }

  handlePageClick(offset) {
    const { currentParams } = this.state
    const { dispatch } = this.props

    dispatch(fetchVisits({ ...currentParams, offset }))
  }

  handleVisitClick(visit) {
    /*
    const url = viksit.connected_entity.url + '?focusOn=' + notification.notifiable.id
    console.log(window.location)
    console.log(url)
    window.location.replace(url)
    */
  }

  prepareDataForTable(visitsData) {
    const data = visitsData
    const columns = [
      {
        Header:     'Id',
        id:         'id',
        accessor:   d => d.id,
        Cell:       row => <span><a href={`/visits/${row.value}`}>{row.value}</a></span>,
        width:      50
      },
      {
        Header:     'Created At',
        id:         'timestamp',
        accessor:   d => moment(d.created_at, full_with_utc).format(full_12),
        width:      200
      },
      {
        Header:     'Account',
        id:         'account',
        accessor:   d => d.account.name,
        width:      150
      },
      {
        Header:     'Salesperson',
        id:         'salesperson',
        accessor:   d => d.salesperson.name,
        width:      150
      },
      {
        Header:     'Visitor',
        id:         'visitor',
        accessor:   d => d.visitor.name,
        width:      150
      },
      {
        Header:     'Longitude',
        id:         'longitude',
        accessor:   d => d.longitude,
        width:      150
      },
      {
        Header:     'Latitude',
        id:         'latitude',
        accessor:   d => d.latitude,
        width:      150
      },
    ]
    return [data, columns]
  }

  render() {
    const { visits, totalCount, offset, loading, currentUser } = this.props
    const limit = this.state.currentParams.limit
    const [data, columns] = this.prepareDataForTable(visits)

    return (
      <div className="Notifications">
        <div className="row">
          <div className="medium-12 columns">
            <h3 className="subheader">Visits</h3>
            {currentUser.id && <VisitsSearchForm {...this.props} onSubmit={this.searchVisits} />}
          </div>
        </div>
        <br />

        <div className="row">
          <div className="medium-12 columns">
            <Paginate dataName="Notifications" totalCount={totalCount} limit={limit} offset={offset} onPageChange={this.handlePageClick} />
            { loading
              ? <Spinner />
              : <VisitsTable data={data} columns={columns} limit={limit} />
            }
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownState) => {
  return {
    loading: state.visits.all.loading || false,
    visits: state.visits.all.visits || [],
    offset: state.visits.all.offset || 0,
    totalCount: state.visits.all.totalCount || 0,
    currentUser: state.currentUser.user,
  }
}

const connectedVisitsIndex = connect(mapStateToProps,)(VisitsIndexView)
export default connectedVisitsIndex

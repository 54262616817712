const initialState = {
  current: {
    comments: [],
    pinnedComments: []
  },
  all: {
    comments: {
      loading: false,
      totalCount: 0,
      offset: 0,
      limit: 25,
      page: 0
    },
    pinnedComments: {
      loading: false,
      totalCount: 0,
      offset: 0,
      limit: 5,
      page: 0
    }
  }
}

export function comments(state = initialState, action) {
  const { payload } = action
  const { current, all } = state

  switch (action.type) {
    case 'FETCH_COMMENTS_REQUEST':
      const commentsWithLoading = { ...all.comments, loading: true }
      return { ...state, all: { ...all, comments: commentsWithLoading }};

    case 'FETCH_COMMENTS_SUCCESS':
      const currentWithUpdatedComments = { ...current, comments: payload.comments }
      const kaminaryCommentsPage = payload.meta.page > 0 ? payload.meta.page - 1 : payload.meta.page
      const allWithComments = { ...all, comments: { ...all.comments, totalCount: payload.meta.count, offset: payload.meta.offset, page: kaminaryCommentsPage, loading: false }}
      return { ...state, current: currentWithUpdatedComments, all: allWithComments};

    case 'FETCH_PINNED_COMMENTS_REQUEST':
      const pinnedCommentsWithLoading = { ...all.pinnedComments, loading: true }
      return { ...state, all: { ...all, pinnedComments: pinnedCommentsWithLoading }};

    case 'FETCH_PINNED_COMMENTS_SUCCESS':
      const currentWithUpdatedPinnedComments = { ...current, pinnedComments: payload.comments }
      const kaminaryPinnedCommentsPage = payload.meta.page > 0 ? payload.meta.page - 1 : payload.meta.page
      const pinnedCommentsWithPagination = { ...all.pinnedComments, totalCount: payload.meta.count, offset: payload.meta.offset, page: kaminaryPinnedCommentsPage, loading: false }
      const allWithPinnedComments = { ...all, loading: false, pinnedComments: pinnedCommentsWithPagination }
      return { ...state, current: currentWithUpdatedPinnedComments, all: allWithPinnedComments};

    case 'UPLOAD_COMMENT_ATTACHMENT_REQUEST':
      return { ...state, current: { ...current, comments: current.comments.map(c => c.id === payload ? { ...c, uploading: true } : c) } }

    default:
      return state;
  }
}
import AccountSelect from '../../../../components/select/AccountSelect.jsx'

const SearchForm = ({ accountIds, name, email, phone, onChange, onSubmit, dispatch }) => {
  return (
    <div>
      <div className='row fullWidth'>
        <div className='medium-12 columns'>
          <label>Name
            <input name='name' value={name} onChange={e => onChange('name', e.target.value)} />
          </label>
        </div>
      </div>
      <div className='row fullWidth'>
        <div className='medium-12 columns'>
          <label>Email
            <input name='email' value={email} onChange={e => onChange('email', e.target.value)} />
          </label>
        </div>
      </div>
      <div className='row fullWidth'>
        <div className='medium-12 columns'>
          <label>Phone
            <input name='phone' value={phone} onChange={e => onChange('phone', e.target.value)} />
          </label>
        </div>
      </div>
      <div className='row fullWidth'>
        <div className='medium-12 columns'>
          <label>Account Select
            <AccountSelect
              name='accountIds'
              multi={true}
              value={accountIds}
              dispatch={dispatch}
              onChange={(name, values) => onChange(name, values.map(val => val.value))}
            />
          </label>
        </div>
      </div>
      <br />
      <div className='row fullWidth'>
        <div className='medium-12 columns'>
          <button className='button right' onClick={onSubmit}>Submit</button>
        </div>
      </div>
    </div>
  )
}

export default SearchForm
import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  fetchServiceExceptionCategory
} from "../../redux/actions/serviceExceptionsCategories"
import ServiceExceptionCategoriesForm from "./components/ServiceExceptionCategoriesForm.jsx"
import ServerErrors from "../../components/form/ServerErrors"


class ServiceExceptionCategoriesEditView extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    const { dispatch, match } = this.props

    dispatch(fetchServiceExceptionCategory(match.params.id))
  }

  render() {
    const { serviceExceptionCategory, dispatch, serverErrors } = this.props

    return (
      <div className="ServiceExceptionCategoriesNew">
        <ServerErrors serverErrors={serverErrors} />
        <div className="row">
          <div className="medium-12 columns">
            <h3>Edit Service Exception Category</h3>
            <ServiceExceptionCategoriesForm
              dispatch={dispatch}
              serviceExceptionCategory={serviceExceptionCategory}
            />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, _props) => {
  return {
    serviceExceptionCategory: state.serviceExceptionCategories.current.serviceExceptionCategory,
    serverErrors: state.serviceExceptionCategories.current.errors,
    types: state.serviceExceptionCategories.current.types
  }
}

const connectedServiceExceptionCategoriesEditView = connect(mapStateToProps,)(ServiceExceptionCategoriesEditView)
export default connectedServiceExceptionCategoriesEditView
import React from 'react';
import Select from 'react-select'
import { withFormik, Form, Field } from 'formik'
import Yup from 'yup'

import UserSelect from '../../../components/select/UserSelect.jsx'
import AccountTypeSelect from '../../../components/select/AccountTypeSelect.jsx'
import FormikSelect from '../../../components/select/base/FormikSelect.jsx'

const NotificationsSearchFormComponent = ({ values, errors, touched, isSubmitting, setFieldValue, setFieldTouched }) => {
  return (
  <Form>
    <div>
      <div className="row">
        <div className="medium-12 columns">
          <label>Actor
            <UserSelect
              name="actorIds"
              multi={true}
              value={values.actorIds}
              onChange={setFieldValue}
              onBlur={setFieldTouched}
              error={errors.actorIds}
              touched={touched.actorIds}
            />
            {errors.actorIds && touched.actorIds && <small className="error">{errors.actorIds}</small>}
          </label>
        </div>
      </div>
      <br />
      <div className="row">
        <div className="medium-12 columns">
          <label>Recipient
            <UserSelect
              name="recipientIds"
              multi={true}
              value={values.recipientIds}
              onChange={setFieldValue}
              onBlur={setFieldTouched}
              error={errors.recipientIds}
              touched={touched.recipientIds}
            />
            {errors.recipientIds && touched.recipientIds && <small className="error">{errors.recipientIds}</small>}
          </label>
        </div>
      </div>
      <br />
      <div className="row">
        <div className="medium-12 columns">
          <label>Limit
            <FormikSelect
              name="limit"
              multi={false}
              value={values.limit}
              options={[{ label: 10, value: 10 }, { label: 50, value: 50 }, { label: 100, value: 100 }, { label: 200, value: 200 }, { label: 3000, value: 3000 }]}
              onChange={setFieldValue}
              onBlur={setFieldTouched}
              error={errors.limit}
              touched={touched.limit}
              touched={touched.limit}
            />
            {errors.limit && touched.limit && <small className="error">{errors.limit}</small>}
          </label>
        </div>
      </div>
      <br />
      <div className="row">
        <div className="medium-12 columns">
          <button>Submit</button>
        </div>
      </div>
    </div>
  </Form>
  )
}

export default withFormik({
  mapPropsToValues(props) {
    return {
      name: '',
      actorIds: [],
      recipientIds: [props.currentUser.id],
      limit: { label: 50, value: 50 }
    }
  },
  handleSubmit(values, { resetForm, setErrors, setSubmitting, props }) {
    const { name, limit, actorIds, recipientIds } = values
    const params = {
      name,
      limit: limit.value,
      actorIds: actorIds.map(e => e.value),
      recipientIds: recipientIds.map(e => e.value),
    }
    props.onSubmit(params)
  }
})(NotificationsSearchFormComponent)

import { createStore, applyMiddleware, combineReducers } from 'redux';
import { composeWithDevTools } from 'redux-devtools-extension';
import ReduxThunk from 'redux-thunk';
import createDebounce from 'redux-debounced';

import { accounts } from './reducers/accounts.js';
import { accountGroups } from './reducers/accountGroups.js'
import { accountTags } from './reducers/accountTags.js';
import { addresses } from './reducers/addresses.js';
import { comments } from './reducers/comments';
import { commentTags } from './reducers/commentTags.js';
import { contacts } from './reducers/contacts.js';
import { currentUser } from './reducers/currentUser.js';
import { deliveryTypes } from './reducers/deliveryTypes.js';
import { distributors } from './reducers/distributors.js';
import { freightShipments } from './reducers/freightShipments.js';
import { errors } from './reducers/errors.js';
import { kpi } from './reducers/kpi.js';
import { notifications } from './reducers/notifications.js';
import { orders } from './reducers/orders.js';
import { orderTypes } from './reducers/orderTypes.js';
import { payments } from './reducers/payments.js';
import { skus } from './reducers/skus.js'
import { serviceExceptions } from './reducers/serviceExceptions.js'
import { serviceExceptionCategories } from './reducers/serviceExceptionsCategories.js'
import { visits } from './reducers/visits.js';

let rootReducer = combineReducers({
  accounts,
  accountGroups,
  accountTags,
  addresses,
  comments,
  commentTags,
  contacts,
  currentUser,
  errors,
  freightShipments,
  kpi,
  notifications,
  visits,
  orders,
  orderTypes,
  payments,
  deliveryTypes,
  distributors,
  skus,
  serviceExceptions,
  serviceExceptionCategories,
});

let store = createStore(
  rootReducer,
  composeWithDevTools(applyMiddleware(createDebounce(), ReduxThunk))
);

export default store;


import React from 'react';
import Select from 'react-select'
import { withFormik, Form, Field } from 'formik'
import Yup from 'yup'

import UserSelect from '../../../components/select/UserSelect.jsx'
import AccountSelect from '../../../components/select/AccountSelect.jsx'
import FormikSelect from '../../../components/select/base/FormikSelect.jsx'

const VisitsSearchFormComponent = ({ values, errors, touched, isSubmitting, setFieldValue, setFieldTouched }) => {
  return (
  <Form>
    <div>
      <div className="row">
        <div className="medium-12 columns">
          <label>Account
            <AccountSelect
              name="accountIds"
              multi={true}
              value={values.accountIds}
              onChange={setFieldValue}
              onBlur={setFieldTouched}
              error={errors.accountIds}
              touched={touched.accountIds}
            />
            {errors.accountIds && touched.accountIds && <small className="error">{errors.accountIds}</small>}
          </label>
        </div>
      </div>
      <br />
      <div className="row">
        <div className="medium-12 columns">
          <label>Visitor
            <UserSelect
              name="visitorIds"
              multi={true}
              value={values.visitorIds}
              onChange={setFieldValue}
              onBlur={setFieldTouched}
              error={errors.visitorIds}
              touched={touched.visitorIds}
            />
            {errors.visitorIds && touched.visitorIds && <small className="error">{errors.visitorIds}</small>}
          </label>
        </div>
      </div>
      <br />
      <div className="row">
        <div className="medium-12 columns">
          <label>Salespersons
            <UserSelect
              name="salespersonIds"
              multi={true}
              value={values.salespersonIds}
              onChange={setFieldValue}
              onBlur={setFieldTouched}
              error={errors.salespersonIds}
              touched={touched.salespersonIds}
            />
            {errors.salespersonIds && touched.salespersonIds && <small className="error">{errors.salespersonIds}</small>}
          </label>
        </div>
      </div>
      <br />
      <div className="row">
        <div className="medium-12 columns">
          <label>Limit
            <FormikSelect
              name="limit"
              multi={false}
              value={values.limit}
              options={[{ label: 10, value: 10 }, { label: 50, value: 50 }, { label: 100, value: 100 }, { label: 200, value: 200 }, { label: 3000, value: 3000 }]}
              onChange={setFieldValue}
              onBlur={setFieldTouched}
              error={errors.limit}
              touched={touched.limit}
              touched={touched.limit}
            />
            {errors.limit && touched.limit && <small className="error">{errors.limit}</small>}
          </label>
        </div>
      </div>
      <br />
      <div className="row">
        <div className="medium-12 columns">
          <button>Submit</button>
        </div>
      </div>
    </div>
  </Form>
  )
}

export default withFormik({
  mapPropsToValues(props) {
    return {
      name: '',
      accountIds: [],
      visitorIds: [],
      salespersonIds: [],
      limit: { label: 50, value: 50 }
    }
  },
  handleSubmit(values, { resetForm, setErrors, setSubmitting, props }) {
    const { name, limit, accountIds, visitorIds, salespersonIds } = values
    const params = {
      name,
      limit: limit.value,
      accountIds: accountIds.map(e => e.value),
      visitorIds: visitorIds.map(e => e.value),
      salespersonIds: salespersonIds.map(e => e.value),
    }
    props.onSubmit(params)
  }
})(VisitsSearchFormComponent)

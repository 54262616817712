const initialstate = {
  all: []
}

export function distributors(state=initialstate, action) {
  const { payload } = action

  switch (action.type) {
    case 'FETCH_DISTRIBUTORS_SUCCESS':
      return { ...state, all: payload }

    default:
      return state
  }
}
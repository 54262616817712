import moment from 'moment'
import {full, full_12_other} from "../../timeFormats"

const Activity = (props) => {
  const { activity, owner } = props
  const { created_at, method, changes } = activity
  const createdAt = moment(created_at, full).format(full_12_other)

  return (
    <div className='Activity'>
      {Object.keys(changes).map((field, index) => {
        return (
          <p key={field}>
            {owner.name}: {method} <b>{field}</b> from <b>{changes[field][0]}</b> to <b>{changes[field][1]}</b> at {createdAt}
          </p>
        )
      })}
    </div>
  )
}

export default Activity

const initialState = {
  current: {
    serviceExceptionCategory: {},
    serviceExceptionCategories: [],
    errors: {},
    types: [
      { id: 'Account', name: 'Account' },
      { id: 'Contact', name: 'Contact' },
      { id: 'Deliveryroute', name: 'Deliveryroute' },
      { id: 'Orderunit', name: 'Orderunit' },
      { id: 'TimeWindow', name: 'TimeWindow' },
      { id: 'Payment', name: 'Payment' },
      { id: 'Vehicle', name: 'Vehicle' },
      // { id: 'Sales', name: 'Sales' },
      // { id: 'Logistics', name: 'Logistics' }
    ]
  },
  all: {
    loading: false,
    offset: 25,
    totalCount: 0,
    page: 0
  },
}

export function serviceExceptionCategories(state = initialState, action) {
  const { payload } = action
  const { current, all } = state

  switch (action.type) {
    case 'FETCH_SERVICE_EXCEPTIONS_CATEGORIES_REQUEST':
      return { ...state, all: { ...all, loading: true } };

    case 'FETCH_SERVICE_EXCEPTIONS_CATEGORIES_SUCCESS':
      const serviceExceptionCategories = payload.service_exception_categories

      return {...state, current: {...current, serviceExceptionCategories: serviceExceptionCategories}, all: { ...all, loading: false}};

    case 'FETCH_SERVICE_EXCEPTIONS_CATEGORY_REQUEST':
      return { ...state, all: { ...all, loading: true } };

    case 'FETCH_SERVICE_EXCEPTIONS_CATEGORY_SUCCESS':
      const serviceExceptionCategory = payload.service_exception_category

      return { ...state, current: { ...current, serviceExceptionCategory}, all: { ...all, loading: false } };

    default:
      return state;
  }
}
import React, { Component } from 'react'
import { connect } from 'react-redux'
import geolib from 'geolib'
import Spinner from 'react-md-spinner'
import CommentsDashboard from '../../components/comments/CommentsDashboard.jsx'

import { fetchVisit } from '../../redux/actions/visits.js'
import { fetchAccounts } from '../../redux/actions/accounts.js'
import { fetchUsers, fetchCurrentUser } from '../../redux/actions/users.js'


class VisitEditView extends Component {
  constructor(props) {
    super(props)

    const { match } = this.props
    this.state = { visitId: match.params.id, account_id: null, account: null, currentPosition: null }
  }

  componentDidMount() {
    const { visitId } = this.state
    const { dispatch }  = this.props

    dispatch(fetchAccounts('quick'))
    dispatch(fetchUsers('quick'))
    dispatch(fetchVisit(visitId))
    dispatch(fetchCurrentUser())
    navigator.geolocation.getCurrentPosition(position => this.setState({ currentPosition: position.coords }))
  }

  render() {
    const { currentPosition } = this.state
    const { visit } = this.props

    if (visit && visit.id && currentPosition && currentPosition.longitude && currentPosition.latitude) {
      const { account } = visit
      const { address } = account
      return (

        <div>
          <br />
          <div className="row">
            <div className="small-12 columns">
              <h3 className="subheader">Visit Edit</h3>
            </div>
          </div>
          <div className="row">
            <div className="small-12 columns">
              <label>Account
                <input value={visit.account.name} readOnly name="account" />
              </label>
            </div>
          </div>
          <div className="row">
            <div className="small-6 columns">
              <label>Account Sales Person
                <input value={visit.salesperson.name} readOnly name="salesperson" />
              </label>
            </div>
            <div className="small-6 columns">
              <label>Visit Logged By
                <input value={visit.visitor.name} readOnly name="visitor" />
              </label>
            </div>
          </div>
          <div className="row">
            { visit.id && currentPosition.longitude && <DistanceLongLatComponent location={address} long={visit.longitude} lat={visit.latitude} /> }
          </div>
          <div className="row">
            <div className="small-12 columns">
              <div className="content active" id="comments">
                {<CommentsDashboard commentableType='visit' commentableId={visit.id} />}
              </div>
            </div>
          </div>
        </div>
      )
    } else {
      return (
        <div>
          <div className="row">
            <div className="small-12 columns">
              <h3 className="subheader">Visit Edit</h3>
              <Spinner />
            </div>
          </div>
        </div>
      )
    }
  }

}

const mapStateToProps = (state, props) => {
  const { visit } = state.visits.current
  return {
    visit
  }
}

const connectedVisitEditView = connect(mapStateToProps,)(VisitEditView)
export default connectedVisitEditView


const DistanceLongLatComponent = ({ long, lat, location }) => {

  if (!long || !lat || !location) return null

  const distance = geolib.getDistance({latitude: lat, longitude: long}, {latitude: location.latitude, longitude: location.longitude}, {unit: 'ft'})

  return (
    <section>
      <div className="small-4 columns">
        <label>Distance
        <input readOnly type="text" value={distance} />
        </label>
      </div>
      <div className="small-4 columns">
        <label>Longitude
        <input readOnly type="text" value={long} />
        </label>
      </div>
      <div className="small-4 columns">
        <label>Latitude
        <input readOnly type="text" value={lat} />
        </label>
      </div>
    </section>
  )
}


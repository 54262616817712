import React from 'react';
import { withFormik, Form, Field } from 'formik'

import AccountTypeSelect from '../../../components/select/AccountTypeSelect.jsx'
import ProspectTypeSelect from '../../../components/select/ProspectTypeSelect.jsx'
import ProspectSourceSelect from '../../../components/select/ProspectSourceSelect.jsx'
import UserSelect from '../../../components/select/UserSelect.jsx'
import FormikSelect from '../../../components/select/base/FormikSelect.jsx'
import AccountTagSelect from '../../../components/select/AccountTagSelect.jsx'

const AccountsSearchFormComponent = ({ values, errors, touched, isSubmitting, setFieldValue, setFieldTouched }) => {
  const onChange = (name, valObj) => {
    setFieldValue(name, valObj.map(e => e.value))
  }

  const limitOptions = [
    { label: 10, value: 10 },
    { label: 50, value: 50 },
    { label: 100, value: 100 },
    { label: 200, value: 200 },
    { label: 3000, value: 3000 },
    { label: 10000, value: 10000 }
    ]

  return (
  <Form>
    <div>
      <br />
      <div className="row fullWidth">
        <div className="medium-12 columns">
          <label>Account Name
            <Field type="text" name="name" value={values.name} />
            {errors.name && touched.name && <small className="error">{errors.name}</small>}
          </label>
        </div>
      </div>
      <div className="row fullWidth">
        <div className="medium-12 columns">
          <label>Prospect Type
            <ProspectTypeSelect
              name="prospectTypeIds"
              multi={true}
              value={values.prospectTypeIds}
              onChange={onChange}
              onBlur={setFieldTouched}
              error={errors.prospectTypeIds}
              touched={touched.prospectTypeIds}
            />
            {errors.prospectTypeIds && touched.prospectTypeIds && <small className="error">{errors.prospectTypeIds}</small>}
          </label>
        </div>
      </div>
      <br />
      <div className="row fullWidth">
        <div className="medium-12 columns">
          <label>Account Type
            <AccountTypeSelect
              name="accountTypeIds"
              multi={true}
              value={values.accountTypeIds}
              onChange={onChange}
              onBlur={setFieldTouched}
              error={errors.accountTypeIds}
              touched={touched.accountTypeIds}
            />
            {errors.accountTypeIds && touched.accountTypeIds && <small className="error">{errors.accountTypeIds}</small>}
          </label>
        </div>
      </div>
      <br />
      <div className="row fullWidth">
        <div className="medium-12 columns">
          <label>Account Tags
            <AccountTagSelect
              name="accountTagIds"
              multi={true}
              value={values.accountTagIds}
              onChange={onChange}
              onBlur={setFieldTouched}
              error={errors.accountTypeIds}
              touched={touched.accountTagIds}
            />
            {errors.accountTagIds && touched.accountTagIds && <small className="error">{errors.accountTagIds}</small>}
          </label>
        </div>
      </div>
      <br />
      <div className="row fullWidth">
        <div className="medium-12 columns">
          <label>Salesperson
            <UserSelect
              name="salespersonIds"
              multi={true}
              value={values.salespersonIds}
              onChange={onChange}
              onBlur={setFieldTouched}
              error={errors.salespersonIds}
              touched={touched.salespersonIds}
            />
            {errors.salespersonIds && touched.salespersonIds && <small className="error">{errors.salespersonIds}</small>}
          </label>
        </div>
      </div>
      <br />
      <div className="row fullWidth">
        <div className="medium-12 columns">
          <label>Prospect Source
            <ProspectSourceSelect
              name="prospectSourceIds"
              multi={true}
              value={values.prospectSourceIds}
              onChange={onChange}
              onBlur={setFieldTouched}
              error={errors.prospectSourceIds}
              touched={touched.prospectSourceIds}
            />
            {errors.prospectSourceIds && touched.prospectSourceIds && <small className="error">{errors.prospectSourceIds}</small>}
          </label>
        </div>
      </div>
      <br />
      <div className="row fullWidth">
        <div className="medium-12 columns">
          <label>Limit
            <FormikSelect
              name="limit"
              multi={false}
              value={values.limit}
              options={limitOptions}
              onChange={setFieldValue}
              onBlur={setFieldTouched}
              error={errors.salespersonIds}
              touched={touched.salespersonIds}
              touched={touched.salespersonIds}
            />
            {errors.limit && touched.limit && <small className="error">{errors.limit}</small>}
          </label>
        </div>
      </div>
      <br />
      <div className="row fullWidth">
        <div className="medium-12 columns">
          <button className= "button right">Submit</button>
        </div>
      </div>
    </div>
  </Form>
  )
}

export default withFormik({
  mapPropsToValues(props) {
    const { currentParams } = props
    const { name, accountTypeIds, accountTagIds, salespersonIds, prospectTypeIds, prospectSourceIds, limit } = currentParams
    return {
      name: name || '',
      accountTypeIds: accountTypeIds || [],
      accountTagIds: accountTagIds || [],
      salespersonIds: salespersonIds || [],
      prospectTypeIds: prospectTypeIds || [],
      prospectSourceIds: prospectSourceIds || [],
      limit: { label: limit, value: limit }
    }
  },
  handleSubmit(values, { resetForm, setErrors, setSubmitting, props }) {
    const { name, limit, accountTypeIds, accountTagIds, salespersonIds, prospectTypeIds, prospectSourceIds } = values
    const params = {
      name,
      limit: limit.value,
      accountTypeIds: accountTypeIds,
      accountTagIds: accountTagIds,
      salespersonIds: salespersonIds,
      prospectTypeIds: prospectTypeIds,
      prospectSourceIds: prospectSourceIds,
    }
    props.onSubmit(params)
  }
})(AccountsSearchFormComponent)

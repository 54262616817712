import React from 'react';
import Select from 'react-virtualized-select'
import { withFormik, Form, Field } from 'formik'

import AccountTypeSelect from '../../../../components/select/AccountTypeSelect.jsx'
import WarehouseSelect from '../../../../components/select/WarehouseSelect.jsx'
import UserSelect from '../../../../components/select/UserSelect.jsx'

const MapReportFormComponent = ({ values, errors, touched, isSubmitting, setFieldValue, setFieldTouched, submitForm }) => {
  const onChange = (name, value) => {
    setFieldValue(name, value)
    setTimeout(() => submitForm(), 100)
  }
  return (
  <Form>
    <h5 className="subheader">Account Filters</h5>
    <div>
      <div className="row">
        <div className="medium-12 columns">
          <label>
            <AccountTypeSelect
              placeholder="Select Account Types"
              name="accountTypes"
              multi={true}
              value={values.accountTypes}
              onChange={onChange}
              onBlur={setFieldTouched}
              error={errors.accountTypes}
              touched={touched.accountTypes}
            />
          </label>
        </div>
      </div>
      <br />
      <div className="row">
        <div className="medium-12 columns">
          <label>
            <WarehouseSelect
              placeholder="Select Warehouses"
              name="warehouses"
              multi={true}
              value={values.warehouses}
              onChange={onChange}
              onBlur={setFieldTouched}
              error={errors.warehouses}
              touched={touched.warehouses}
            />
          </label>
        </div>
      </div>
      <br />
      <div className="row">
        <div className="medium-12 columns">
          <label>
            <UserSelect
              placeholder="Select Account Salespersons"
              name="accountSalespersons"
              multi={true}
              value={values.accountSalespersons}
              onChange={onChange}
              onBlur={setFieldTouched}
              error={errors.accountSalespersons}
              touched={touched.accountSalespersons}
            />
          </label>
        </div>
      </div>
      <br />
      {/*
      <div className="row">
        <div className="medium-12 columns">
          <button className="tiny">Submit</button>
        </div>
      </div>
      */}
    </div>
  </Form>
  )
}

export default withFormik({
  enableReinitialize: true,
  mapPropsToValues(props) {
    const { accountTypes, warehouses, accountSalespersons } = props
    return {
      accountTypes: accountTypes || [],
      warehouses: warehouses || [],
      accountSalespersons: accountSalespersons || []
    }
  },
  handleSubmit(values, { resetForm, setErrors, setSubmitting, props }) {
    const { accountTypes, warehouses, accountSalespersons } = values
    const params = { accountTypes, warehouses, accountSalespersons }
    props.onSubmit(params)
  }
})(MapReportFormComponent)

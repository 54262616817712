import { fetchDeliveryTypes } from '../../redux/actions/deliveryTypes.js'
import CustomSelect from './base/CustomSelect.jsx'

const DeliveryTypeSelect = (props) => {
  const allProps = { ...props, fetchOptionsAction: () => fetchDeliveryTypes(), selector: (state) => state['deliveryTypes']['all']  }
  return (
    <CustomSelect { ...allProps } />
  )
}

export default DeliveryTypeSelect
import React, { Component } from 'react'
import $ from 'jquery'
import { connect } from 'react-redux'
import Paginate from "../paginate"
import Spinner from "react-md-spinner"
import Comment from './Comment.jsx'
import CommentInput from './CommentInput.jsx'

import { fetchUsers } from '../../redux/actions/users.js'

import {
  fetchComments,
  fetchPinnedComments,
  fetchCommentTags,
  createComment,
  updateComment,
  uploadAttachment
} from '../../redux/actions/comments.js'


class CommentsDashboard extends Component {
  constructor(props) {
    super(props)

    this.onSubmit = this.onSubmit.bind(this)
    this.renderCommentInputBox = this.renderCommentInputBox.bind(this)
    this.renderCommentUpdateBox = this.renderCommentUpdateBox.bind(this)
  }

  componentDidMount() {
    const that = this
    const { dispatch } = this.props

    this.dispatchChanges()
    dispatch(fetchCommentTags())
    dispatch(fetchUsers())

    $(document).ready(function() {
      $(document).on('click', 'a.upload', (e) => {
        const fileInput = $(e.target).siblings('input[type="file"')[0]
        $(fileInput).click()
      })

      $(document).on('change', 'input[type="file"]', (e) => {
        const comment   = $(e.target).closest('.comment')
        const id        = $(comment).data('id')
        const url       = `/comments/${id}/attachments`
        const file      = e.target.files[0]
        const formData  = new FormData();
        const onSuccess = (response) => that.dispatchChanges(response)
        const onError   = (response) => alert('Upload Failed', response)

        formData.append('comment[image]', file, file.name);
        dispatch(uploadAttachment(id, url, formData, onSuccess, onError))
      })
    })
  }

  dispatchChanges(_response) {
    const { dispatch, commentableType, commentableId, pinnedCommentsOffset, pinnedCommentsPage, commentsOffset, commentsPage, withPinned } = this.props

    dispatch(fetchComments({ commentableType, commentableId, commentsOffset, commentsPage }))

    if (withPinned) {
      dispatch(fetchPinnedComments({ commentableType, commentableId, pinnedCommentsOffset, pinnedCommentsPage }))
    }
  }

  onSubmit(comment) {
    const { dispatch } = this.props

    if (comment.id) {
      dispatch(updateComment(comment, (response => this.dispatchChanges(response))))
    } else {
      dispatch(createComment(comment, (response => this.dispatchChanges(response))))
    }
  }

  handlePinnedCommentsPageClick(pinnedCommentsOffset, pinnedCommentsPage) {
    const { dispatch, commentableType, commentableId } = this.props

    dispatch(fetchPinnedComments({ commentableType, commentableId, pinnedCommentsOffset, pinnedCommentsPage }))
  }

  handleCommentsPageClick(commentsOffset, commentsPage) {
    const { dispatch, commentableType, commentableId } = this.props

    dispatch(fetchComments({ commentableType, commentableId, commentsOffset, commentsPage }))
  }

  renderCommentInputBox(commentableType, commentableId) {
    const { users, commentTags, readOnly } = this.props

    if (!readOnly) {
      return (
        <CommentInput
          commentableType={commentableType}
          commentableId={commentableId}
          commentTags={commentTags}
          users={users}
          onSubmit={this.onSubmit}
        />
      )
    }
  }

  renderCommentUpdateBox(commentId, editorState, onClickCancel) {
    const { users, commentTags } = this.props

    return (
      <CommentInput
        id={commentId}
        commentTags={commentTags}
        editMode={true}
        editorState={editorState}
        users={users}
        onCancel={onClickCancel}
        onSubmit={this.onSubmit}
      />
    )
  }

  renderCommentBox(comment) {
    const { focusOn, currentUser, readOnly, withPinned } = this.props

    return (
      <Comment
        key={comment.id}
        currentUser={currentUser}
        focusOn={focusOn}
        isRoot={true}
        withPinned={withPinned}
        readOnly={readOnly}
        renderCommentInputBox={this.renderCommentInputBox}
        renderCommentUpdateBox={this.renderCommentUpdateBox}
        updateComment={this.onSubmit}
        {...comment}
      />
    )
  }

  renderPinnedComments(comments, caption) {
    const { pinnedCommentsTotalCount, pinnedCommentsLimit,  pinnedCommentsPage,  pinnedCommentsOffset, pinnedCommentsLoading } = this.props

    return (
      <div className="row fullWidth">
        <div className="medium-12 columns">
          <span className='tinyheader'>{caption}</span>
          <Paginate
            dataName="Pinned Comments"
            totalCount={pinnedCommentsTotalCount}
            limit={parseInt(pinnedCommentsLimit)}
            page={parseInt(pinnedCommentsPage)}
            offset={parseInt(pinnedCommentsOffset)}
            onPageChange={this.handlePinnedCommentsPageClick.bind(this)}
          />
          {pinnedCommentsLoading ? <Spinner /> : this.renderPaginatedComments(comments, caption, true)}
        </div>
      </div>
    )
  }

  renderComments(comments, caption) {
    const { commentsTotalCount, commentsLimit,  commentsPage,  commentsOffset, commentsLoading } = this.props

    return (
      <div className="row fullWidth">
        <div className="medium-12 columns">
          <span className='tinyheader'>{caption}</span>
          <Paginate
            dataName="Comments"
            totalCount={commentsTotalCount}
            limit={parseInt(commentsLimit)}
            page={parseInt(commentsPage)}
            offset={parseInt(commentsOffset)}
            onPageChange={this.handleCommentsPageClick.bind(this)}
          />
          {commentsLoading  ? <Spinner /> : this.renderPaginatedComments(comments, caption, true)}
        </div>
      </div>
    )
  }

  renderPaginatedComments(comments, caption, hidden) {
    const sortedComments = comments && comments.map(comment => this.renderCommentBox(comment))

    return (
      <div className='comments'>
        {!hidden ? <span className='tinyheader'>{caption}</span> : null}
        {sortedComments}
      </div>
    )
  }

  render() {
    const { comments, pinnedComments, commentableType, commentableId, error, withPinned  } = this.props

    return (
      <div className="comments-dashboard">
        {error && <span className="error">{error}</span>}
        {this.renderCommentInputBox(commentableType, commentableId)}
        {withPinned ? this.renderPinnedComments(pinnedComments, 'Pinned Comments') : null}
        {this.renderComments(comments, 'Comments')}
      </div>
    )
  }
}

const mapStateToProps = (state, _props) => {
  const currentUser = state.currentUser.user

  const users = (state.kpi.users || []).map(u => {
    return { ...u, avatar: u.avatar_url, link: `/users/${u.id}` }
  })

  const commentTags = (state.commentTags.all || []).map(ct => {
    return { ...ct, is_comment_tag: true }
  })

  return {
    comments: state.comments.current.comments,
    pinnedComments: state.comments.current.pinnedComments,
    pinnedCommentsLoading: state.comments.all.loading,
    pinnedCommentsOffset: state.comments.all.pinnedComments.offset,
    pinnedCommentsTotalCount: state.comments.all.pinnedComments.totalCount,
    pinnedCommentsLimit: state.comments.all.pinnedComments.limit,
    pinnedCommentsPage: state.comments.all.pinnedComments.page,
    commentsLoading: state.comments.all.loading,
    commentsOffset: state.comments.all.comments.offset,
    commentsTotalCount: state.comments.all.comments.totalCount,
    commentsLimit: state.comments.all.comments.limit,
    commentsPage: state.comments.all.comments.page,
    error: state.errors.comments,
    users,
    commentTags,
    currentUser
  }
}

const connectedCommentsDashboard = connect(mapStateToProps,)(CommentsDashboard);
export default connectedCommentsDashboard

const initialState = {
  current: { 
    visit: null,
  },
  all: {
    visits: [],
    offset: 0,
    totalCount: 0
  },
  manualFetchedCoords: {}
}

export function visits(state = initialState, action) {
  const { payload } = action
  const { current, all } = state

  switch (action.type) {
    case 'FETCH_VISITS_REQUEST':
      return { ...state, all: { ...all, loading: true } };

    case 'FETCH_VISITS_SUCCESS':
      const { visits, count, offset } = payload
      return { ...state, all: { visits, totalCount: count, offset, loading: false } };

    case 'CLEAR_VISITS_OFFSET':
      return { ...state, all: { ...all, offset: 0, totalCount: 0 } };

    case 'FETCH_VISIT_SUCCESS':
      const { visit } = payload
      return { ...state, current: { visit } };

    case 'FETCH_POS_CORDS_SUCCESS':
      if (payload) {
        return { ...state, manualFetchedCoords: payload }
      } else {
        return state
      }

    default:
      return state;
  }
}



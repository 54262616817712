const initialState = {
  loadingAccounts: false,
  loadingCount: 0,
  tableReportData: {},
  chartReportData: {}
}

export function kpi(state = initialState, action) {
  const { loading, loadingCount, tableReportData, chartReportData } = state;

  switch (action.type) {
    case 'FETCH_USERS_REQUEST':
      return { ...state, loadingUsers: true };
    case 'FETCH_USERS_SUCCESS':
      return { ...state, loadingUsers: false, users: action.payload.users };
    case 'FETCH_USERS_FAILURE':
      return { ...state, loadingUsers: false, error: 'fetch users failure' };

    case 'FETCH_ACCOUNTS_REQUEST':
      return { ...state, loadingAccounts: true };
    case 'FETCH_ACCOUNTS_SUCCESS':
      const accounts = action.payload.accounts.map(acc => {
        return { ...acc.attributes }
      })
      return { ...state, loadingAccounts: false, accounts };
    case 'FETCH_ACCOUNTS_FAILURE':
      return { ...state, loadingAccounts: false, error: 'fetch accounts failure' };

    case 'FETCH_ACCOUNT_TYPES_REQUEST':
      return { ...state, loadingAccountTypes: true };
    case 'FETCH_ACCOUNT_TYPES_SUCCESS':
      return { ...state, loadingAccountTypes: false, accountTypes: action.payload.account_types };
    case 'FETCH_ACCOUNT_TYPES_FAILURE':
      return { ...state, loadingAccountTypes: false, error: 'fetch accountTypes failure' };

    case 'FETCH_CONTACT_TYPES_REQUEST':
      return { ...state, loadingContactTypes: true }
    case 'FETCH_CONTACT_TYPES_SUCCESS':
      return { ...state, loadingContactTypes: false, contactTypes: action.payload.contactTypes }
    case 'FETCH_CONTACT_TYPES_FAILURE':
      return { ...state, loadingContactTypes: false, error: 'fetch contactTypes failure' }

    case 'FETCH_TAGS_REQUEST':
      return { ...state, loadingTags: true };
    case 'FETCH_TAGS_SUCCESS':
      return { ...state, loadingTags: false, [action.tagsType]: action.payload.tags };
    case 'FETCH_TAGS_FAILURE':
      return { ...state, loadingTags: false, error: 'fetch Tags failure' };

    case 'FETCH_SKUS_REQUEST':
      return { ...state, loadingSkus: true };
    case 'FETCH_SKUS_SUCCESS':
      return { ...state, loadingSkus: false, skus: action.payload.skus };
    case 'FETCH_SKUS_FAILURE':
      return { ...state, loadingSkus: false, error: 'fetch users failure' };

    case 'FETCH_GLS_REQUEST':
      return { ...state, loadingGls: true };
    case 'FETCH_GLS_SUCCESS':
      return { ...state, loadingGls: false, gls: action.payload.gls };
    case 'FETCH_GLS_FAILURE':
      return { ...state, loadingGls: false, error: 'fetch gls failure' };

    case 'FETCH_WAREHOUSES_REQUEST':
      return { ...state, loadingWarehouses: true };
    case 'FETCH_WAREHOUSES_SUCCESS':
      return { ...state, loadingWarehouses: false, warehouses: action.payload.warehouses };
    case 'FETCH_WAREHOUSES_FAILURE':
      return { ...state, loadingWarehouses: false, error: 'fetch warehouses failure' };

    case 'FETCH_CHART_REPORT_DATA_REQUEST':
      return { ...state, loadingCount: loadingCount + 1, loading: true, requestId: action.requestId };
    case 'FETCH_CHART_REPORT_DATA_SUCCESS':
      if (action.requestId >= state.requestId) {
        const updatedChartReportData = { ...chartReportData, [action.dataType]: action.payload.data }
        return { ...state, loadingCount: loadingCount - 1, loading: (loadingCount - 1) > 0, chartReportData: updatedChartReportData };
      } else {
        return { ...state, loadingCount: loadingCount - 1, loading: (loadingCount - 1) > 0}
      }
    case 'FETCH_CHART_REPORT_DATA_FAILURE':
      return { ...state, loadingCount: loadingCount - 1, loading: (loadingCount - 1) > 0, error: 'error!' };

    case 'FETCH_TABLE_REPORT_DATA_REQUEST':
      return { ...state, loadingCount: loadingCount + 1, loading: true };
    case 'FETCH_TABLE_REPORT_DATA_SUCCESS':
      const updatedTableReportData = { ...tableReportData, [action.dataType]: action.payload }
      return { ...state, loadingCount: loadingCount - 1, loading: (loadingCount - 1) > 0, tableReportData: updatedTableReportData };
    case 'FETCH_REPORT_DATA_FAILURE':
      return { ...state, loadingCount: loadingCount - 1, loading: (loadingCount - 1) > 0, error: 'error!' };

    case 'SET_STATE':
      return { ...state, ...action.payload }

    case 'FETCH_USER_SUCCESS':
      return { ...state, user: action.payload }

    case 'FETCH_PROSPECT_TYPES_REQUEST':
      return { ...state, loadingProspectTypes: true };
    case 'FETCH_PROSPECT_TYPES_SUCCESS':
      return { ...state, loadingProspectTypes: false, prospectTypes: action.payload };
    case 'FETCH_PROSPECT_TYPES_FAILURE':
      return { ...state, loadingProspectTypes: false, error: 'fetch prospectTypes failure' };

    case 'FETCH_PROSPECT_SOURCES_REQUEST':
      return { ...state, loadingProspectSources: true };
    case 'FETCH_PROSPECT_SOURCES_SUCCESS':
      return { ...state, loadingProspectSources: false, prospectSources: action.payload };
    case 'FETCH_PROSPECT_SOURCES_FAILURE':
      return { ...state, loadingProspectSources: false, error: 'fetch prospectSources failure' };

    case 'PRELOAD_ACCOUNT':
      return { ...state, accounts: [action.payload]}

    default:
      return state;
  }
}


import CustomSelect from './base/CustomSelect.jsx'
import { fetchProspectTypes } from '../../redux/actions/prospectTypes.js'

const ProspectTypeSelect = (props) => {
  const allProps = { ...props, fetchOptionsAction: () => fetchProspectTypes(), selector: (state) => state["kpi"]["prospectTypes"]  }
  return (
    <CustomSelect { ...allProps } />
  )
}

export default ProspectTypeSelect
import React, { Component } from 'react'
import BooleanSelect from '../../../components/select/BooleanSelect.jsx'
import ContactTypeSelect from '../../../components/select/ContactTypeSelect.jsx'
import GooglePlaceSelect from '../../../components/select/GooglePlaceSelect.jsx'
import AddressComponent from './AddressComponent.jsx'
import { snakeCaseHash } from '../../../helpers/snakeCaseHash.js'
import { fromPairs } from 'lodash'
import isEqual from 'react-fast-compare'

class AccountContactsComponent extends Component {
  constructor(props) {
    super(props)

    const { account, contacts } = this.extractPristineState(props)
    const emptyContact          = this.generateEmptyContact()
    this.state = { account, contacts, emptyContact }
  }

  componentWillReceiveProps(nextProps) {
    if (!isEqual(nextProps, this.props)) {
      const { account, contacts } = this.extractPristineState(nextProps)
      this.setState({ account, contacts })
    }
  }

  getErrorMessage = (error, errorMsg='') => {
    Object.entries(error.responseJSON).forEach(([key, msgs]) => { errorMsg += key + ' ' + msgs.join(', ') })
    return errorMsg
  }

  generateEmptyContact = () => {
    const { contactProperties, accountContactProperties } = this

    const emptyContact        = fromPairs(Object.keys(contactProperties).map(key => [key, null]))
    const emptyAccountContact = fromPairs(Object.entries(accountContactProperties).map(keyValPair => {
      const [key, config] = keyValPair
      const { defaultValue } = config
      if (defaultValue) {
        return [key, defaultValue]
      } else {
        return [key, '']
      }
    }))

    return { ...emptyContact, accountContact: emptyAccountContact, editing: true, error: '' }
  }

  getOneLineFromAddress(address) {
    const { line1, county, city, state, country, zip } = address
    return [line1, county, city, state, country, zip].join(' ')
  }

  accountProperties = {
    'id' : { label: 'Account Id' }
  }

  contactProperties = {
    'id'              : { hidden: true },
    'first_name'      : { label: 'First Name' },
    'last_name'       : { label: 'Last Name' },
    'email'           : { label: 'Email' },
    'phone'           : { label: 'Phone' },
  }

  accountContactProperties = {
    'id'                      : { hidden: true },
    'send_email_statements'   : { label: 'Send Email Statements', component: BooleanSelect, defaultValue: false, getValLabel: rawVal => rawVal.toString() },
    'send_physical_statements': { label: 'Send Physical Statements', component: BooleanSelect, defaultValue: false, getValLabel: rawVal => rawVal.toString() },
    'primary'                 : { label: 'Primary Contact', component: BooleanSelect, defaultValue: false, getValLabel: rawVal => rawVal.toString()},
    'contact_type'            : { 
      label: 'Contact Type',
      component: ContactTypeSelect,
      defaultValue: { id: '', name: '' },
      toStateValue: (raw) => ({ name: raw.label, id: raw.value }),
      getValLabel: (stateValue) => stateValue['name'].toString(),
      getValValue: (stateValue) => stateValue['id']
     },
    'address'                 : {
      label: 'Address',
      nameKey: 'one_line_address',
      valKey:  'one_line_address',
      defaultValue: { one_line_address: '' },
      toStateValue: (address) => ({ one_line_address: this.getOneLineFromAddress(address), ...address }),
      getValLabel: (stateValue) => {
        return (
          <section>
            {stateValue.line1 && <AddressComponent address={stateValue} /> || ''}
          </section>
        )
      },
      getValValue: (stateValue) => stateValue['one_line_address'],
      component: (props) => {
        return (
          <section>
          <GooglePlaceSelect
            {...props}
            onSelect={(address)  => this.onChange(null, props.contactId, 'address', address)}
            onChange={(key, val) => this.onChange(null, props.contactId, 'address', { one_line_address: val })}
            onBlur={() => console.log('blur!')}
            lengthThreshold={3}
          />
          {props.rawVal.line1 && <AddressComponent address={props.rawVal} />}
          </section>
        )
      },
    }
  }

  extractPristineState = (props) => {
    const { accountProperties, contactProperties, accountContactProperties } = this
    const { account } = props
    const { contacts, account_contacts } = account

    const pristineAccount = { }
    Object.entries(account).forEach(keyValPair => {
      const [key, val] = keyValPair
      if (accountProperties[key]) {
        pristineAccount[key] = val
      }
    })

    const pristineContacts = []
    contacts.forEach(contact => {
      const pristineContact = { }
      const accountContact  = account_contacts.find(el => el.contact_id === contact.id)

      Object.entries(contact).forEach(keyValPair => {
        const [key, val] = keyValPair
        if (contactProperties[key]) {
          pristineContact[key] = val
        }
      })

      const pristineAccountContact = { }
      Object.entries(accountContact).forEach(keyValPair => {
        const [key, val] = keyValPair
        if (accountContactProperties[key]) {
          pristineAccountContact[key] = val === null ? accountContactProperties[key]['defaultValue'] : val
        }
      })

      pristineContact['accountContact'] = pristineAccountContact
      pristineContact['editing'] = false
      pristineContact['error']  = ''
      pristineContacts.push(pristineContact)
    })

    return { account: pristineAccount, contacts: pristineContacts.sort((contact1, contact2) => contact1.id < contact2.id) }
  }

  toggleEdit = (contactId) => {
    if (!contactId) return

    const pristineState = this.extractPristineState(this.props)
    const contacts = this.state.contacts.map(contact => {
      if (contact.id != contactId) return contact

      const { editing } = contact
      if (!editing) {
        return { ...contact, editing: !editing }
      } else {
        return { ...pristineState.contacts.find(el => el.id === contactId) }
      }
    })

    this.setState({ contacts })
  }

  onChange = (e, contactId, key, rawVal) => {
    const { contactProperties, accountContactProperties } = this
    const contactPropertiesConfig = contactProperties[key]
    const accountContactPropertiesConfig = accountContactProperties[key]

    const contacts = this.state.contacts.map(contact => {
      if (contact.id != contactId) return contact

      const { accountContact }  = contact
      if (contactPropertiesConfig) {
        return { ...contact, [key]: rawVal }
      } else if (accountContactPropertiesConfig) {
        const { toStateValue } = accountContactPropertiesConfig
        const stateVal = toStateValue ? toStateValue(rawVal) : rawVal.value
        return { ...contact, accountContact: { ...accountContact, [key]: stateVal } }
      }
    })

    this.setState({ contacts })
  }

  onAdd = () => {
    const { contacts, emptyContact } = this.state
    if (contacts.find(contact => contact.id === null)) {
      return // only one new contact at a time
    }
    this.setState({ contacts: [ ...contacts, { ...emptyContact } ] })
  }

  onRemove = (contactId) => {
    const { contacts } = this.state
    const { getErrorMessage } = this

    const contact = contacts.find(contact => contact.id === contactId)
    const accountContactId = contact.accountContact.id
    const callback = () => this.setState({ contacts: contacts.filter(contact => contact.id != contactId) })
    const url = Routes.account_contact_path(accountContactId)

    if (accountContactId) {
      $.ajax({
        url,
        type: 'DELETE',
        dataType: 'json'
      }).then(_response => {
        callback()
        this.props.refetchDataCallback()
      }).catch(error => {
        const updatedContact = { ...contact, error: getErrorMessage(error, 'Account ') }
        this.setState({ contacts: contacts.map(c => c.id === contactId ? updatedContact : c) })
      })
    } else {
      callback()
    }
  }

  onSubmit = (contactId) => {
    const { account, contacts } = this.state
    const { getErrorMessage }   = this

    const contact = contacts.find(el => el.id === contactId)
    const { id, editing, error, accountContact, ...payload } = contact
    const data = { contact: snakeCaseHash(payload) }

    let originalAddressId
    if (accountContact.id) {
      const originalAddress = this.props.account.account_contacts.find(el => el.id === accountContact.id).address
      if (originalAddress) {
        originalAddressId = originalAddress.id
      }
    }

    $.ajax({
      data,
      url: (contactId ? Routes.contact_path(contactId) : Routes.contacts_path()),
      type: (contactId ? 'PATCH' : 'POST'),
      dataType: 'json'
    }).then(response => {
      const updatedContact = { ...contact, ...response }
      this.setState({ contacts: contacts.map(c => c.id === contactId ? updatedContact : c) })
      const accountContactId = contact.accountContact.id
      const { id, contact_type, address, ...payload } = accountContact
      const { one_line_address, streetNumber, street, ...permittedAddressAttrs } = address
      const data = {
        account_contact: {
          ...payload,
          address_attributes: { ...permittedAddressAttrs, id: originalAddressId },
          account_id: account.id,
          contact_id: response.id,
          contact_type_id: contact_type.id
        }
      }

      $.ajax({
        data,
        type: (accountContactId ? 'PATCH' : 'POST'),
        url:  (accountContactId ? Routes.account_contact_path(accountContactId) : Routes.account_contacts_path()),
        dataType: 'json'
      }).then(() => {
        this.setState({ contacts: this.state.contacts.map(c => c.id === response.id ? { ...c, error: '', editing: false } : c) })
        this.props.refetchDataCallback()
      }).catch(err => {
        if (!contactId && response.id) {
          const url = Routes.contact_path(response.id)

          $.ajax({
            url,
            type: 'DELETE',
            dataType: 'json'
          })
        }
        const error = getErrorMessage(err)
        this.setState({ contacts: this.state.contacts.map(c => c.id === response.id ? { ...c, id: null, error } : c) })
      })

    }).catch(err => {
      const error = getErrorMessage(err)
      this.setState({ contacts: this.state.contacts.map(c => c.id === contactId ? { ...c, error } : c) })
    })
  }

  render() {
    const { contacts } = this.state
    const { contactProperties, accountContactProperties } = this

    const contactsTables = contacts.map(contact => {
      const { accountContact, editing, error } = contact

      return (
        <div key={contact.id}>
          {error && <small className="error">{error}</small>}
          <table width="100%">
            <tbody>
            {
              Object.entries(contactProperties).map((keyValPair, i) => {
                const [key, config] = keyValPair

                if (!config || config['hidden']) return null

                const value = contact[key] || ''
                return (
                  <tr key={key}>
                    <td>{config['label']}</td>
                    <td>
                      {
                        editing
                        ? <input
                            autoFocus={i===1}
                            value={value}
                            onChange={e => this.onChange(e, contact.id, key, e.target.value)}
                          />
                        : value
                      }
                    </td>
                  </tr>
                )
              })
            }
            {
              Object.entries(accountContactProperties).map(keyValPair => {
                const [key, config] = keyValPair
                if (!config || config['hidden']) return null

                const rawVal = accountContact[key]
                const { label, getValLabel, getValValue, component, changeHandler, blurHandler } = config

                return (
                  <tr key={key}>
                    <td style={{width: "35%"}}>{label}</td>
                    <td style={{width: "65%"}}>
                      {
                        editing
                        ? (
                          component({
                            contactId: contact.id,
                            name: key,
                            rawVal: rawVal,
                            value: (getValValue ? getValValue(rawVal) : rawVal),
                            onChange: (k, v) => this.onChange(null, contact.id, k, v),
                          })
                        )
                        : (
                          (getValLabel ? getValLabel(rawVal) : rawVal.toString())
                        )
                      }
                    </td>
                  </tr>
                )
              })
            }
            </tbody>
          </table>
          <div>
            <button disabled={!editing} className="tiny" onClick={e => this.onSubmit(contact.id)}>Save Changes</button>
            <button disabled={!editing} className="tiny right" onClick={e => this.onRemove(contact.id)}>Remove Contact</button>
          </div>
          <div className="right">
            <label htmlFor="editAttributes" className="tinyheader" style={{float: 'left'}}>Edit Attributes</label>
            <span onClick={e => this.toggleEdit(contact.id)} id="editAttributes" className="switch round small" style={{float: 'right'}}>
              <input type="radio" checked={editing} />
              <label></label>
            </span>
          </div>
        </div>
      )
    })

    return (
      <div>
        <div>
          <button className="tiny" onClick={e => this.onAdd()}>Add New Contact</button>
        </div>
        <div>
          {contactsTables}
        </div>
      </div>
    )
  }
}

export default AccountContactsComponent
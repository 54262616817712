import React, { Component } from 'react';
import createReactClass from 'create-react-class';
import Select from 'react-virtualized-select'
import axios from 'axios'

const AccountTagAccountItem = ({ account, associatedAccounts, getAccounts, onChange, onRemove }) => {
  const trashStyle = {
    color: '#f04124',
    fontSize: '20px'
  }

  const commonProps = {
    name: "tag[tagged][]",
  }

  let selectProps = {}
  if (account.edit) {
    selectProps = { ...commonProps, async: true, onChange: onChange, loadOptions: getAccounts, placeholder: "Select an item.." }
  } else {
    selectProps = { ...commonProps, value: account.id, options: associatedAccounts }
  }

  return (
    <div className='row'>
      <div className="row-collapse">
        <div className="medium-10 columns">
            <Select {...selectProps} />
        </div>
        <div className="medium-2 columns">
          <span className="postfix">
            <a href="#" style={trashStyle} onClick={onRemove}><i className="fi-trash"></i></a>
          </span>
        </div>
      </div>
    </div>
  )
}


export default class AccountTagForm extends Component {
  constructor(props) {
    super(props)

    const { name, associated_accounts } = this.props;

    this.state = { 
      name: name,
      associatedAccounts: associated_accounts.map((account) => {
        return { id: account.id, value: account.id, label: account.name, edit: false }
      }),
      newItem: { value: null, label: null, edit: true },
    }
  }

  getAccounts(term="") {
    return axios.get(`/accounts.json?quick=true&q=${term}`)
    .then((json) => {
      const accounts = json['data']['accounts'].map((account) => {
        return { value: account.id, label: account.name, edit: false }
      })

      return { options: accounts }
    })
  }

  onAddNew() {
    const { associatedAccounts, newItem } = this.state
    this.setState({ associatedAccounts: associatedAccounts.concat(newItem) })
  }

  onRemoveItem(index) {
    const newAssociatedAccounts = this.state.associatedAccounts.filter((item, i) => index != i)
    this.setState({ associatedAccounts: newAssociatedAccounts })
  }

  onSelectValueChange(index, newAccount) {
    const accounts = this.state.associatedAccounts.slice(0)
    accounts[index] = { id: newAccount.value, value: newAccount.value, label: newAccount.label, edit: false }
    this.setState({ associatedAccounts: accounts })
  }

  onTitleChange(e) {
    this.setState({ name: e.target.value })
  }

  render() {
    const { name, associatedAccounts } = this.state

    const accountItems = associatedAccounts.map((account, index) => {
      return (
        <div key={index}>
          <AccountTagAccountItem account={account} associatedAccounts={associatedAccounts} getAccounts={this.getAccounts} onChange={this.onSelectValueChange.bind(this, index)} onRemove={this.onRemoveItem.bind(this, index)} />
        </div>
      )
    })

    return (
      <div>
        { accountItems }

        <div className="row">
          <div className="small-12 columns">
            <button type="button" onClick = {this.onAddNew.bind(this)} className="button tiny">Add New Account</button>
            <hr />
          </div>
        </div>
      </div>
    )
  }
}

import React, { Component } from 'react'

class UploadAttachmentLink extends Component {
  constructor(props) {
    super(props)

  }

  render() {
    const { text, style, onChange } = this.props

    return (
      <span className='UploadAttachmentLink'>
        <input type='file' style={{ 'display' : 'none' }} multiple />
        <a className='upload' style={style} onChange={onChange}> {text}</a>
      </span>
    )
  }
}

export default UploadAttachmentLink
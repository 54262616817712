import React from 'react'
import { withFormik, Form } from 'formik'
import Yup from 'yup'
import DeliveryTypeSelect from '../../../components/select/DeliveryTypeSelect.jsx'
import BooleanSelect from "../../../components/select/BooleanSelect"

const FreightShipmentFormComponent = ({ values, onChange, errors, touched, isSubmitting, setFieldValue, setFieldTouched }) => {
  const handleChange = (name, value, callback) => {
    setFieldValue(name, value)

    if (onChange) {
      onChange(name, value, callback)
    }
  }

  const {
    carrierReferenceNumber,
    estimatedCost,
    estimatedVolumeBarrels,
    estimatedCaseEquivalents,
    estimatedWeightPounds,
    estimatedCubics,
    estimatedPallets,
    deliveryTypeId,
    deliveryrouteId,
    shippedDate,
    shippedTime,
    signedBolReceived
  } = values

  const getHeader = () => {
    const { page } = values

    let header

    switch (page) {
      case "new":
        header = 'New Freight Shipment'
        break;
      case "edit":
        header = 'Submit Freight Shipment'
        break;
    }

    return header
  }

  const getSubmitButtonText = () => {
    const { page } = values

    let buttonText

    switch (page) {
      case "new":
        buttonText = 'Submit New Freight Shipment'
        break;
      case "edit":
        buttonText = 'Submit Edited Freight Shipment'
        break;
    }

    return buttonText
  }

  return (
    <Form>
      <div>
        <div className='row'>
          <div className='small-12 columns'>
            <h3 className='subheader'>{getHeader()}</h3>
          </div>
        </div>
          <section>
            <div className='row'>
              <div className='small-12 columns'>
                <label>Delivery Type
                  <DeliveryTypeSelect
                    name='deliveryTypeId'
                    multi={false}
                    value={deliveryTypeId}
                    onChange={(name, val) => handleChange(name, val.value)}
                    onBlur={setFieldTouched}
                    error={errors.deliveryTypeId}
                    touched={touched.deliveryTypeId}
                  />
                  {errors.deliveryTypeId && touched.deliveryTypeId && <small className='error'>{errors.deliveryTypeId}</small>}
                </label>
              </div>
            </div>

            <br/>
            <div className='row'>
              <div className='small-6 columns'>
                <label>Carrier Reference Number
                  <input
                    type='text'
                    value={carrierReferenceNumber}
                    onChange={e => handleChange('carrierReferenceNumber', e.target.value)}
                  />
                </label>
              </div>
              <div className='small-6 columns'>
                <label>Deliveryroute Id
                  <input
                    type='text'
                    value={deliveryrouteId}
                    onChange={e => handleChange('deliveryrouteId', e.target.value)}
                  />
                </label>
              </div>
            </div>

            <br/>
            <div className='row'>
              <div className='small-6 columns'>
                <label>Shipped Date</label>
                <input
                  type='date'
                  name='shippedDate'
                  value={shippedDate}
                  onChange={e => handleChange('shippedDate', e.target.value)}
                />
              </div>
                <div className='small-6 columns'>
                  <label>Shipped Time</label>
                  <input
                    name='shippedTime'
                    type={'time'}
                    className={'disabledTimeInput'}
                    value={shippedTime}
                    onChange={e => handleChange('shippedTime', e.target.value)}
                  />
                </div>
            </div>

            <br/>
            <div className='row'>
              <div className='small-6 columns'>
                <label>Signed Bol Received
                  <BooleanSelect
                    name='signedBolReceived'
                    value={signedBolReceived}
                    onChange={(name, val) => handleChange(name, val.value)}
                  />
                </label>
              </div>
              <div className='small-6 columns'>
                <label>Estimated Shipment Cost
                  <input
                    type='text'
                    value={estimatedCost}
                    onChange={e => handleChange('estimatedCost', e.target.value)}
                  />
                </label>
              </div>
            </div>

            <br/>
            <div className='row'>
              <div className='small-6 columns'>
                <label>Estimated Volume Barrels (max 4 decimals)
                  <input
                    type='text'
                    value={estimatedVolumeBarrels}
                    onChange={e => handleChange('estimatedVolumeBarrels', e.target.value)}
                  />
                </label>
              </div>
              <div className='small-6 columns'>
                <label>Estimated Case Equivalents (max 2 decimals)
                  <input
                    type='text'
                    value={estimatedCaseEquivalents}
                    onChange={e => handleChange('estimatedCaseEquivalents', e.target.value)}
                  />
                </label>
              </div>
            </div>

            <br/>
            <div className='row'>
              <div className='small-6 columns'>
                <label>Estimated Weight (in Pounds max 2 decimals)
                  <input
                    type='text'
                    value={estimatedWeightPounds}
                    onChange={e => handleChange('estimatedWeightPounds', e.target.value)}
                  />
                </label>
              </div>
              <div className='small-6 columns'>
                <label>Estimated Pallets (max 2 decimals)
                  <input
                    type='text'
                    value={estimatedPallets}
                    onChange={e => handleChange('estimatedPallets', e.target.value)}
                  />
                </label>
              </div>
            </div>

            <div className='row'>
              <div className='small-12 columns'>
                <button className='button right' disabled={isSubmitting}>{getSubmitButtonText()}</button>
              </div>
            </div>
          </section>
      </div>
    </Form>
  )
}

export default withFormik({
  enableReinitialize: true,
  mapPropsToValues(props) {
    return {
      page: props.page,
      currentUser: props.currentUser,
      carrierReferenceNumber: props.carrierReferenceNumber,
      estimatedCost: props.estimatedCost,
      estimatedVolumeBarrels: props.estimatedVolumeBarrels,
      estimatedCaseEquivalents: props.estimatedCaseEquivalents,
      estimatedWeightPounds: props.estimatedWeightPounds,
      estimatedCubics: props.estimatedCubics,
      estimatedPallets: props.estimatedPallets,
      defaultDeliveryType: props.defaultDeliveryType,
      deliveryTypeId: props.deliveryTypeId,
      deliveryrouteId: props.deliveryrouteId,
      shippedDate: props.shippedDate,
      shippedTime: props.shippedTime,
      signedBolReceived: props.signedBolReceived,
    }
  },

  validationSchema: Yup.object().shape({
  }),
  handleSubmit(values, { setSubmitting, props }) {
    props.onSubmit(values)
    setTimeout(() => setSubmitting(false), 500)
  }
})(FreightShipmentFormComponent)

const initialState = {
  current: { 
    order: null,
  },
  all: {
    loading: false,
    orders: [],
    offset: 0,
    totalCount: 0
  },
  csv: {
    loading: false,
    orders: []
  }
}

export function orders(state = initialState, action) {
  const { payload } = action
  const { all, csv } = state

  switch (action.type) {
    case 'CLEAR_ORDERS':
      return { ...state, all: { ...all, orders: [], offset: 0, totalCount: 0} };

    case 'CREATE_ORDER_REQUEST':
      return { ...state, all: { ...all, loading: true } };

    case 'UPDATE_ORDER_REQUEST':
      return { ...state, all: { ...all, loading: true } };

    case 'FETCH_ORDERS_FULL_REQUEST':
      return { ...state, all: { ...all, loading: true } };

    case 'FETCH_ORDERS_FULL_SUCCESS':
      const { orders, count, offset } = payload
      return { ...state, all: { orders: orders.map(order => ({ ...order.attributes })), totalCount: count, offset, loading: false } };

    case 'CLEAR_ORDERS_FULL_OFFSET':
      return { ...state, all: { ...all, offset: 0, totalCount: 0 } };

    case 'FETCH_ORDER_SUCCESS':
      return { ...state, current: { order: payload } }

    case 'FETCH_ORDERS_FOR_CSV_DOWNLOAD_REQUEST':
      return { ...state, csv: { ...csv, loading: true } };

    case 'FETCH_ORDERS_FOR_CSV_DOWNLOAD_SUCCESS':
      return { ...state, csv: { ...csv, orders: payload.orders.map(order => ({ ...order.attributes })), loading: false } };

    default:
      return state;
  }
}
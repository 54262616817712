import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from "moment"
import ServerErrors from "../../components/form/ServerErrors"
import FreightShipmentFormComponent from "./components/FreightShipmentComponent"
import { snakeCaseHash } from "../../helpers/snakeCaseHash"
import { fetchFreightShipment, updateFreightShipment } from "../../redux/actions/freightShipments"
import { isCallback } from "../../helpers/isCallback"
import { fetchDefaultDeliveryType, fetchDeliveryTypes } from "../../redux/actions/deliveryTypes"
import { fetchCurrentUser } from "../../redux/actions/currentUser"
import { base, short_time } from "../../timeFormats"


class FreightShipmentsEditView extends Component {
  constructor(props) {
    super(props)
    this.state = {}
  }

  componentDidMount() {
    const { dispatch, match } = this.props

    dispatch(fetchFreightShipment(match.params.id))
    dispatch(fetchCurrentUser())
    dispatch(fetchDefaultDeliveryType())
    dispatch(fetchDeliveryTypes())
  }

  onChange = (name, value, callback) => {
    this.setState({ [name]: value }, () => {
      if (callback && isCallback(callback)) {
        callback()
      }
    })
  }

  onSubmit = (values) => {
    const { dispatch, defaultDeliveryType, currentUser, freightShipment } = this.props

    const {
      id,
      carrier_reference_number,
      estimated_cost,
      estimated_volume_barrels,
      estimated_case_equivalents,
      estimated_weight_pounds,
      estimated_cubics,
      estimated_pallets,
      deliveryroute_id,
      delivery_type_id,
      signed_bol_received,
      shipped_at
    } = freightShipment

    const {
      carrierReferenceNumber,
      estimatedCost,
      estimatedVolumeBarrels,
      estimatedCaseEquivalents,
      estimatedWeightPounds,
      estimatedCubics,
      estimatedPallets,
      deliveryrouteId,
      deliveryTypeId,
      shippedDate,
      shippedTime,
      signedBolReceived
    } = this.state

    const getUpdatedProperty = (property, updatedProperty) => {
      if (updatedProperty === undefined) {
        return property
      } else {
        return updatedProperty
      }
    }

    const getUpdatedShippedAt = (shipped_at, shippedDate, shippedTime) => {
      const format = base + ' ' + short_time

      if ((shippedDate === undefined) && (shippedTime === undefined)) {
         return moment(shipped_at).format(format)
      }

      if ((shippedDate !== undefined) && (shippedTime !== undefined)) {
        return moment(shippedDate + ' ' + shippedTime).format(format)
      }

      if ((shippedDate !== undefined) && (shippedTime === undefined)) {
        return moment(shippedDate + ' ' + moment(shipped_at).format(short_time)).format(format)
      }

      if ((shippedDate === undefined) && (shippedTime !== undefined)) {
        return moment(moment(shipped_at).format(base) + ' ' + shippedTime).format(format)
      }
    }

    const freight_shipment = snakeCaseHash({
      carrierReferenceNumber: getUpdatedProperty(carrier_reference_number,  carrierReferenceNumber),
      estimatedCost: getUpdatedProperty(estimated_cost,  estimatedCost),
      deliveryrouteId: getUpdatedProperty(deliveryroute_id, deliveryrouteId),
      estimatedVolumeBarrels: getUpdatedProperty(estimated_volume_barrels, estimatedVolumeBarrels),
      estimatedCaseEquivalents: getUpdatedProperty(estimated_case_equivalents, estimatedCaseEquivalents),
      estimatedWeightPounds: getUpdatedProperty(estimated_weight_pounds, estimatedWeightPounds),
      estimatedCubics: getUpdatedProperty(estimated_cubics, estimatedCubics),
      estimatedPallets: getUpdatedProperty(estimated_pallets, estimatedPallets),
      signedBolReceived: getUpdatedProperty(signedBolReceived, signed_bol_received),
      deliveryTypeId: getUpdatedProperty(deliveryTypeId, delivery_type_id),
      shippedAt: getUpdatedShippedAt(shipped_at, shippedDate, shippedTime)
    })

    dispatch(updateFreightShipment(id, { freight_shipment }))
  }

  render() {
    const { serverErrors, currentUser, dispatch, defaultDeliveryType, defaultDeliveryTypes, freightShipment } = this.props

    if (!defaultDeliveryTypes || !defaultDeliveryType || !freightShipment) return null

    const {
      carrier_reference_number,
      estimated_cost,
      estimated_volume_barrels,
      estimated_case_equivalents,
      estimated_weight_pounds,
      estimated_cubics,
      estimated_pallets,
      deliveryroute_id,
      delivery_type_id,
      shipped_at,
      signed_bol_received
    } = freightShipment

    const shippedDate = moment(shipped_at).format(base)
    const shippedTime = moment(shipped_at).format(short_time)

    return (
      <div>
        <ServerErrors serverErrors={serverErrors} />
        <FreightShipmentFormComponent
          page={'new'}
          dispatch={dispatch}
          currentUser={currentUser}
          carrierReferenceNumber={carrier_reference_number}
          estimatedCost={estimated_cost}
          estimatedVolumeBarrels={estimated_volume_barrels}
          estimatedCaseEquivalents={estimated_case_equivalents}
          estimatedWeightPounds={estimated_weight_pounds}
          estimatedCubics={estimated_cubics}
          estimatedPallets={estimated_pallets}
          deliveryrouteId={deliveryroute_id}
          signedBolReceived={signed_bol_received}
          shippedDate={shippedDate}
          shippedTime={shippedTime}
          deliveryTypeId={delivery_type_id || defaultDeliveryType.id}
          onChange={this.onChange}
          onSubmit={this.onSubmit}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    freightShipment: state.freightShipments.current.freight_shipment,
    currentUser: state.currentUser.user,
    defaultDeliveryType: state.deliveryTypes.default,
    defaultDeliveryTypes: state.deliveryTypes.all,
    serverErrors: state.errors.orders.create || {}
  }
}

const connectedFreightShipmentsEditView = connect(mapStateToProps,)(FreightShipmentsEditView)
export default connectedFreightShipmentsEditView

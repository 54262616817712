import CustomSelect from './base/CustomSelect.jsx'
import { fetchAccountGroups } from '../../redux/actions/accountGroups.js'

const AccountGroupSelect = (props) => {
  const selector = (state) => {
    return state["accountGroups"]["all"]
  }
  const allProps = { ...props, fetchOptionsAction: () => fetchAccountGroups(), selector: selector  }
  return (
    <CustomSelect { ...allProps } />
  )
}

export default AccountGroupSelect

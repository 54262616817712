import $ from 'jquery'
import Routes from '../../routes.js.erb'

export function fetchUsersRequest() {
  return {
    type: 'FETCH_USERS_REQUEST',
  }
}

export function fetchUsersSuccess(response) {
  return {
    type: 'FETCH_USERS_SUCCESS',
    payload: response,
  }
}

export function fetchUsersFailure(response) {
  return {
    type: 'FETCH_USERS_FAILURE',
    error: response.error,
  }
}

export function fetchUsers(_startDate, _endDate) {
  const url = Routes.users_path({format: 'json'})

  return (dispatch) => {
    dispatch(fetchUsersRequest());

    $.getJSON(url)
      .then(json => { dispatch(fetchUsersSuccess(json)) })
      .catch(response => dispatch(fetchUsersFailure(response)));
  }
}

export function getCurrentUser() {
  return {
    type: 'FETCH_USER_SUCCESS',
    payload: { "id":165,"email":"afshinbayegan@fortpointbeer.com","phone":"408.981.8697","first_name":"Afshin","last_name":"Bayegan","name":"Afshin Bayegan","avatar_url":"/assets/user_photos/165.jpg","is_active":true,"salesteam":true }
  }
}

function fetchCurrentUserSuccess(payload) {
  return {
    type: 'FETCH_CURRENT_USER_SUCCESS',
    payload
  }
}
export function fetchCurrentUser() {
  const url = Routes.current_user_path({format: 'json'})
  return (dispatch) => {

    $.getJSON(url)
      .then(payload => { dispatch(fetchCurrentUserSuccess(payload)) })
      .catch(response => console.log(response));
  }
}

function fetchSalespersonSuccess(payload) {
  return {
    type: 'FETCH_SALESPERSON_SUCCESS',
    payload
  }
}

export function fetchSalesperson(id) {
  const url = Route.user_path(id, {format: 'json'})

  return (dispatch) => {
    $.getJSON(url)
      .then(response => {
        const { user } = response
        dispatch(fetchSalespersonSuccess(user))
      })
  }
}

import React, { Component } from 'react';
import createReactClass from 'create-react-class';

var SkuForm = createReactClass({
  getInitialState: function () {
    return {
      sku: this.props.sku
    }
  },


  render: function () {
    return (
      <div>
        <div className="row">
          <div className="small-12 columns">
            <h3 className="subheader">{this.props.formType}</h3>
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns">
            <a className="button tiny right" href="/skus">Back To All SKUs</a>
          </div>
        </div>

        <div className="row">
          <div className="small-12 columns">
            <label>Name
              <input type="text" name="sku[name]" defaultValue={this.props.sku.name} />
            </label>
          </div>
        </div>

        <div className="row">
          <div className="small-12 columns">
            <label>SAP ID
              <input type="text" name="sku[sap_id]" defaultValue={this.props.sku.sap_id} />
            </label>
          </div>
        </div>

        <div className="row">
          <div className="small-12 columns">
            <label>Netsuite ID
              <input type="text" name="sku[netsuite_id]" defaultValue={this.props.sku.netsuite_id} />
            </label>
          </div>
        </div>

        <div className="row">
          <div className="small-12 columns">
            <label>Netsuite Item Type
              <select name="sku[netsuite_item_type]" defaultValue={this.props.sku.netsuite_item_type}>
                <option value={null}>Types...</option>
                {this.props.netsuite_types.map(function (ns_type) {
                  return (
                    <option value={ns_type}>{ns_type}</option>
                    );
                })}
              </select>
            </label>
          </div>
        </div>




        <div className="row">
          <div className="small-12 columns">
            <label>GL (GL Location To Book To For Sales)
              <select name="sku[gl_id]" defaultValue={this.props.sku.gl_id}>
                <option value={null}>GLs...</option>
                {this.props.gls.map(function (gl) {
                  return (
                    <option key={gl.id} value={gl.id}>{gl.number + " - " + gl.name}</option>
                    );
                })}
              </select>
            </label>
          </div>
        </div>

        <div className="row">
          <div className="small-12 columns">
            <label>Dependent SKU (Used for automatically adding to order)
              <select name="sku[dependent_sku_id]" defaultValue={this.props.sku.dependent_sku_id}>
                <option value={null}>SKUs...</option>
                {this.props.skus.map(function (sku) {
                  return (
                    <option key={sku.id} value={sku.id}>{sku.name}</option>
                    );
                })}
              </select>
            </label>
          </div>
        </div>


        <div className="row">
          <div className="small-12 columns">
            <label>Fort Point (Internal) SKU Number
              <input type="text" name="sku[sku_number]" defaultValue={this.props.sku.sku_number} />
            </label>
          </div>
        </div>

        <div className="row">
          <div className="small-12 columns">
            <label>Counting Unit (Pounds, Keg, Case, Bag...)
              <input type="text" name="sku[unit]" defaultValue={this.props.sku.unit} />
            </label>
          </div>
        </div>


        <div className="row">
          <div className="small-12 columns">
            <label>Universal Product Code
              <input type="text" name="sku[upc]" defaultValue={this.props.sku.upc} />
            </label>
          </div>
        </div>

        <div className="row">
          <div className="small-12 columns">
            <label>Manufacturer
              <input type="text" name="sku[manufacturer]" defaultValue={this.props.sku.manufacturer} />
            </label>
          </div>
        </div>


        <div className="row">
          <div className="small-12 columns">
            <label>MSRP
              <input type="text" name="sku[msrp]" defaultValue={this.props.sku.msrp} />
            </label>
          </div>
        </div>

        <div className="row">
          <div className="small-12 columns">
            <label>Vendor
              <input type="text" name="sku[vendor]" defaultValue={this.props.sku.vendor} />
            </label>
          </div>
        </div>

        <div className="row">
          <div className="small-12 columns">
            <label>Vendor SKU / Model / Make Number
              <input type="text" name="sku[vendor_sku_number]" defaultValue={this.props.sku.vendor_sku_number} />
            </label>
          </div>
        </div>


        <div className="row">
          <div className="small-12 columns">
            <label>Inventoried? (Setting To No Will auto replenish stock)
              <select name="sku[inventoried]" defaultValue={this.props.sku.inventoried} >
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </select>
            </label>
          </div>
        </div>

        <div className="row">
          <div className="small-12 columns">
            <label>Volume (Gallons)
              <input type="text" name="sku[gallons]" defaultValue={this.props.sku.gallons} />
            </label>
          </div>
        </div>

        <div className="row">
          <div className="small-12 columns">
            <label>Weight (Pounds)
              <input type="text" name="sku[pounds]" defaultValue={this.props.sku.pounds} />
            </label>
          </div>
        </div>

        <div className="row">
          <div className="small-12 columns">
            <label>Length (Inches)
              <input type="text" name="sku[length_in]" defaultValue={this.props.sku.length_in} />
            </label>
          </div>
        </div>

        <div className="row">
          <div className="small-12 columns">
            <label>Width (Inches)
              <input type="text" name="sku[width_in]" defaultValue={this.props.sku.width_in} />
            </label>
          </div>
        </div>

        <div className="row">
          <div className="small-12 columns">
            <label>Height (Inches)
              <input type="text" name="sku[height_in]" defaultValue={this.props.sku.height_in} />
            </label>
          </div>
        </div>

        <div className="row">
          <div className="small-12 columns">
            <label>Number of Units Per Pallet
              <input type="text" name="sku[number_per_pallet]" defaultValue={this.props.sku.number_per_pallet} />
            </label>
          </div>
        </div>

        <div className="row">
          <div className="small-12 columns">
            <label>Shelf Life (Days)
              <input type="text" name="sku[shelf_days]" defaultValue={this.props.sku.shelf_days} />
            </label>
          </div>
        </div>

        <div className="row">
          <div className="small-12 columns">
            <label>Short Description
              <input type="text" name="sku[short_description]" defaultValue={this.props.sku.short_description} />
            </label>
          </div>
        </div>

        <div className="row">
          <div className="small-12 columns">
            <label>Long Description
              <input type="text" name="sku[long_description]" defaultValue={this.props.sku.long_description} />
            </label>
          </div>
        </div>

        <div className="row">
          <div className="small-12 columns">
            <label>Print Sticker?
              <select name="sku[print_sticker]" defaultValue={this.props.sku.print_sticker} >
                <option value={true}>Yes</option>
                <option value={false}>No</option>
              </select>
            </label>
          </div>
        </div>

        <div className="row">
          <div className="small-12 columns">
            <label>Publicly Viewable
              <select name="sku[public_viewable]" defaultValue={this.props.sku.public_viewable} >
                <option value={false}>No</option>
                <option value={true}>Yes</option>
              </select>
            </label>
          </div>
        </div>
      </div>
    )
  }
});

export default SkuForm;

const initialState = {
  all: [],
  default: null
}

export function deliveryTypes(state=initialState, action) {
  const { payload } = action

  switch (action.type) {
    case 'FETCH_DELIVERY_TYPES_SUCCESS':
      return { ...state, all: payload }
    case 'FETCH_DEFAULT_DELIVERY_TYPE_SUCCESS':
      return { ...state, default: payload[0] }

    default:
      return state
  }
}

const initialState = {
  accounts: {
    create: null
  },
  orders: {
    create: null
  }
}

export function errors(state = initialState, action) {
  const { payload } = action

  switch (action.type) {
    case 'CLEAR_ERRORS':
      return { ...initialState };

    case 'CREATE_ACCOUNT_ERROR':
      const accountsErrors = { ...state.accounts, create: payload }
      return { ...state, accounts: accountsErrors };

    case 'CREATE_COMMENT_ERROR':
      return { ...state, comments: payload }

    case 'CREATE_ORDER_ERROR':
      const createErdersErrors = { ...state.orders, create: payload }
      return { ...state, orders: createErdersErrors }

    case 'UPDATE_ORDER_ERROR':
      const updateOrdersErrors = { ...state.orders, create: payload }
      return { ...state, orders: updateOrdersErrors }

    default:
      return state;
  }
}



import $ from 'jquery'
import Routes from '../../routes.js.erb'

function fetchProspectSourcesRequest() {
  return {
    type: 'FETCH_PROSPECT_SOURCES_REQUEST',
  }
}

function fetchProspectSourcesSuccess(response) {
  return {
    type: 'FETCH_PROSPECT_SOURCES_SUCCESS',
    payload: response,
  }
}

function fetchProspectSourcesFailure(response) {
  return {
    type: 'FETCH_PROSPECT_SOURCES_FAILURE',
    error: response.error,
  }
}

export function fetchProspectSources() {
  const url = Routes.prospect_sources_path({format: 'json'})

  return (dispatch) => {
    dispatch(fetchProspectSourcesRequest());

    $.getJSON(url)
      .then(json => { dispatch(fetchProspectSourcesSuccess(json)) })
      .catch(response => dispatch(fetchProspectSourcesFailure(response)));
  }
}

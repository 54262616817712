import $ from 'jquery'
import Routes from '../../routes.js.erb'
import { snakeCaseHash } from "../../helpers/snakeCaseHash"

function fetchServiceExceptionsRequest() {
  return {
    type: 'FETCH_SERVICE_EXCEPTIONS_REQUEST'
  }
}

function fetchServiceExceptionsSuccess(payload) {
  return {
    type: 'FETCH_SERVICE_EXCEPTIONS_SUCCESS',
    payload
  }
}

export function fetchServiceExceptions(params = {}) {
  const url = Routes.api_v3_service_exceptions_path({...snakeCaseHash(params), format: 'json'})

  return (dispatch) => {
    dispatch(fetchServiceExceptionsRequest())

    $.getJSON(url)
      .then(response => {
        dispatch(fetchServiceExceptionsSuccess({ ...response}))
      })
  }
}

function fetchServiceExceptionRequest() {
  return {
    type: 'FETCH_SERVICE_EXCEPTION_REQUEST'
  }
}

function fetchServiceExceptionSuccess(payload) {
  return {
    type: 'FETCH_SERVICE_EXCEPTION_SUCCESS',
    payload
  }
}

export function fetchServiceException(id) {
  const url = Routes.api_v3_service_exception_path(id, {format: 'json'})

  return (dispatch) => {
    dispatch(fetchServiceExceptionRequest())

    $.getJSON(url)
      .then(response => {
        dispatch(fetchServiceExceptionSuccess({ ...response}))
      })
  }
}

function createServiceExceptionSuccess(payload) {
  return {
    type: 'CREATE_SERVICE_EXCEPTIONS_CATEGORY_SUCCESS',
    payload
  }
}

function createServiceExceptionError(payload) {
  return {
    type: 'CREATE_SERVICE_EXCEPTIONS_CATEGORY_ERROR',
    payload
  }
}

export function createServiceException(params) {
  const url = Routes.api_v3_service_exceptions_path({params})
  const data = { service_exception: snakeCaseHash(params) }

  return (dispatch) => {
    $.ajax({
      url,
      data,
      type: 'POST',
      dataType: 'json'
    }).then(payload => {
      dispatch(createServiceExceptionSuccess(payload))
    }).catch(error => {
      dispatch(createServiceExceptionError(error.responseJSON))
    })
  }
}

import React from 'react'
import { withFormik, Form } from 'formik'
import Yup from 'yup'

import AccountSelect from '../../../components/select/AccountSelect.jsx'
import DeliveryTypeSelect from '../../../components/select/DeliveryTypeSelect.jsx'
import AccountRecievableSummaryComponent from './AccountRecievableSummaryComponent.jsx'
import RequestedDeliveryTimeWindowsWrapper from './RequestedDeliveryTimeWindowsWrapper.jsx'
import SuggestedDeliveryTimeWindowsWrapper from './SuggestedDeliveryTimeWindowsWrapper.jsx'
import RequestedOrderItemsComponent from './RequestedOrderItemsComponent.jsx'
import { hasRole } from "../../../helpers/hasRole.js"

const OrderFormComponent = ({ values, onChange, errors, touched, isSubmitting, setFieldValue, setFieldTouched, dispatch, fetchAccount, fetchSkuInfo }) => {
  const handleChange = (name, value, callback) => {
    setFieldValue(name, value)

    if (onChange) {
      onChange(name, value, callback)
    }
  }

  const { accountId, account, deliveryTypeId, timeWindows, suggestedTimeWindows, orderItems, memo, purchaseOrderNumber } = values
  const { currentUser, editMode, skus } = values
  const canEdit = hasRole(currentUser.roles, 'ar')
  const selector = (editMode, account) => {
    if (editMode) {
      return (_state) => [account]
    } else {
      return (state) => state["kpi"]["accounts"]
    }
  }

  const getHeader = () => {
    const { page } = values

    let header

    switch (page) {
      case "new":
        header = 'New Order'
        break;
      case "edit":
        header = 'Submit Edit Order'
        break;
      case "approve":
        header = 'Approve Order'
        break;
      case "unapprove":
        header = 'Unapprove Order'
        break;
      case "book":
        header = 'Book Order'
        break;
      case "complete":
        header = 'Complete Order'
        break;
      case "uncomplete":
        header = 'Uncomplete Order'
        break;
      case "ship":
        header = 'Ship Order'
        break;
      case "unship":
        header = 'Unship Order'
        break;
    }

    return header
  }

  const getSubmitButtonText = () => {
    const { page } = values

    let buttonText

    switch (page) {
      case "new":
        buttonText = 'Submit New Order'
        break;
      case "edit":
        buttonText = 'Submit Edited Order'
        break;
      case "approve":
        buttonText = 'Approve Order'
        break;
      case "unapprove":
        buttonText = 'Unapprove Order'
        break;
      case "book":
        buttonText = 'Book Order'
        break;
      case "complete":
        buttonText = 'Complete Order'
        break;
      case "uncomplete":
        buttonText = 'Uncomplete Order'
        break;
      case "ship":
        buttonText = 'Ship Order'
        break;
      case "unship":
        buttonText = 'Unship Order'
        break;
    }

    return buttonText
  }

  return (
    <Form>
      <div>
        <div className='row'>
          <div className='small-12 columns'>
            <h3 className='subheader'>{getHeader()}</h3>
          </div>
        </div>
        <div className='row'>
          <div className='small-12 columns'>
            <label>Order creation is now managed in Netsuite, email orders@fortpointbeer.com to place an order.</label>
          </div>
        </div>
        <br/>
        {account.id ?
          <section>
          <div className='row'>
            <div className='small-12 columns'>
              <label>Delivery Type
                <DeliveryTypeSelect
                  name='deliveryTypeId'
                  multi={false}
                  value={deliveryTypeId}
                  onChange={(name, val) => handleChange(name, val.value)}
                  onBlur={setFieldTouched}
                  error={errors.deliveryTypeId}
                  touched={touched.deliveryTypeId}
                />
                {errors.deliveryTypeId && touched.deliveryTypeId && <small className='error'>{errors.deliveryTypeId}</small>}
              </label>
            </div>
          </div>
          <div className='row'>
            <div className='small-6 columns'>
              <label>Account Sales Person
                <input
                  readOnly
                  type='text'
                  value={account && account.salesperson && account.salesperson.name}
                />
              </label>
            </div>
            <div className='small-6 columns'>
              <label>Order Entered By
                <input readOnly type='text' value={currentUser && currentUser.name} />
              </label>
            </div>
          </div>
          <div className='row'>
            <div className='small-6 columns'>
              <label>Purchase Order Number
                <input
                  type='text'
                  value={purchaseOrderNumber}
                  onChange={e => handleChange('purchaseOrderNumber', e.target.value)}
                />
              </label>
            </div>
          </div>
          <div className='row'>
            <div className='small-12 columns'>
              <AccountRecievableSummaryComponent arDate='2018-12-12' arBreakDown={JSON.parse(account.ar_summary)} />
            </div>
          </div>
          <div className='row'>
            <div className='small-12 columns'>
              {suggestedTimeWindows.length > 0 ?
                <SuggestedDeliveryTimeWindowsWrapper
                  name='suggestedTimeWindows'
                  suggestedTimeWindows={suggestedTimeWindows}
                />
              : <h4 className='subheader'>This account has no time window set, please visit the account dashboard to add some time windows!</h4>}
            </div>
          </div>
          <div className='row'>
            <div className='small-12 columns'>
              {errors.timeWindows && touched.timeWindows && <small className='error'>{errors.timeWindows}</small>}
              <RequestedDeliveryTimeWindowsWrapper
                name='timeWindows'
                timeWindows={timeWindows}
                handleChange={handleChange}
                onBlur={setFieldTouched}
                error={errors.timeWindows}
                touched={touched.timeWindows}
              />
            </div>
          </div>
          <div className='row'>
            <div className='small-12 columns'>
              {errors.orderItems && touched.orderItems && <small className='error'>{errors.orderItems}</small>}
              <RequestedOrderItemsComponent
                name='orderItems'
                skus={skus}
                canEdit={canEdit}
                accountId={accountId}
                orderItems={orderItems}
                handleChange={handleChange}
                onBlur={setFieldTouched}
                error={errors.orderItems}
                touched={touched.orderItems}
                fetchSkuInfo={fetchSkuInfo}
              />
            </div>
          </div>
          <div className='row'>
            <div className='small-12 columns'>
              <label>Memo
                <textarea name='memo' rows='5' defaultValue={memo} onChange={e => handleChange('memo', e.target.value)} />
              </label>
            </div>
          </div>
          <div className='row'>
            <div className='small-12 columns'>
              <button className='button right' disabled={isSubmitting}>{getSubmitButtonText()}</button>
            </div>
          </div>
          </section>
          : null}
      </div>
    </Form>
  )
}

export default withFormik({
  enableReinitialize: true,
  mapPropsToValues(props) {
    return {
      page: props.page,
      header: props.header,
      editMode: props.editMode,
      accountId: props.accountId,
      defaultDeliveryType: props.defaultDeliveryType,
      deliveryTypeId: props.deliveryTypeId,
      account: props.account,
      currentUser: props.currentUser,
      timeWindows: props.timeWindows,
      suggestedTimeWindows: props.suggestedTimeWindows,
      skus: props.skus,
      orderItems: props.orderItems,
      memo: props.memo,
      purchaseOrderNumber: props.purchaseOrderNumber
    }
  },

  validationSchema: Yup.object().shape({
    timeWindows: Yup.array().of(Yup.object().shape({
      startTime: Yup.string(),
      endTime: Yup.string(),
      date: Yup.string(),
    })).min(1, "Min 1 Delivery TimeWindow required"),
    orderItems: Yup.array().of(Yup.object().shape({
      skuId: Yup.number().integer(),
      requestedQuantity: Yup.number().integer()
    })).min(1, "Min 1 OrderItem required")
  }),
  handleSubmit(values, { setSubmitting, props }) {
    props.onSubmit(values)
    setTimeout(() => setSubmitting(false), 500)
  }
})(OrderFormComponent)

import React, { Component } from 'react';
import createReactClass from 'create-react-class';
import Select from 'react-virtualized-select'
import axios from 'axios'

const SkuTagAccountItem = ({ sku, associatedSkus, getSkus, onChange, onRemove }) => {
  const trashStyle = {
    color: '#f04124',
    fontSize: '20px'
  }

  const commonProps = {
    name: "tag[tagged][]",
  }

  let selectProps = {}
  if (sku.edit) {
    selectProps = { ...commonProps, async: true, onChange: onChange, loadOptions: getSkus, placeholder: "Select an item.." }
  } else {
    selectProps = { ...commonProps, value: sku.id, options: associatedSkus }
  }

  return (
    <div className='row'>
      <div className="row-collapse">
        <div className="medium-10 columns">
            <Select {...selectProps} />
        </div>
        <div className="medium-2 columns">
          <span className="postfix">
            <a href="#" style={trashStyle} onClick={onRemove}><i className="fi-trash"></i></a>
          </span>
        </div>
      </div>
    </div>
  )
}


export default class SkuTagForm extends Component {
  constructor(props) {
    super(props)

    const { name, associated_skus } = this.props;

    this.state = { 
      name: name,
      associatedSkus: associated_skus.map((sku) => {
        return { id: sku.id, value: sku.id, label: sku.name, edit: false }
      }),
      newItem: { value: null, label: null, edit: true },
    }
  }

  getSkus(term="") {
    return axios.get(`/skus.json?quick=true&q=${term}`)
    .then((json) => {
      const skus = json['data']['skus'].map((sku) => {
        return { value: sku.id, label: sku.name, edit: false }
      })

      return { options: skus }
    })
  }

  onAddNew() {
    const { associatedSkus, newItem } = this.state
    this.setState({ associatedSkus: associatedSkus.concat(newItem) })
  }

  onRemoveItem(index) {
    const newAssociatedSkus = this.state.associatedSkus.filter((item, i) => index != i)
    this.setState({ associatedSkus: newAssociatedSkus })
  }

  onSelectValueChange(index, newSku) {
    const skus = this.state.associatedSkus.slice(0)
    skus[index] = { id: newSku.value, value: newSku.value, label: newSku.label, edit: false }
    this.setState({ associatedSkus: skus })
  }

  onTitleChange(e) {
    this.setState({ name: e.target.value })
  }

  render() {
    const { name, associatedSkus } = this.state

    const skuItems = associatedSkus.map((sku, index) => {
      return (
        <div key={index}>
          <SkuTagAccountItem sku={sku} associatedSkus={associatedSkus} getSkus={this.getSkus} onChange={this.onSelectValueChange.bind(this, index)} onRemove={this.onRemoveItem.bind(this, index)} />
        </div>
      )
    })

    return (
      <div>
        { skuItems }

        <div className="row">
          <div className="small-12 columns">
            <button type="button" onClick = {this.onAddNew.bind(this)} className="button tiny">Add New Sku</button>
            <hr />
          </div>
        </div>
      </div>
    )
  }
}

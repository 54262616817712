import $ from 'jquery'
import Routes from '../../routes.js.erb'

function fetchDeliveryTypesSuccess(payload) {
  return {
    type: 'FETCH_DELIVERY_TYPES_SUCCESS',
    payload
  }
}

export function fetchDeliveryTypes() {
  const url = Routes.delivery_types_path({format: 'json'})

  return (dispatch) => {
    $.getJSON(url)
      .then(response => {
        dispatch(fetchDeliveryTypesSuccess(response))
      })
  }
}

function fetchDefaultDeliveryTypeSuccess(payload) {
  return {
    type: 'FETCH_DEFAULT_DELIVERY_TYPE_SUCCESS',
    payload
  }
}

export function fetchDefaultDeliveryType() {
  const filterParams = { default: true, format: 'json' }
  const url = Routes.delivery_types_path(filterParams)

  return (dispatch) => {
    $.getJSON(url)
      .then(response => {
        dispatch(fetchDefaultDeliveryTypeSuccess(response))
      })
  }
}
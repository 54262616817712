import React, { Component } from 'react'
import {connect} from "react-redux"
import {
  fetchServiceExceptionCategories
} from "../../redux/actions/serviceExceptionsCategories"
import ReactTable from "react-table"
import ServiceExceptionCategoriesForm from "./components/ServiceExceptionCategoriesForm.jsx"
import ServerErrors from "../../components/form/ServerErrors"

class ServiceExceptionCategoriesIndexView extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    const { dispatch } = this.props

    dispatch(fetchServiceExceptionCategories())
  }

  render() {
    const { serviceExceptionCategories, dispatch, serverErrors } = this.props
    const data = serviceExceptionCategories
    const columns = [
      {
        Header: 'Id',
        id: 'id',
        accessor: d => d.id,
        width: 100
      },
      {
        Header: 'Exceptionable',
        id: 'service_exception_category_type',
        accessor: d => d.service_exception_category_type,
        width: 100
      },
      {
        Header: 'Name',
        id: 'name',
        accessor: d => [d.id, d.name],
        Cell: row => <span><a href={Routes.service_exception_category_path(row.value[0])} target='_blank'>{row.value[1]}</a></span>,
        width: 300
      },
      {
        Header: 'Description',
        id: 'description',
        accessor: d => d.description,
        Cell: row => <span>{row.value}</span>
      }
    ]

    return (
      <div className="ServiceExceptionCategoriesIndex">
        <ServerErrors serverErrors={serverErrors} />
        <div className="row">
          <div className="medium-12 columns">
            <h3>New Service Exception Category</h3>
            <ServiceExceptionCategoriesForm dispatch={dispatch} />
          </div>
        </div>
        <div className="row">
          <div className="medium-12 columns">
            <h3>Service Exception Categories</h3>
            <ReactTable
              className='-striped -highlight'
              data={data}
              columns={columns}
              showPagination={false}
              defaultPageSize={25}
            />
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, _props) => {
  return {
    serviceExceptionCategories: state.serviceExceptionCategories.current.serviceExceptionCategories,
    serverErrors: state.serviceExceptionCategories.current.errors,
    types: state.serviceExceptionCategories.current.types
  }
}

const connectedServiceExceptionCategoriesIndex = connect(mapStateToProps,)(ServiceExceptionCategoriesIndexView)
export default connectedServiceExceptionCategoriesIndex

import React, { Component } from 'react'
import moment from 'moment'
import $ from 'jquery'

import { EditorState, convertFromRaw } from 'draft-js';

import Editor from 'draft-js-plugins-editor';
import Spinner from 'react-md-spinner'

import createMentionPlugin from 'draft-js-mention-plugin';
import createHashtagPlugin from 'draft-js-hashtag-plugin'
import createLinkifyPlugin from 'draft-js-linkify-plugin'

import UploadAttachmentLink from '../upload/UploadAttachmentLink.jsx'

import { comment } from "../../timeFormats"

const hashtagPlugin = createHashtagPlugin()
const linkifyPlugin = createLinkifyPlugin()

class Comment extends Component {
  constructor(props) {
    super(props)

    const { content } = props
    const contentAsJSON = JSON.parse(content)
    const editorState = EditorState.createWithContent(convertFromRaw(contentAsJSON))

    this.mentionPlugin = createMentionPlugin()
    this.state = { editMode: false, editorState, showReplies: props.isRoot || false, showReplyBox: false }
  }

  componentWillReceiveProps(props) {
    const { content } = props
    const contentAsJSON = JSON.parse(content)
    const editorState = EditorState.createWithContent(convertFromRaw(contentAsJSON))

    this.setState({ showReplyBox: false, editMode: false, editorState })
  }

  componentDidMount() {
    const { focusOn } = this.props

    if (this.props.id === focusOn) {
      setTimeout(function() {
        $('html, body').animate({
          scrollTop: $('#comment-' + focusOn).offset().top
        }, 2000)
      }, 2000)
    }
  }

  onClickReply() {
    const { showReplyBox } = this.state

    this.setState({ showReplies: true, showReplyBox: !showReplyBox })
  }

  onClickEdit() {
    const { editMode } = this.state
    const { id }       = this.props
    const BUFFER       = 100

    this.setState({ editMode: !editMode }, () => {
      setTimeout(function() {
        $('html, body').animate({
          scrollTop: $(`#comment-container-${id}`).offset().top - BUFFER
        }, 500)
      }, 100)
    })
  }

  onClickCancel = () => {
    const { editMode } = this.state
    const { content }  = this.props
    const contentAsJSON = JSON.parse(content)
    const editorState = EditorState.createWithContent(convertFromRaw(contentAsJSON))

    this.setState({ editMode: !editMode, editorState })
  }

  onClickPin() {
    const { id, commentableId, commentableType, pinned } = this.props
    const params = { id, commentableId, commentableType, pinned: !pinned }

    this.props.updateComment(params)
  }

  onShowReplies() {
    const { showReplies } = this.state

    this.setState({ showReplies: !showReplies })
  }

  onChange = (editorState) => {
    this.setState({ editorState })
  }

  renderEditor() {
    const { id, image, uploading } = this.props
    const { editorState } = this.state
    const style = image ? { } : { display: 'none' }
    const plugins = [this.mentionPlugin, linkifyPlugin, hashtagPlugin]

    return (
      <div tabIndex="0" id={"comment-" + id} className="readonly-editor">
        <Editor
          editorState={editorState}
          onChange={this.onChange}
          plugins={plugins}
          readOnly={true}
        />
        <span>{uploading ? <Spinner /> : null}</span>
        <img className='attachment' style={style} src={image} />
      </div>
    )
  }

  renderAuthor() {
    const { created_at, user } = this.props
    const formattedCreateAt = moment(created_at).format(comment)

    return (
      <span className="left">
        <em>{user && user.name}</em>
        <span className='ml10'>{formattedCreateAt}</span>
      </span>
    )
  }

  renderActions() {
    const { comments, currentUser, isRoot, readOnly, pinned, user, withPinned } = this.props
    const { showReplyBox, showReplies } = this.state

    return(
      <span className='right'>
        {withPinned ? isRoot && <a className="tiny" onClick={this.onClickPin.bind(this)}>{pinned ? 'Unpin' : 'Pin'}</a> : null}
        {comments.length > 0 && <a className="tiny ml10" onClick={this.onShowReplies.bind(this)}>{showReplies ? 'Hide Thread' : 'Show Thread'}</a> }
        {!readOnly && !showReplyBox && currentUser.id === user.id &&
          <span className="tiny">
            <UploadAttachmentLink text='Upload Attachment ' />
            <a className='ml10' onClick={this.onClickEdit.bind(this)}>Edit </a>
          </span>
        }
        {!readOnly && !showReplyBox && <a className="tiny ml10" onClick={this.onClickReply.bind(this)}>Reply</a>}
      </span>
    )
  }

  renderReplyBox() {
    const { id, currentUser, comments, renderCommentInputBox, renderCommentUpdateBox, readOnly } = this.props
    const { showReplyBox, showReplies} = this.state

    return (
      <span>
        {showReplyBox && renderCommentInputBox("comment", id)}
        {showReplies && comments && comments.map(comment =>
          <Comment
            key={comment.id}
            currentUser={currentUser}
            readOnly={readOnly}
            renderCommentInputBox={renderCommentInputBox}
            renderCommentUpdateBox={renderCommentUpdateBox}
            showReplies={showReplies}
            {...comment}
          />
        )}
      </span>
    )
  }

  renderCommentBox() {
    return (
      <div>
        {this.renderEditor()}
        {this.renderAuthor()}
        {this.renderActions()}
        {this.renderReplyBox()}
      </div>
    )
  }

  render() {
    const { id, renderCommentUpdateBox } = this.props
    const { editorState, editMode } = this.state

    return (
      <div className="panel small-12 columns comment" id={`comment-container-${id}`} data-id={id}>
        {editMode
          ? renderCommentUpdateBox(id, editorState,  this.onClickCancel)
          : this.renderCommentBox()}
      </div>
    )
  }
}

export default Comment

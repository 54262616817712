import React, { Component } from 'react'
import { EditorState, convertToRaw, convertFromRaw, RichUtils } from 'draft-js'
import Editor, { composeDecorators } from 'draft-js-plugins-editor'
import createMentionPlugin, { defaultSuggestionsFilter } from 'draft-js-mention-plugin'
import createToolbarPlugin from 'draft-js-static-toolbar-plugin'
import createEmojiPlugin from 'draft-js-emoji-plugin'
import createLinkifyPlugin from 'draft-js-linkify-plugin'

import {
  ItalicButton,
  BoldButton,
  UnderlineButton,
  UnorderedListButton,
  OrderedListButton,
} from 'draft-js-buttons'

const staticToolbarPlugin = createToolbarPlugin({
  structure: [
    BoldButton,
    ItalicButton,
    UnderlineButton,
    UnorderedListButton,
    OrderedListButton,
  ]
})

const { Toolbar } = staticToolbarPlugin
const emojiPlugin = createEmojiPlugin()
const { EmojiSuggestions, EmojiSelect } = emojiPlugin
const linkifyPlugin = createLinkifyPlugin()


export default class SimpleMentionEditor extends Component {

  constructor(props) {
    super(props);

    this.mentionPlugin = createMentionPlugin();
    this.hashtagMentionPlugin = createMentionPlugin({ mentionTrigger: '#', mentionPrefix: '#', entityMutability: 'IMMUTABLE' })

    const { users, commentTags, editorState } = props

    this.state = { editorState: editorState || EditorState.createEmpty(), suggestions: users || [], hashtagSuggestions: commentTags || [] }
    this.onSearchChange = this.onSearchChange.bind(this)
    this.onHashtagSearchChange = this.onHashtagSearchChange.bind(this)
  }

  onChange = (editorState) => {
    this.setState({
      editorState,
    })
  }

  onSearchChange = ({ value }) => {
    const { users } = this.props
    this.setState({
      suggestions: defaultSuggestionsFilter(value, users),
    })
  }

  onHashtagSearchChange = ({ value }) => {
    const { commentTags } = this.props
    this.setState({
      hashtagSuggestions: defaultSuggestionsFilter(value, commentTags),
    });
  };

  focus = () => {
    this.editor.focus();
  };

  handleSubmit = () => {
    const { onSubmit, id, commentableType, commentableId } = this.props
    const { editorState } = this.state
    const content = editorState.getCurrentContent()
    const jsonString = JSON.stringify(convertToRaw(content))
    onSubmit({ content: jsonString, id, commentableType, commentableId })
    setTimeout(() => this.setState({ editorState: EditorState.createEmpty() }), 500)
  }

  editorStyle = {
    'border': '1px solid grey',
    'marginBottom': '5px',
    'padding': '5px',
    'backgroundColor':  'white',
  }

  render() {
    const UserMentionSuggestions = this.mentionPlugin.MentionSuggestions
    const HashtagMentionSuggestions = this.hashtagMentionPlugin.MentionSuggestions
    const plugins = [this.mentionPlugin, this.hashtagMentionPlugin, staticToolbarPlugin, emojiPlugin, linkifyPlugin]

    const { editMode } = this.props

    return (
      <div className="comment-input small-12 columns" onClick={this.focus}>
        <div className="editor" style={this.editorStyle}>
          <Editor
            editorState={this.state.editorState}
            onChange={this.onChange}
            plugins={plugins}
            ref={(element) => { this.editor = element; }}
          />
          <EmojiSelect />
          <Toolbar />
        </div>
        <UserMentionSuggestions
          onSearchChange={this.onSearchChange}
          suggestions={this.state.suggestions}
        />
        <HashtagMentionSuggestions
          onSearchChange={this.onHashtagSearchChange}
          suggestions={this.state.hashtagSuggestions}
        />
        <EmojiSuggestions />
        {editMode ? <button className="button tiny alert" onClick={this.props.onCancel}>Cancel</button> : null}
        <button className="button tiny right" onClick={this.handleSubmit}>Submit</button>
      </div>
    );
  }
}

import React, { Component } from 'react'

const TableComponent = (props) => {
  const { accounts, className } = props
  const rows = accounts.map(account => {

    const { id, name, accountType, salesperson, warehouse } = account

    return (
      <tr key={id}>
        <td>{name}</td>
        <td>{accountType.name}</td>
        <td>{salesperson.name}</td>
        <td>{warehouse.name}</td>
      </tr>
    )
  })

  return (
    <table className={className}>
      <thead>
        <tr>
          <th>Account Name</th>
          <th>Account Type</th>
          <th>Salesperson</th>
          <th>Warehouse</th>
        </tr>
      </thead>
      <tbody>
        {rows}
      </tbody>
    </table>
  )
}

export default TableComponent

import React, { Component } from 'react'
import { capitalize } from 'lodash'

import WarehouseSelect from '../../../components/select/WarehouseSelect.jsx'
import AccountTypeSelect from '../../../components/select/AccountTypeSelect.jsx'
import AccountGroupSelect from '../../../components/select/AccountGroupSelect.jsx'
import BooleanSelect from '../../../components/select/BooleanSelect.jsx'
import AccountSelect from '../../../components/select/AccountSelect.jsx'
import DistributorSelect from '../../../components/select/DistributorSelect.jsx'

import AccountTimeWindowsComponent from './AccountTimeWindowsComponent.jsx'

class AccountDetailComponent extends Component {
  constructor(props) {
    super(props)

    const { account, editing } = this.extractPristineState(props)
    this.state = { account, editing, submitting: false, attributesEditMode: false, error: '' }

    this.onChange= this.onChange.bind(this)
    this.onBlur  = this.onBlur.bind(this)
    this.onKeyDown = this.onKeyDown.bind(this)
    this.toggleAttributesEditMode = this.toggleAttributesEditMode.bind(this)
    this.onSubmit = this.onSubmit.bind(this)
  }

  accountProperties = {
    'name'                       : { label: 'Name' },
    'abc'                        : { label: 'ABC License Number' },
    'boe'                        : { label: 'BOE Number' },
    'requires_proof_of_delivery' : { label: 'Requires proof of delivery?', component: BooleanSelect },
    'abc_type'                   : { label: 'ABC License Type' },
    'sap_id'                     : { label: 'SAP ID' },
    'publish'                    : { label: 'Publish to website?', component: BooleanSelect },
    'warehouse'                  : { label: 'Shipping Warehouse', association: true, component: WarehouseSelect },
    'distributor'                : { label: 'Distributor', association: true, component: DistributorSelect },
    'account_type'               : { label: 'Account Type', association: true, component: AccountTypeSelect },
    'account_group'              : { label: 'Account Group', association: true, component: AccountGroupSelect },
    'remit_account'              : { label: 'Remit Account', association: true, component: AccountSelect },
    'merged_into'                : { label: 'Merge Into Account', association: true, component: AccountSelect },
    'memo'                       : { label: 'Memo' }
  }

  extractPristineState(props) {
    const account = { }
    const editing = { }
    Object.entries(props.account).map((keyValuePair) => {
      const [key, value] = keyValuePair
      if (this.accountProperties[key]) {
        if ((key === 'publish') || (key === 'requires_proof_of_delivery')) {
          account[key] = { id: value, name: capitalize(value) }
          editing[key] = false
        } else {
          account[key] = value
          editing[key] = false
        }
      }
    })

    return { account, editing }
  }


  onChange(key, rawVal) {
    let val
    const isAssociationProperty = this.accountProperties[key] && this.accountProperties[key].association

    if (isAssociationProperty || key === 'publish' || key === 'requires_proof_of_delivery') {
      const { value, label } = rawVal
      val = { id: value, name: label }
    } else {
      val = rawVal
    }

    const { account } = this.state

    this.setState({ account: { ...account, [key]: val } })
  }

  onSubmit() {
    const self = this
    const { accountId } = this.props

    const account = { }
    Object.entries(this.state.account).forEach(keyValPair => {
      const [key, val] = keyValPair
      const { association } = this.accountProperties[key]
      if (association && val != null) {
        account[key + '_id'] = val.id
      } else if ((key === 'publish') || (key === 'requires_proof_of_delivery')) {
        account[key] = val.id
      } else {
        account[key] = val
      }
    })

    this.setState({ submitting: true })
    $.ajax({
      type: 'PATCH',
      url: `/accounts/${accountId}`,
      data: { account },
      dataType: 'json'
    }).then(response => {
      self.setState({ editing: { }, submitting: false, attributesEditMode: false, error: null }, () => {
        self.props.refetchDataCallback()
      })
    }).catch(err => {
      let error = ''
      Object.entries(err.responseJSON).forEach((keyArr) => {
        const [key, arr] = keyArr
        error += key + ': '
        error += arr.join(', ')
      })
      self.setState({ submitting: false, error })
    })
  }

  onKeyDown(e, key, value) {
    if (e.keyCode === 27) {
      this.onBlur(key)
    }
  }

  toggleAttributesEditMode() {
    const { attributesEditMode, editing } = this.state
    const { account } = this.props
    const accountProps = Object.keys(this.accountProperties)

    const resetAccountState = { }
    const resetEdit  = { }

    if (attributesEditMode) {
      accountProps.forEach(key => {
        resetAccountState[key] = account[key]
        resetEdit[key] = false
      })
    } else {
      accountProps.forEach(key => {
        resetEdit[key] = true
      })
    }

    if (attributesEditMode) {
      this.setState({ attributesEditMode: !attributesEditMode, editing: resetEdit, account: resetAccountState })
    } else {
      this.setState({ attributesEditMode: !attributesEditMode, editing: resetEdit })
    }
  }

  onBlur(key) {
    const { account } = this.state
    const updatedAccount = { ...account, [key]: this.props.account[key] }

    const { editing } = this.state
    const updatedEditingState = { ...editing, [key]: false }

    this.setState({ account: updatedAccount, editing: updatedEditingState })
  }

  render() {
    const { account, editing, submitting, attributesEditMode, error } = this.state

    const tableRows = Object.entries(account).map(keyValuePair => {
      const [key, value] = keyValuePair
      const { label, association, component } = this.accountProperties[key]

      if (component) {
        return (
          <tr key={key}>
            <td style={{width: "35%"}}>{label}</td>
            <td style={{cursor: 'pointer', width: "65%"}}>
              {editing[key] ?
                component({ name: key,
                            value: (value && value.id),
                            onChange: this.onChange,
                            onBlur: (e => {}),
                            disabled: !editing[key]
                })
                : (value && value.name || '').toString()
              }
            </td>
          </tr>
        )
      } else {
        return (
          <tr key={key}>
            <td style={{width: "35%"}}>{label}</td>
            <td style={{cursor: 'pointer', width: "35%"}}>
              {editing[key]
                ? <input
                    autoFocus
                    disabled={submitting}
                    value={value || ''}
                    onKeyDown={e => this.onKeyDown(e, key, e.target.value)}
                    onChange={e => this.onChange(key, e.target.value)}
                  />
                : value
              }
            </td>
          </tr>
        )
      }
    })

    const { accountId, refetchDataCallback } = this.props
    const { address, time_windows } = this.props.account

    return (
      <div className="AccountDetail">
        <div className="medium-12 columns">
          <p style={{marginBottom: "0px", marginTop: "5px"}} className="tinyheader">Basic Details</p>

          {error && <small className="error">{error}</small>}
          <table width="100%">
            <thead>
              <tr>
                <th>Account Attribute</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              {tableRows}
            </tbody>
          </table>
          <div>
            <button disabled={!attributesEditMode} className="tiny" onClick={e => this.onSubmit()}>Save Changes</button>
          </div>
          <div className="right">
            <label htmlFor="attributesEdit" className="tinyheader" style={{float: 'left'}}>Edit Attributes</label>
            <span onClick={this.toggleAttributesEditMode} id="attributesEdit" className="switch round small" style={{float: 'right'}}>
              <input type="radio" checked={attributesEditMode}  />
              <label></label>
            </span>
          </div>
        </div>
        <div className="medium-12 columns">
          <p style={{marginBottom: "0px", marginTop: "5px"}} className="tinyheader">Address Details</p>
          <table width="100%">
            <thead>
              <tr>
                <th>Address Attribute</th>
                <th>Value</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Line 1</td>
                <td>{address.line1}</td>
              </tr>
              <tr>
                <td>Line 2</td>
                <td>{address.line2}</td>
              </tr>
              <tr>
                <td>City</td>
                <td>{address.city}</td>
              </tr>
              <tr>
                <td>State</td>
                <td>{address.state}</td>
              </tr>
              <tr>
                <td>Zip</td>
                <td>{address.zip}</td>
              </tr>
              <tr>
                <td>County</td>
                <td>{address.county}</td>
              </tr>
              <tr>
                <td>Neighborhood</td>
                <td>{address.neighborhood}</td>
              </tr>
              <tr>
                <td>Country</td>
                <td>{address.country}</td>
              </tr>
              <tr>
                <td>Latitude</td>
                <td>{address.latitude}</td>
              </tr>
              <tr>
                <td>Longitude</td>
                <td>{address.longitude}</td>
              </tr>
            </tbody>
          </table>
          <a href={Routes.edit_account_path(accountId)} className="button tiny right">Edit Address</a>
        </div>

        <div>
          <AccountTimeWindowsComponent accountId={accountId} />
        </div>
      </div>
    )
  }
}

export default AccountDetailComponent

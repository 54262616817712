import React, {Component} from "react"
import Paginate from "../../../components/paginate"
import Spinner from "react-md-spinner"
import ReactTable from "react-table"
import { base } from "../../../timeFormats"

class FreightShipmentsIndexTable extends Component {
  constructor(props) {
    super(props)

    this.getDeliveryType.bind(this)
  }

  getDeliveryType(delivery_type_id) {
    return this.props.deliveryTypes.find(el => el.id === delivery_type_id)
  }

  columns = [
    {
      Header:     'Id',
      id:         'id',
      accessor:   d => d.id,
      cell:       (row) => <span>{row.id}</span>,
      width:      50,
      style: { textAlign: 'center' }

    },
    {
      Header:     'Delivery Route',
      id:         'deliveryroute_id',
      accessor:   (d) => {
        return (
          <span>
            <a href={Routes.deliveryroute_path(d.deliveryroute_id)}>Route #{d.deliveryroute_id}</a>
          </span>
        )
      },

      style: { textAlign: 'center' }
    },
    {
      Header:     'Delivery Type',
      id:         'delivery_type_id',
      accessor:   (d) => {
        const deliveryType = this.getDeliveryType(d.delivery_type_id)
        return (
          <span>
            <a href={Routes.delivery_type_path(d.delivery_type_id)}>{deliveryType.name}</a>
          </span>
        )
      },
      style: { textAlign: 'center' }
    },
    {
      Header:     'Shipped',
      id:         'shippedAt',
      accessor:   d => moment(d.shipped_at).format(base),
      width:      200,
      style: { textAlign: 'center' }
    },
    {
      Header:     'Action',
      id:         '',
      width:      75,
      Cell:       row => <span><a href={Routes.edit_freight_shipment_path(row.original.id)}>Edit</a></span>,
      style: { textAlign: 'center' }
    }
  ]


  render() {
    const { limit, page, offset, totalCount, loading, handlePageChange, freightShipments } = this.props
    const data = freightShipments

    return(
      <div className="row">
        <div className="medium-12 columns">
          <Paginate
            dataName="Freight Shipments"
            totalCount={totalCount}
            limit={parseInt(limit)}
            page={parseInt(page)}
            offset={parseInt(offset)}
            onPageChange={handlePageChange}
          />
          {loading ?
            <Spinner /> :
            <ReactTable
              className='-striped -highlight'
              data={data}
              columns={this.columns}
              showPagination={false}
              defaultPageSize={Math.min(limit, data.length)}
            />
          }
        </div>
      </div>
    )
  }
}

export default FreightShipmentsIndexTable

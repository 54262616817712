import React, { Component } from 'react'
import CustomSelect from '../../../components/select/base/CustomSelect.jsx'
import UploadAttachmentLink from '../../../components/upload/UploadAttachmentLink.jsx'
import { paymentMethods } from "../../../constants"
import $ from "jquery"

class Payments extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    const that = this

    $(document).ready(function() {
      $(document).on('click', 'a.upload', (e) => {
        const fileInput = $(e.target).siblings("input[type='file']")[0]
        $(fileInput).click()
      })

      $(document).on('change', 'input[type="file"]', (e) => {
        const { handleChanges } = that.props
        const payment = $(e.target).parentsUntil('.Payments')[2]
        const index = $(payment).data('index')
        const link = $(payment).find('a.upload')[0]
        const file = e.target.files[0]
        const fileReader = new FileReader()

        fileReader.addEventListener("load", (e) => {
          handleChanges(index, 'checkImage', e.target.result)
        });

        fileReader.readAsDataURL(file)
        $(link).html(file.name)
      })
    })
  }

  removeItem(_event, index) {
    const { paymentsAttributes, handleChanges } = this.props

    paymentsAttributes[index] = null

    const updatedPaymentsAttributes = paymentsAttributes.filter(el => el != null)

    handleChanges('paymentsAttributes', updatedPaymentsAttributes)
  }

  render() {
    const { paymentsAttributes, handleChanges } = this.props

    return (
      <div className={'Payments'}>
        {paymentsAttributes.map((payment, index) => {
          const { paymentMethod, checkNumber, amount, _id } = payment

          return (
            <Payment
              _id={_id}
              key={index}
              index={index}
              paymentMethod={paymentMethod}
              checkNumber={checkNumber}
              amount={amount}
              handleChanges={handleChanges}
              removeItem={this.removeItem.bind(this)}
            />
          )
        })}
      </div>
    )
  }
}

export default Payments

class Payment extends Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { _id, index, amount, checkNumber, paymentMethod } = this.props
    const { handleChanges } = this.props

    const trashStyle = {
      color: '#f04124',
      fontSize: '20px'
    }

    const optionsForSelect = Object.keys(paymentMethods).map((key) => {
      return {
        name: key,
        id: paymentMethods[key]
      }
    })

    return (
      <div className='row Payment' data-index={index} data-id={_id}>
        <div className='medium-3 columns'>
          <label>Payment Method
            <CustomSelect
              options={optionsForSelect}
              value={parseInt(paymentMethod)}
              onChange={(_, option) => handleChanges(index, 'paymentMethod', option.value)}
            />
          </label>
        </div>

        <div className='medium-3 columns'>
          {paymentMethod === paymentMethods['check'] ?
            <label>Check Number
              <input
                type='text'
                value={checkNumber}
                onChange={event => handleChanges(index, 'checkNumber', event.target.value)}/>
            </label>
            : null}
        </div>

        <div className='medium-3 columns'>
            <label>Attachment</label>
            <UploadAttachmentLink
              text={'Upload Photo'}
              style={{'display': 'inline-block', 'verticalAlign': 'sub'}}
            />
        </div>


        <div className='medium-3 columns'>
          <div className='row collapse'>
            <label>Amount</label>
            <div className='small-10 columns'>
              <input
                type='number'
                min={0}
                value={amount || 0}
                onChange={event => handleChanges(index, 'amount', parseFloat(event.target.value))}
              />
            </div>
            <div className='small-2 columns'>
              <span className='postfix'>
                <a
                  style={trashStyle}
                  onClick={_event => handleChanges(index, '_destroy', true)}
                >
                  <i className='fi-trash'></i>
                </a>
              </span>
            </div>
          </div>
        </div>
        <hr/>
      </div>
    )
  }
}
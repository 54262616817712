import FormikSelect from './base/FormikSelect.jsx'

const BooleanSelect = (props) => {
  const { trueLabel, falseLabel } = props
  const options = [{ value: true, label: trueLabel || 'True' }, { value: false, label: falseLabel || 'False' }]
  const allProps = { ...props, options  }
  return (
    <FormikSelect { ...allProps } />
  )
}

export default BooleanSelect

/* eslint no-console:0 */
// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.
//
// To reference this file, add <%= javascript_pack_tag 'application' %> to the appropriate
// layout file, like app/views/layouts/application.html.erb


// Uncomment to copy all static images under ../images to the output folder and reference
// them with the image_pack_tag helper in views (e.g <%= image_pack_tag 'rails.png' %>)
// or the `imagePath` JavaScript helper below.
//
// const images = require.context('../images', true)
// const imagePath = (name) => images(name, true)

const images = require.context('../images', true)
const fonts = require.context('../fonts', true)

const $ = window.$ = window.jQuery = global.$ = global.jQuery = require('jquery')
const jQueryUI = window.jQueryUI = require('jquery-ui')
const jQueryUJS = window.jQueryUJS = require('jquery-ujs')
const reveal = window.reveal = require('jquery-reveal')
const _ = window._ = require('underscore')
const foundation = window.foundation = require('foundation-sites/js/foundation.min')
const ratchet = window.ratchet = require('ratchet-npm')
const moment = window.moment = require('moment')
const momentTimezone = window.momentTimezone = require('moment-timezone')
const select2 = window.select2 = require('select2')
const tipsy = window.tipsy = require('tipsy')
const React = window.React = require('react')
const ReactDom = window.ReactDOM = require('react-dom')
const ReactUjs = window.ReactUjs = require('react_ujs')
const Routes  = window.Routes = require('../javascripts/routes.js.erb')

import '../javascripts/application'

export function commentTags(state = [], action) {
  const { payload } = action

  switch (action.type) {
    case 'FETCH_COMMENT_TAGS_SUCCESS':
      const { comment_tags } = payload
      return { all: comment_tags || payload }
    default:
      return state;
  }
}



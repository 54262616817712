import React, { Component } from 'react'
import { connect } from 'react-redux'
import FormikSelect from './FormikSelect.jsx'

class CustomSelect extends Component {

  componentDidMount() {
    const { fetchOptionsAction, dispatch } = this.props
    fetchOptionsAction && dispatch(fetchOptionsAction())
  }

  render() {
    const options = this.props.options && this.props.options.map((el) => {
      return { value: el.id, label: el.name }
    });

    const allProps = { ...this.props, options }

    return (
      <FormikSelect { ...allProps } />
    )
  }
}

const mapStateToProps = (state, props) => {
  const { selector, options } = props
  return {
    options: options || selector && selector(state)
  }
}

const connectedCustomSelect = connect(mapStateToProps)(CustomSelect)
export default connectedCustomSelect

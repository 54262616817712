import mapboxgl from 'mapbox-gl'

export function getFeatures(accounts) {
  return accounts.map(account => {

    const { id, name, geometry, accountType, salesperson, address } = account

    return {
      type: "Feature",
      properties: {
        id,
        name,
        accountTypeName: accountType.name,
        salespersonName: salesperson.name,
        oneLineAddress: address.one_line_address
      },
      geometry
    }
  })
}

export function onClick(e, map) {
  const coordinates = e.features[0].geometry.coordinates.slice()
  const { id, name, accountTypeName, salespersonName, oneLineAddress } = e.features[0].properties
  const description = `<a target=_blank href=/accounts/${id}>` + name + "</a>" + "<br/>" + accountTypeName + "<br/>" + salespersonName + "<br/>" + oneLineAddress


  // Ensure that if the map is zoomed out such that multiple
  // copies of the feature are visible, the popup appears
  // over the copy being pointed to.
  while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
    coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
  }

  new mapboxgl.Popup()
    .setLngLat(coordinates)
    .setHTML(description)
    .addTo(map)
}


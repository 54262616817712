import React, { Component } from 'react'
import { connect } from 'react-redux'
import { fetchFreightShipments } from "../../redux/actions/freightShipments"
import { fetchDeliveryTypes } from "../../redux/actions/deliveryTypes"
import FreightShipmentsIndexFilter from "./components/FreightShipmentsIndexFilter.jsx"
import FreightShipmentsIndexTable from "./components/FreightShipmentsIndexTable.jsx"

class FreightShipmentsIndexView extends Component {
  constructor(props) {
    super(props)

    const defaultParams = {
      page:       0,
      limit:      200,
      offset:     0,
      sortBy:     'id',
      order:      'ASC',
    }

    this.state = {
      currentParams: { ...defaultParams }
    }
  }

  componentDidMount() {
    const { dispatch } = this.props
    const { currentParams } = this.state

    dispatch(fetchFreightShipments(currentParams))
    dispatch(fetchDeliveryTypes())
  }

  handlePageChange = (offset, page) => {
    const { currentParams } = this.state
    const { dispatch } = this.props
    const that = this

    this.setState({ currentParams: { ...currentParams, page, offset }}, () => {
      dispatch(fetchFreightShipments(that.state.currentParams))
    })
  }

  onSubmit = (params) => {
    const { currentParams } = this.state
    const { dispatch } = this.props
    const that = this

    this.setState({currentParams: { ...currentParams, ...params }}, () => {
      dispatch(fetchFreightShipments(that.state.currentParams))
    })
  }

  render() {
    const { currentParams } = this.state
    const { dispatch } = this.props
    const { freightShipments, deliveryTypes, totalCount, offset, loading } = this.props
    const { limit, page } = this.state.currentParams

    if (freightShipments.length === 0 || deliveryTypes.length === 0) return null

    return (
      <div className="FreightShipments">
        <div className='row'>
          <div className='row'>
            <div className='small-12 columns'>
              <h3 className='subheader'>Freight Shipments</h3>
            </div>
          </div>
        </div>

        <FreightShipmentsIndexFilter
          currentParams={currentParams}
          dispatch={dispatch}
          onSubmit={this.onSubmit}
        />

        <FreightShipmentsIndexTable
          page={page}
          limit={limit}
          offset={offset}
          freightShipments={freightShipments}
          deliveryTypes={deliveryTypes}
          totalCount={totalCount}
          loading={loading}
          handlePageChange={this.handlePageChange}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.notifications.all.loading || false,
    freightShipments: state.freightShipments.all.freightShipments || [],
    deliveryTypes: state.deliveryTypes.all || [],
    offset: state.freightShipments.all.offset || 0,
    totalCount: state.freightShipments.all.totalCount || 0,
  }
}

const connectedFreightShipmentsIndexView = connect(mapStateToProps,)(FreightShipmentsIndexView)
export default connectedFreightShipmentsIndexView

import CustomSelect from './base/CustomSelect.jsx'
import { fetchProspectSources } from '../../redux/actions/prospectSources.js'

const ProspectSourceSelect = (props) => {
  const allProps = { ...props, fetchOptionsAction: () => fetchProspectSources(), selector: (state) => state["kpi"]["prospectSources"]  }
  return (
    <CustomSelect { ...allProps } />
  )
}

export default ProspectSourceSelect

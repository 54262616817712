import React, { Component } from 'react'
import { connect } from 'react-redux'
import {fetchOrder} from "../../redux/actions/orders"
import {fetchCurrentUser} from "../../redux/actions/users"

class OrdersReceiptView extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    const { dispatch, match } = this.props

    dispatch(fetchOrder(match.params.id))
    dispatch(fetchCurrentUser())
  }

  render() {
    if (!this.props.order) return null

    const { order } = this.props

    return (
      <div className='OrderReceiptView'>
        <img className='receipt' src={order.receipt_image} />
      </div>
    )
  }
}

const mapStateToProps = (state, _props) => {
  const currentUser = state.currentUser.user
  const { order } = state.orders.current

  return {
    currentUser,
    order
  }
}
const connectedOrdersReceiptView = connect(mapStateToProps,)(OrdersReceiptView)
export default connectedOrdersReceiptView
import CustomSelect from './base/CustomSelect.jsx'
import { fetchAccountTypes } from '../../redux/actions/accountTypes.js'

const AccountTypeSelect = (props) => {
  const allProps = {
    ...props,
    fetchOptionsAction: () => fetchAccountTypes(),
    selector: (state) => state["kpi"]["accountTypes"]
  }
  return (
    <CustomSelect { ...allProps } />
  )
}

export default AccountTypeSelect

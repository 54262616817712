import $ from 'jquery'

// LEGACY React & ReactDOM needed for mounting with react_ujs
window.React = global.React = require('react');
window.ReactDOM = global.ReactDOM = require('react-dom');
window.ReactRailsUJS = global.ReactRailsUJS = require('react_ujs');

//products
global.ProductShow = require( './legacy/products/product_show.js.jsx' ).default;
global.ProductForm = require( './legacy/products/product_form.js.jsx' ).default;

//skus
global.SkuForm = require( './legacy/skus/sku_form.js.jsx' ).default;

//account_tag
global.AccountTagForm = require('./legacy/tags/account_tag_form.jsx').default;

//sku_tag
global.SkuTagForm = require('./legacy/tags/sku_tag_form.jsx').default;
global.UserTagForm = require('./legacy/tags/user_tag_form.jsx').default;

global.PasswordReset = require( './legacy/devise/password_reset.js.jsx' ).default;
global.PasswordResetRequest = require( './legacy/devise/password_reset_request.js.jsx' ).default;

global.RailsOrderDateFormGroup = require( './legacy/utilities/rails_order_date_form_group.js.jsx' ).default;
global.RailsDatetimeFormGroup = require( './legacy/utilities/rails_datetime_form_group.js.jsx' ).default;

global.UserNotificationsComponent = require('./components/notifications/UserNotificationsComponent.jsx')

$(document).ready(() => {
  global.ReactRailsUJS.mountComponents()
});

import React, { Component } from 'react'
import { connect } from 'react-redux'
import {
  fetchServiceExceptionCategory
} from "../../redux/actions/serviceExceptionsCategories"


class ServiceExceptionCategoriesShowView extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    const { dispatch, match } = this.props

    dispatch(fetchServiceExceptionCategory(match.params.id))
  }

  render() {
    const { serviceExceptionCategory, match } = this.props

    return (
      <div className="ServiceExceptionCategoriesShow">
        <div className="row">
          <div className="small-12 columns">
            <h3>Service Exception Category - {serviceExceptionCategory && serviceExceptionCategory.name}</h3>
            <button className="button tiny right dropdown" data-dropdown="drop2" href="javascript:;" aria-expanded="false">
              Actions
            </button>
            <ul className="f-dropdown" data-dropdown-content="" id="drop2" aria-hidden="true">
              <li>
                <a href={Routes.edit_service_exception_category_path(match.params.id)}>
                  Edit Service Exception Category
                </a>
              </li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="small-6 columns">
            <label>Name</label>
            <input readOnly="readonly" value={serviceExceptionCategory && serviceExceptionCategory.name}/>
          </div>
          <div className="small-6 columns">
            <label>Excaptionable Type</label>
            <input readOnly="readonly" value={serviceExceptionCategory.service_exception_category_type}/>
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns">
            <label>Description</label>
            <textarea readOnly="readonly" value={serviceExceptionCategory && serviceExceptionCategory.description}/>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, _props) => {
  return {
    serviceExceptionCategory: state.serviceExceptionCategories.current.serviceExceptionCategory
  }
}

const connectedServiceExceptionCategoriesShow = connect(mapStateToProps,)(ServiceExceptionCategoriesShowView)
export default connectedServiceExceptionCategoriesShow
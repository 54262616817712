import $ from 'jquery'
import Routes from '../../routes.js.erb'

function fetchActivitiesSuccess(payload) {
  return {
    type: 'FETCH_ACTIVITIES_SUCCESS',
    payload
  }
}

export function fetchActivities(params) {
  const { accountId } = params
  const url = Routes.activities_account_path(accountId, {format: 'json'})

  return (dispatch) => {
    $.getJSON(url)
      .then(response => {
        const { activities } = response
        dispatch(fetchActivitiesSuccess(activities))
      })
  }
}
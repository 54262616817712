import React from 'react';
import { withFormik, Form, Field } from 'formik'
import DateInput from "../../../components/form/input/DateInput"


const FreightShipmentsIndexFilter = ({ values, errors, touched, setFieldValue, setFieldTouched }) => {
  const onChange = (name, val) => {
    setFieldValue(name, val)
  }

  return (
    <Form>
      <br />
      <div className='FreightShipmentsIndexFilter'>
        <div className='row'>
          <div className='medium-12 columns'>
            <div className=' row fullWidth'>
              <div className='medium-6 columns'>
                <label>Start Date
                  <DateInput name='startDate' value={values.startDate} onChange={val => onChange('startDate', val)} />
                </label>
              </div>
              <div className='medium-6 columns'>
                <label>End Date
                  <DateInput name='endDate' value={values.endDate} onChange={val => onChange('endDate', val)} />
                </label>
              </div>
            </div>
          </div>
        </div>
        <br />
        <div className='row'>
          <div className='medium-12 columns'>
            <button className='button right'>Submit</button>
          </div>
        </div>
        <div className='row'>
          <div className='medium-12 columns'>
            <a href={Routes.new_freight_shipment_path()}
              className="button right"
            >
              Add New Shipment
            </a>
          </div>
        </div>
      </div>
    </Form>
  )
}

export default withFormik({
  mapPropsToValues(props) {
    const { startDate, endDate } = props.currentParams
    return {
      startDate,
      endDate
    }
  },

  handleSubmit(values, { props }) {
    const { startDate, endDate } = values
    const params = {
      startDate,
      endDate
    }

    props.onSubmit(params)
  }

})(FreightShipmentsIndexFilter)

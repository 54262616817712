import $ from 'jquery'
import './components'
import './legacy'

$(document).ready(() => {
  let token = $('meta[name="csrf-token"]').attr('content')

  $.fn.load = (callback) => $(window).on('load', callback)
  $(document).foundation()
  $('[data-behaviour~=select2]').select2()

  $.ajaxSetup({
    beforeSend: function ( xhr ) {
      xhr.setRequestHeader('X-CSRF-Token', token)
    }
  })
})

import $ from 'jquery'
import Routes from '../../routes.js.erb'

export function fetchCurrentUser() {
  const url = Routes.current_user_path({format: 'json'})

  return (dispatch) => {
    $.getJSON(url)
      .then(json => { dispatch({ type: 'FETCH_CURRENT_USER_SUCCESS', payload: json }) })
      .catch(response => console.log(response));
  }
}
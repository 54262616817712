import $ from 'jquery'
import Routes from '../../routes.js.erb'

function fetchSkusRequest() {
  return {
    type: 'FETCH_SKUS_REQUEST',
  }
}

function fetchSkusSuccess(response) {
  return {
    type: 'FETCH_SKUS_SUCCESS',
    payload: response,
  }
}
function fetchSkusFailure(response) {
  return {
    type: 'FETCH_SKUS_FAILURE',
    error: response.error,
  }
}

export function fetchSkus(_startDate, _endDate) {
  const url = Routes.skus_path({format: 'json'})
  const data = { quick: true }

  return (dispatch) => {
    dispatch(fetchSkusRequest());

    $.getJSON(url, data)
      .then(json => dispatch(fetchSkusSuccess(json)))
      .catch(response => dispatch(fetchSkusFailure(response)));
  }
}

function fetchAllSkusSuccess(payload) {
  return {
    type: 'FETCH_ALL_SKUS_SUCCESS',
    payload
  }
}

export function fetchAllSkus(viewContext) {
  const url = Routes.skus_path({format: 'json'})
  const data = { view_context: viewContext }

  return (dispatch) => {
    $.ajax({
      url,
      data,
      dataType: 'json',
    }).then(response => {
      dispatch(fetchAllSkusSuccess(response))
    })
  }
}

function fetchSkuInfoRequest() {
  return {
    type: 'FETCH_SKU_INFO_REQUEST'
  }
}

function fetchSkuInfoSuccess(accountId, payload) {
  return {
    type: 'FETCH_SKU_INFO_SUCCESS',
    payload : { ...payload, accountId }
  }
}

export function fetchSkuInfo(skuId, accountId, orderId) {
  const url = Routes.api_v3_account_sku_path(accountId, skuId, { order_id: orderId, format: 'json' })

  return (dispatch) => {
    dispatch(fetchSkuInfoRequest())
    $.ajax({
      url,
      dataType: 'json',
    }).then(response => {
      dispatch(fetchSkuInfoSuccess(accountId, response.data.attributes))
    }).catch(err => {
      console.log(err)
    })
  }
}

function fetchSkusInfoRequest() {
  return {
    type: 'FETCH_SKUS_INFO_REQUEST'
  }
}

function fetchSkusInfoSuccess(accountId, payload) {
  return {
    type: 'FETCH_SKUS_INFO_SUCCESS',
    payload : { ...payload, accountId }
  }
}

export function fetchSkusInfo(skuIds, order) {
  const { account } = order
  const url = Routes.api_v3_account_skus_path(account.id,{ ids: skuIds, order_id: order.id, format: 'json' })

  return (dispatch) => {
    dispatch(fetchSkusInfoRequest())
    $.ajax({
      url,
      dataType: 'json',
    }).then(response => {
      dispatch(fetchSkusInfoSuccess(account.id, response))
    }).catch(err => {
      console.log(err)
    })
  }
}
import React, { Component } from 'react';
import createReactClass from 'create-react-class';
import { base } from '../../timeFormats'
import moment from 'moment';


var ProductForm = createReactClass({

  render: function () {
    const skus = _.sortBy(this.props.skus, 'name');
    const productFamilies = _.sortBy(this.props.productFamilies, 'name');

    return (
      <div>
        <div className="row">
          <div className="small-12 columns">
            <h3 className="subheader">{this.props.formType}</h3>
          </div>
        </div>

        <div className="row">
          <div className="small-12 columns">
            <label>Name
              <input type="text" name="product[name]" defaultValue={this.props.product.name} />
            </label>
          </div>
        </div>

        <div className="row">
          <div className="small-12 columns">
            <label>Batch Identifier
              <input type="text" name="product[batch_identifier]" defaultValue={this.props.product.batch_identifier} />
            </label>
          </div>
        </div>

        <div className="row">
          <div className="small-12 columns">
            <label>Batch Date
              <input type="date" name="product[batch_date]" defaultValue={moment(this.props.product.batch_date).format(base)} />
            </label>
          </div>
        </div>

        <div className="row">
          <div className="small-12 columns">
            <label>Sellable?
              <select name="product[sellable]" defaultValue={this.props.product.sellable}>
                <option value={false}>No</option>
                <option value={true}>Yes</option>
              </select>
            </label>
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns">
            <label>Volume
              <input type="text" name="product[volume]" defaultValue={this.props.product.volume} />
            </label>
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns">
            <label>Beer?
              <select name="product[beer]" defaultValue={this.props.product.beer}>
                <option value={false}>No</option>
                <option value={true}>Yes</option>
              </select>
            </label>
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns">
            <label>Keg?
              <select name="product[keg]" defaultValue={this.props.product.keg}>
                <option value={false}>No</option>
                <option value={true}>Yes</option>
              </select>
            </label>
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns">
            <label>Deposit?
              <select name="product[deposit]" defaultValue={this.props.product.deposit}>
                <option value={false}>No</option>
                <option value={true}>Yes</option>
              </select>
            </label>
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns">
            <label>SKU</label>
            <select name="product[sku_id]" defaultValue={this.props.product.sku_id}>
              <option value={null}>SKUs...</option>
              {skus.map(sku => {
                return <option key={sku.id} value={sku.id}>{sku.name}</option>;
              })}
            </select>
          </div>
        </div>
        <div className="row">
          <div className="small-12 columns">
            <label>Product Family</label>
            <select name="product[product_family_id]" defaultValue={this.props.product.product_family_id}>
              <option value={null}>SKUs...</option>
              {productFamilies.map(sku => {
                return <option key={sku.id} value={sku.id}>{sku.name}</option>;
              })}
            </select>
          </div>
        </div>
        <ProductPricesBoxForm productPrices={this.props.product.prices}/>
      </div>

    )
  }
});

var ProductPricesBoxForm = createReactClass({

  getInitialState: function () {
    var newProductPrice = [
    { amount: null,
      county: "San Francisco",
      destroy: 0
    }
    ];
    var productPrices = this.props.productPrices.length == 0 ? newProductPrice : this.props.productPrices;
    return {
      productPrices: productPrices
    }
  },

  onAddProductPrice: function (evt) {
    var productPrice = [
    { amount: null,
      suggestion: null,
      destroy: 0
    }
    ];
    this.setState({productPrices: this.state.productPrices.concat(productPrice)});
  },

  onRemoveProductPrice: function (index) {
    var newProductPrices = this.state.productPrices;
    newProductPrices[index].destroy = 1;
    this.setState({newProductPrices: newProductPrices});
  },

  render: function () {
    return (
      <div>
        <div className="row">
          <div className="small-12 columns">
            <h4 className="subheader">Pricing</h4>
          </div>
        </div>

        {this.state.productPrices.map(function(productPrice, index) {
          var boundClick = this.onRemoveProductPrice.bind(this, index);
          return <ProductPriceWrapperForm index={index} onRemoveProductPrice ={boundClick} key={index} productPrice={productPrice}/>;
        },this)}

        <div className="row">
          <div className="small-12 columns">
            <button type="button" onClick = {this.onAddProductPrice} className="button tiny">Add Additional Price</button>
            <hr />
          </div>
        </div>
      </div>
    )
  }
});

var ProductPriceWrapperForm = createReactClass({
  render: function() {
    var inputPartial;
    if (this.props.productPrice.destroy == 1) {
      inputPartial = <DestroyProductPriceForm index={this.props.index} productPrice={this.props.productPrice} />;
    } else {
      inputPartial = <ProductPriceForm index={this.props.index} onRemoveProductPrice={this.props.onRemoveProductPrice} productPrice={this.props.productPrice} />;
    }
    return (
      <div>
        {inputPartial}
      </div>
    )
  }
});

var DestroyProductPriceForm = createReactClass({
  render: function () {
    var inputName = "product[prices_attributes][" + this.props.index + "]";
    return (
        <div>
          <input type="hidden" name={inputName + "[amount]"} value={this.props.productPrice.amount} />
          <input type="hidden" name={inputName + "[county]"} value={this.props.productPrice.county} />
          <input type="hidden" name={inputName + "[id]"} value={this.props.productPrice.id} />
          <input type="hidden" name={inputName + "[_destroy]"} value={1} />
        </div>
    )
  }
});

var ProductPriceForm = createReactClass({
  onRemoveProductPrice: function (evt) {
    this.props.onRemoveProductPrice(evt);
  },

  render: function () {
    var inputName = "product[prices_attributes][" + this.props.index + "]";
    var trashStyle = {
      color: '#f04124',
      fontSize: '20px'
    };
    return (
      <div className='row'>
        <div className="medium-6 columns">
          <label>Price</label>
          <input type="text" name={inputName + "[amount]"} defaultValue={this.props.productPrice.amount} />
        </div>
        <div className="medium-6 columns">
          <div className="row collapse">
            <label>Territory</label>
            <div className="small-10 columns">
              <select name={inputName + "[county]"} defaultValue={this.props.productPrice.county} >
                <option value="">None Selected</option>
                <option value="San Francisco">San Francisco</option>
                <option value="Alameda">Alameda</option>
                <option value="Marin">Marin</option>
                <option value="Santa Clara">Santa Clara</option>
                <option value="San Mateo">San Mateo</option>
                <option value="Sonoma">Sonoma</option>
              </select>
            </div>
            <div className="small-2 columns">
              <span className="postfix">
                <a href="#" style={trashStyle} onClick={this.onRemoveProductPrice}><i className="fi-trash"></i></a>
              </span>
            </div>
          </div>
        </div>
        <input type="hidden" name={inputName + "[id]"} value={this.props.productPrice.id} />
        <input type="hidden" name={inputName + "[_destroy]"} value={0} />
      </div>
    )
  }
});

export default ProductForm;

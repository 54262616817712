import React, { Component } from 'react'
import Select from 'react-virtualized-select'

const FormikSelect = ({ name, placeholder, value, options, multi, touched, error, disabled, onChange, onBlur, onKeyDown }) => {

  const handleChange = value => {
    onChange && onChange(name, value)
  }

  const handleBlur = () => {
    onBlur && onBlur(name, true)
  }

  const handleKeyDown = (e) => {
    onKeyDown && onKeyDown(e, name, value)
  }

  return (
    <Select
      multi={multi}
      placeholder={placeholder}
      options={options}
      onChange={handleChange}
      onBlur={handleBlur}
      onInputKeyDown={handleKeyDown}
      value={value}
      disabled={disabled}
    />
  )
}

export default FormikSelect

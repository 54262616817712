import React, { Component } from 'react'
import $ from 'jquery'
import moment from 'moment'
import { connect } from 'react-redux'
import { parseParams } from '../../helpers/parseParams.js'

import CommentsDashboard from '../../components/comments/CommentsDashboard.jsx'
import AccountDetailComponent from './components/AccountDetailComponent.jsx'
import AccountContactsComponent from './components/AccountContactsComponent.jsx'
import OrdersTableComponent from './components/OrdersTableComponent.jsx'
import AccountVisitsTableComponent from './components/AccountVisitsTableComponent.jsx'
import Activity from '../../components/activityFeed/Activity.jsx'

import { fetchAccounts, fetchAccount } from '../../redux/actions/accounts.js'
import { fetchActivities } from '../../redux/actions/activities.js'
import { fetchContactTypes } from '../../redux/actions/contactTypes.js'
import { fetchAccountOrders } from '../../redux/actions/orders.js'
import { fetchSkus } from '../../redux/actions/skus.js'
import { fetchUsers, fetchCurrentUser } from '../../redux/actions/users.js'
import { fetchVisits }  from '../../redux/actions/visits.js'
import { fetchDistributors } from '../../redux/actions/distributors.js';

class AccountShowView extends Component {
  constructor(props) {
    super(props)

    const { match } = this.props
    this.state = { accountId: match.params.id }

    this.fetchAccountDetails = this.fetchAccountDetails.bind(this)
  }

  fetchAccountDetails() {
    const { accountId } = this.state
    const { dispatch }  = this.props

    dispatch(fetchCurrentUser())
    dispatch(fetchAccounts('quick'))
    dispatch(fetchAccount(accountId))
    dispatch(fetchAccountOrders({ accountId }))
    dispatch(fetchContactTypes())
    dispatch(fetchUsers())
    dispatch(fetchSkus())
    dispatch(fetchDistributors())
    dispatch(fetchVisits({ offset: 0, viewContext: 'index', account_ids: [accountId] }))
    dispatch(fetchActivities({ accountId }))
  }

  componentDidMount() {
    this.fetchAccountDetails()

    $('#dashboard-tabs, #dashboard-side-tabs').on('toggled', function (event, tab, old) {
      const allTabs = $(tab).parent().children().get()
      allTabs.forEach(child => {
        $(child).removeClass('active')
        $(child).find('.tinyheader-selected').removeClass('tinyheader-selected').addClass('tinyheader')
      })
      const selected = $(tab).find('.tinyheader')
      $(selected).removeClass('tinyheader').addClass('tinyheader-selected').blur()
    })

    const params = parseParams(location.search, { 'focusOn': { parseAs: 'int' } })
    const focusOnComment = params['focusOn']

    if (focusOnComment) {
      this.setState({ focusOnComment })
    }
  }

  sortingFn(a, b) {
    return moment(a.created_at) > moment(b.created_at) ? 1 : 0
  }

  prepareActivities(activities) {
    return activities.map((activity) => {
      const { owner_type, owner_id } = activity

      const resourceName = owner_type.toLowerCase() + 's'
      const owners = this.props[resourceName]
      const owner = owners && owners.find(resource => resource.id === owner_id) || {}

      return <Activity key={activity.id} activity={activity} owner={owner}/>
    })
  }

  render() {
    const { accountId, focusOnComment } = this.state
    const { account, orders, visits, activities } = this.props
    const salesperson = account.salesperson || {}
    const primaryContactId = (accountId && account && account.name && account.account_contacts.find(ac => ac.primary === true))
    const primaryContact = primaryContactId && account.contacts && account.contacts.find(contact => contact.id === primaryContactId.contact_id) || {}
    const otherContacts  = primaryContactId && account.contacts && account.contacts.filter(contact => contact.id != primaryContactId.contact_id) || []
    const setDisplay = (prop) => (prop === undefined || prop === null) ? "none" : ""
    const preparedActivities = this.prepareActivities(activities)

    return (
      <div>
        <div className="row fullWidth">
          <div className="medium-8 columns">
            <p style={{marginBottom: "0px", marginTop: "5px"}} className="tinyheader">account</p>
            <h1>{account.name}</h1>
            <br />
          </div>
          <div className="medium-4 columns">
            <p style={{marginBottom: "0px", marginTop: "5px"}} className="tinyheader">account type</p>
            <h1>{account.account_type && account.account_type.name}</h1>
            <br />
          </div>
        </div>
        <div className="row fullWidth">
          <div className="medium-2 columns">
            <p className="tinyheader">sales rep</p>
            <img style={{display: "block", marginLeft: "auto", marginBottom: "8px", marginRight: "auto", width: "65%"}} className="avatar-circle" src={salesperson.avatar_path} />
            <a href="#" style={{marginBottom: "5px"}} className="button tiny full-width-button">{[salesperson.first_name, salesperson.last_name].join(' ')}</a>
            <a href={"tel:"+salesperson.phone} style={{marginBottom: "5px"}} className="button tiny full-width-button">{salesperson.phone}</a>
            <a href={"mailto:"+salesperson.email} className="button tiny full-width-button">{salesperson.email}</a>
            <br />
            <p className="tinyheader">Terms</p>
            <a href="#" style={{marginBottom: "5px"}} className="button tiny full-width-button">{account.terms}</a>
            <br />
            <br />
            <p className="tinyheader">address</p>
            <a href="#" className="button tiny full-width-button">{account.one_line_address}</a>
            <br />
            <div>
              <p className="tinyheader">actions</p>
              <div style={{marginBottom: "15px"}}>
                <a href={Routes.new_order_path({accountId: accountId})} target={"_blank"} className="button tiny full-width-button">Create New Order</a>
              </div>
              <div style={{marginBottom: "15px"}}>
                <a href={encodeURI("https://docs.google.com/forms/d/e/1FAIpQLScp3kkY6-Ts_VtIauQk6bLIQmPaLdG-6QcdemoQ03UtLNhHEw/viewform?usp=pp_url&entry.400231278=" + account.name + "&entry.1409547111=" + account.id)} style={{marginBottom: "5px"}} target={"_blank"} className="button tiny full-width-button">Merchandise</a>
              </div>
              <div style={{marginBottom: "15px"}}>
                <a href={encodeURI("https://docs.google.com/forms/d/e/1FAIpQLScfFN8ao6DDK98sNYponSEWK6Hg63ZV1VvneuO92eeOqsICeA/viewform?usp=pp_url&entry.768270545=" + account.name + "&entry.1705750229=" + account.id)} style={{marginBottom: "5px"}} target={"_blank"} className="button tiny full-width-button">Initiate Return</a>
              </div>
              <div style={{marginBottom: "15px"}}>
                <a href={"/accounts/" + account.random_id + "/portal"} style={{marginBottom: "5px"}} target={"_blank"} className="button tiny full-width-button">Account Portal</a>
              </div>
              <div style={{marginBottom: "15px"}}>
                <a href={"/accounts/" + account.id + "/pay"} style={{marginBottom: "5px"}} target={"_blank"} className="button tiny full-width-button">Pay Past Due</a>
              </div>
              <div style={{marginBottom: "15px"}}>
                <a href={"/accounts/" + account.id + "/pay?full"} style={{marginBottom: "5px"}} target={"_blank"} className="button tiny full-width-button">Pay Total Due</a>
              </div>
              <div style={{marginBottom: "15px"}}>
                <a href={"/accounts/" + account.id + "/email_statement"} style={{marginBottom: "5px"}} target={"_blank"} className="button tiny full-width-button">Email Statement To Self</a>
              </div>
            </div>
            {primaryContactId &&
              <div>
                <p className="tinyheader">primary contact</p>
                <div style={{marginBottom: "15px"}}>
                  <a href="#" style={{marginBottom: "5px"}} className="button tiny full-width-button">{[primaryContact.first_name, primaryContact.last_name].join(' ')}<br />({primaryContact.contactType && primaryContact.contactType.name})</a>
                  <a href={"tel:"+primaryContact.phone} style={{marginBottom: "5px", display: setDisplay(primaryContact.phone)}} className="button tiny full-width-button">{primaryContact.phone || '-'}</a>
                  <a href={"mailto:"+primaryContact.email} style={{marginBottom: "5px", display: setDisplay(primaryContact.email)}} className="button tiny full-width-button">{primaryContact.email || '-'}</a>
                </div>
              </div>
            }
            {otherContacts.length > 0 && <p className="tinyheader">others contacts</p>}
            {otherContacts.length > 0 &&
              otherContacts.map((contact, i) => {
                return (
                  <div key={contact.id} style={{marginBottom: "15px"}}>
                    <a href="#" style={{marginBottom: "5px"}} className="button tiny full-width-button">{[contact.first_name, contact.last_name].join(' ')}<br />({contact.contactType && contact.contactType.name})</a>
                    <a href={"tel:"+contact.phone} style={{marginBottom: "5px", display: setDisplay(contact.phone)}} className="button tiny full-width-button">{contact.phone || '-'}</a>
                    <a href={"mailto:"+contact.email} style={{marginBottom: "5px", display: setDisplay(contact.email)}}className="button tiny full-width-button">{contact.email || '-'}</a>
                  </div>
                )
              })
            }
          </div>
          <div className="medium-6 columns">
            <div className="row">
              <div className="row">
                <div className="small-10 columns">
                  <ul className="tabs inline-list" data-tab id="dashboard-tabs">
                    <li className="tabs-title active"><a href="#comments" className="tinyheader-selected">comments</a></li>
                    <li className="tabs-title"><a href="#activities" className="tinyheader">activities</a></li>
                    <li className="tabs-title"><a href="#details" className="tinyheader">details</a></li>
                    <li className="tabs-title"><a href="#contacts" className="tinyheader">contacts</a></li>
                  </ul>
                </div>
                <div className="small-2 columns">
                  <a className="tinyheader" href={"/accounts/" + account.id + "/statement.pdf"} target="_blank">statement pdf</a>
                </div>
              </div>
              <br />
              <div className="tabs-content" data-tabs-content="dashboard-tabs">
                <div className="content active" id="comments">
                  <CommentsDashboard commentableType='account' commentableId={accountId} focusOn={focusOnComment} withPinned={true} />
                </div>
                <div className="content" id="activities">
                  <div className="panel">
                    {preparedActivities}
                  </div>
                </div>
                <div className="content" id="details">
                  {accountId && account.name && <AccountDetailComponent account={account} accountId={accountId} refetchDataCallback={this.fetchAccountDetails} />}
                </div>
                <div className="content" id="contacts">
                  {accountId && account.contacts &&
                    <AccountContactsComponent
                      account={account}
                      refetchDataCallback={this.fetchAccountDetails}
                    />
                  }
                </div>
              </div>
            </div>
          </div>
          <div className="medium-4 columns">
            <div className="row">
              <div className="row">
                <div className="small-12 columns">
                  <ul className="tabs inline-list" data-tab id="dashboard-side-tabs">
                    <li className="tabs-title active"><a href="#orders" className="tinyheader-selected">orders</a></li>
                    <li className="tabs-title"><a href="#visits" className="tinyheader">visits</a></li>
                    <li className="tabs-title"><a href="#deals" className="tinyheader">deals</a></li>
                    <li className="tabs-title"><a href="#metrics" className="tinyheader">metrics</a></li>
                  </ul>
                </div>
              </div>
              <div className="tabs-content" data-tabs-content="dashboard-side-tabs">
                <div className="content active" id="orders">
                  { orders.length > 0
                    ? <OrdersTableComponent orders={orders} defaultPageSize={orders.length} />
                    : <div className="small-12 columns"> No Orders </div>
                  }
                </div>
                <div className="content" id="visits">
                  <div className="small-12 columns">
                  { visits.length > 0
                    ? <AccountVisitsTableComponent visits={visits} defaultPageSize={visits.length} />
                    : <div className="small-12 columns"> No Visits </div>
                  }
                  </div>
                </div>
                <div className="content" id="deals">
                  <div className="small-12 columns">
                    Deals
                  </div>
                </div>
                <div className="content" id="metrics">
                  {/* <AccountMetricsComponent accountId={accountId} skus={this.props.skus} /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  const currentUser = state.currentUser.user
  const accounts = state.kpi.accounts
  const { account, activities, orders, salesperson } = state.accounts.current
  const { visits } = state.visits.all || []
  const { contactTypes, users, skus } = state.kpi

  account.contacts && contactTypes && account.contacts.forEach(contact => {
    const ac = account.account_contacts.find(ac => contact.id === ac.contact_id)
    const contactType = contactTypes.find(ct => ct.id === ac.contact_type_id) || { name: '-' }
    ac['contactType'] = contactType
    contact['contactType'] = contactType
  })

  return {
    account,
    activities,
    orders,
    visits,
    contactTypes,
    salesperson,
    users,
    skus,
    accounts,
    currentUser
  }
}

const connectedAccountShowView = connect(mapStateToProps,)(AccountShowView);
export default connectedAccountShowView;

import $ from 'jquery'
import Routes from '../../routes.js.erb'

function fetchTagsRequest() {
  return {
    type: `FETCH_TAGS_REQUEST`,
  }
}

function fetchTagsSuccess(tagsType, response) {
  return {
    type: 'FETCH_TAGS_SUCCESS',
    tagsType,
    payload: response,
  }
}

function fetchTagsFailure(response) {
  return {
    type: 'FETCH_TAGS_FAILURE',
    error: response.error,
  }
}

export function fetchTags(tagsType='ALL_TAGS') {
  const url = Routes.tags_path({ type: tagsType, format: 'json' })

  return (dispatch) => {
    dispatch(fetchTagsRequest());

    $.getJSON(url)
      .then(json => { dispatch(fetchTagsSuccess(tagsType, json)) })
      .catch(response => dispatch(fetchTagsFailure(response)));
  }
}


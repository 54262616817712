import { connect } from 'react-redux'
import OrdersEditView from './OrdersEditView.jsx'


class OrdersBookView extends OrdersEditView {
  constructor(props, context) {
    const localProps = {
      ...props,
      initialState: {
        status: 'booked'
      }
    }

    super(localProps, context)
  }
}

const mapStateToProps = (state) => {
  return {
    page: 'book',
    order: state.orders.current.order,
    currentUser: state.currentUser.user,
    skus: state.skus.all || [],
    serverErrors: state.errors.orders.create || {}
  }
}

const connectedOrdersBookView = connect(mapStateToProps,)(OrdersBookView)
export default connectedOrdersBookView
import React, {Component} from "react"
import StandardFilter from "./StandardFilter"
import IdsFilter from "./IdsFilter"
import PurchaseOrderNumberFilter from "./PurchaseOrderNumberFilter"

class OrdersIndexFilters extends Component {
  constructor(props) {
    super(props)
  }

  renderCurrentViewFilter = () => {
    const { currentParams, currentView, dispatch, onSubmit } = this.props

    switch (currentView) {
      case 'standard':
        return <StandardFilter
          currentParams={currentParams}
          dispatch={dispatch}
          onSubmit={onSubmit}
        />
      case 'ids':
        return <IdsFilter
          currentParams={currentParams}
          onSubmit={onSubmit}
        />
      case 'purchaseOrderNumbers':
        return <PurchaseOrderNumberFilter
          currentParams={currentParams}
          onSubmit={onSubmit}
        />
    }
  }

  render() {
    return(
      <div className='OrdersIndexFilters'>
        {this.renderCurrentViewFilter()}
      </div>
    )
  }
}

export default OrdersIndexFilters
import ReactTable from 'react-table'

const IndexTable = ({ data, columns, limit }) => {
  return (
    <div className='row fullWidth'>
      <div className='medium-12 columns'>
        <ReactTable
          key={columns.length}
          className='-striped -highlight'
          data={data}
          columns={columns}
          showPagination={false}
          defaultPageSize={limit}
        />
      </div>
    </div>
  )
}

export default IndexTable
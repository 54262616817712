import React from 'react'
import OrderTimeWindow from './OrderTimeWindow.jsx'

const SuggestedDeliveryTimeWindowsWrapper = ({ suggestedTimeWindows })  => {
  return (
    <div>
      <div className='row'>
        <div className='small-12 columns'>
          <div className='row'>
            <div className='small-12 columns'>
              <h4 className='subheader'>Suggested Delivery Time Windows</h4>
            </div>
          </div>
          {suggestedTimeWindows.map((timeWindow, _index) => {
            const { id, date, startTime, endTime } = timeWindow
            return (
              <OrderTimeWindow
                key={id}
                id={id}
                date={date}
                startTime={startTime}
                endTime={endTime}
                disabled={true}
              />
            )
          })}
        </div>
      </div>
      <hr />
    </div>
  )
}

export default SuggestedDeliveryTimeWindowsWrapper
const initialState = {
  current: { 
    address: null,
  },
  all: {
    addresses: [],
    offset: 0,
    totalCount: 0
  },
  cities: []
}

export function addresses(state = initialState, action) {
  const { payload } = action
  const { current, all } = state

  switch (action.type) {
    case 'FETCH_ADDRESSES_REQUEST':
      return { ...state, all: { ...all, loading: true } };

    case 'FETCH_ADDRESSES_SUCCESS':
      const { addresses, count, offset } = payload
      return { ...state, all: { addresses, totalCount: count, offset, loading: false } };

    case 'CLEAR_ADDRESSES_OFFSET':
      return { ...state, all: { ...all, offset: 0, totalCount: 0 } };

    case 'FETCH_CITIES_SUCCESS':
      return { ...state, cities: payload }

    default:
      return state;
  }
}

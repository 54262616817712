import React, { Component } from 'react'
import $ from 'jquery'
import { truncateWithEllipses } from '../../helpers/truncateText.js'
import { lowerCase } from 'lodash'
import { getText } from '../../helpers/notifications/getText'
import Spinner from 'react-md-spinner'
import { unionBy } from 'lodash'

export default class UserNotificationsComponent extends Component {
  constructor(props) {
    super(props)

    const { userId, userName } = props

    this.state = { userId, userName, count: 0, showAll: false, unreadNotifications: [], notifications: [], hasMore: true, isLoading: false, error: null, offset: 0, limit: 5 }
  }

  onLoadMore = () => {
    const {
      loadNotifications,
      state: {
        error,
        isLoading,
        hasMore,
      },
    } = this;

    if (error || isLoading || !hasMore) {
      return
    }

    loadNotifications()
  }

  loadNotifications = () => {
    const { offset, limit, userId, showAll } = this.state
    const params = { offset, limit, recipient_ids: [userId], read_filter: showAll ? null : false }

    this.setState({ isLoading: true }, () => {
      const self = this
      $.ajax({
        url: Routes.notifications_path({format: 'json'}),
        data: params,
        dataType: 'json',
        success: function(response) {
          const { notifications } = response
          self.setState({
            hasMore: notifications.length > 0,
            offset: offset + limit,
            notifications: unionBy(self.state.notifications, notifications, 'id'),
            isLoading: false,
          })
        }
      })
    })
  }


  poll = () => {
    const { limit, userId, showAll, offset } = this.state
    const params = { offset: 0, limit: offset + limit, recipient_ids: [userId], read_filter: showAll ? null : false }

    const self = this
    $.ajax({
      url:  Routes.notifications_path(),
      data: params,
      dataType: 'json',
      success: function(response) {
        const { notifications, count } = response
        self.setState({ notifications, count })
      },
      complete: () => self.pollingFn = setTimeout(() => self.poll(params), 2500)
    })
  }


  componentDidMount() {
    const self = this

    $('#notifications > .info').on('click', function() {
      $('#notificationsContainer').fadeToggle(300)
      $('#notificationsContainer').focus()
      return false
    })

    $('#notificationsContainer').on('blur', function() {
      $('#notificationsContainer').fadeToggle(300)
    })

    $('#notificationsContainer').on('scroll', function() {
      if($(this).scrollTop() + $(this).innerHeight() >= $(this)[0].scrollHeight) {
        self.onLoadMore()
      }
    })

   this.pollingFn = setTimeout(() => this.poll(), 2500)
  }

  handleNotificationClick(notification) {
    const url = notification.connected_entity.url + '?focusOn=' + notification.notifiable.id
    // come back and fix with redux
    window.location.replace(url)
  }

  toggleRead = (id) => {
    const self = this
    const notificationStatus = self.state.notifications.find(n => n.id === id).read_at
    const toggledReadAction = notificationStatus === null ? '/mark_as_read' : '/mark_as_unread'
    const url = Routes.notification(id, { toggledReadAction })

    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      success: function(response) {
        const { notification } = response
        const { id } = notification
        const { notifications } = self.state
        self.setState({ notifications: notifications.map(n => n.id == id ? { ...notification } : n) })
      }
    })
  }

  showAllVsUnreadToggle = () => {
    const { showAll } = this.state
    this.setState({ showAll: !showAll, notifications: [], hasMore: true, offset: 0 }, () => this.onLoadMore())
  }

  render() {
    const { userName, showAll, notifications, count, isLoading } = this.state
    const notificationsList = notifications.map(n => {
      const { id, actor, action, reason, notifiable, recipient, connected_entity, read_at } = n
      const text = getText(reason, action, actor.name, recipient.name, lowerCase(notifiable.type), connected_entity.name, truncateWithEllipses(notifiable.summary, 20))
      return (
        <div className="notification row" key={id}>
          <div className="text small-10 columns" onClick={(e) => this.handleNotificationClick(n)}>{text}</div>
          <div className="toggleSwitch small-2 columns">
            <span onClick={e => this.toggleRead(id)} className="toggle" className="switch round tiny right">
              <input type="checkbox" checked={!!read_at} />
              <label></label>
            </span>
          </div>
        </div>
      )
    })

    return (
      <div id="notifications">
        <a className="info">
          <span className="userName">{userName}</span>
          <span id="notifications-count">{count}</span>
        </a>
        <div id="notificationsContainer" className="hidden" tabIndex={"-1"}>
          <div className="notificationsTitle">
            Notifications
            <span onClick={this.showAllVsUnreadToggle} className="toggle" className="switch round tiny right">
              <input type="checkbox" checked={!!showAll} />
              <label></label>
            </span>
          </div>
          <div className="notificationsBody">
            { notificationsList.length > 0
              ? notificationsList
              : <div className="noNotifications">No Notifications</div>
            }
          </div>
          <div className="notificationsLoader">
          { isLoading ? <Spinner /> : null }
          </div>
          <div className="notificationsFooter"><a href="/notifications">See All</a></div>
        </div>
      </div>
    )

  }
}


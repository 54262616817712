import React, { Component } from 'react';
import moment from 'moment';
import { base, short_weekday } from '../../../timeFormats'

export default class DateInput extends Component {
  handleChange = (evt) => {
    const actual = evt.target.value
    const dateValue = moment(actual)
    this.props.onChange(dateValue.format( base))
  }

  render() {
    let dateInput;

    if (this.props.readOnly === true) {
      dateInput = <input readOnly type="date" onChange={this.handleChange} name={this.props.name} value={this.props.value} />;
    } else {
      dateInput = <input type="date" onChange={this.handleChange} name={this.props.name} value={this.props.value} />;
    }

    const style = {
      zIndex: -10
    }

    return (
      <div className="row">
        <div className="small-12 columns">
          <div className="row collapse">
            <label>{this.props.description}</label>
            <div className="small-6 columns">
              {dateInput}
            </div>
            <div className="small-6 columns">
              <span className="postfix" style={style}>
                {moment(this.props.value).format(short_weekday)}
              </span>
            </div>
          </div>
        </div>
      </div>
    );
  }
}


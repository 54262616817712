import React from 'react'
import { withFormik, Form, Field } from 'formik'
import Yup from 'yup'

import GooglePlaceSelect from '../../../components/select/GooglePlaceSelect.jsx';
import AccountTypeSelect from '../../../components/select/AccountTypeSelect.jsx'
import UserSelect from '../../../components/select/UserSelect.jsx'
import WarehouseSelect from '../../../components/select/WarehouseSelect.jsx'
import DistributorSelect from '../../../components/select/DistributorSelect.jsx'
import { createAccount, updateAccount } from '../../../redux/actions/accounts.js'

const AccountFormComponent = ({ values, errors, touched, isSubmitting, setFieldValue, setFieldTouched }) => {
  const onChange = (name, valObj) => {
    setFieldValue(name, valObj.value)
  }

  return (
  <Form>
    <div>
      <div className="row">
        <div className="medium-6 columns">
          <label>Account Name
            <Field type="text" name="name" />
            {errors.name && touched.name && <small className="error">{errors.name}</small>}
          </label>
        </div>
        <div className="medium-6 columns">
          <label>AccountType
            <AccountTypeSelect
              name="accountTypeId"
              multi={false}
              value={values.accountTypeId}
              onChange={onChange}
              onBlur={setFieldTouched}
              error={errors.accountTypeId}
              touched={touched.accountTypeId}
            />
            {errors.accountTypeId && touched.accountTypeId && <small className="error">{errors.accountTypeId}</small>}
          </label>
        </div>
      </div>

      <div className="row">
        <div className="medium-12 columns">
          <label>One Line Address
            <GooglePlaceSelect
              name={"one_line_address"}
              value={values.one_line_address}
              onChange={setFieldValue}
              lengthThreshold={3}
              onSelect={(address) => setFieldValue("address", address)}
              onBlur={setFieldTouched}
              error={errors.one_line_address}
              touched={touched.one_line_address}
            />
            {errors._address && errors._address && touched._address && <small className="error">{errors._address}</small>}
          </label>
        </div>
      </div>

      <div className="row">
        <div className="medium-6 columns">
          <label>Line 1 (Street Address)
            <Field name="address.line1" readOnly type="text" />
            {errors.address && errors.address.line1 && touched.address && touched.address.line1 && <small className="error">{errors.address.line1}</small>}
          </label>
        </div>
        <div className="medium-6 columns">
          <label>Line 2 (Apt / Floor / Suite )
            <Field name="address.line2" type="text" />
          </label>
        </div>
      </div>
      <div className="row">
        <div className="medium-3 columns">
          <label>City
            <Field name="address.city" readOnly type="text" />
            {errors.address && errors.address.city && touched.address && touched.address.city && <small className="error">{errors.address.city}</small>}
          </label>
        </div>
        <div className="medium-3 columns">
          <label>State
            <Field name="address.state" readOnly type="text" />
            {errors.address && errors.address.state && touched.address && touched.address.state && <small className="error">{errors.address.state}</small>}
          </label>
        </div>
        <div className="medium-3 columns">
          <label>Zip
            <Field name="address.zip" readOnly type="text" />
            {errors.address && errors.address.zip && touched.address && touched.address.zip && <small className="error">{errors.address.zip}</small>}
          </label>
        </div>
        <div className="medium-3 columns">
          <label>Country
            <Field name="address.country" readOnly type="text" />
          </label>
        </div>
      </div>
      <div className="row">
        <div className="medium-6 columns">
          <label>Neighborhood
            <Field name="address.neighborhood" readOnly type="text" />
          </label>
        </div>
        <div className="medium-6 columns">
          <label>County
            <Field name="address.county" readOnly type="text" />
          </label>
        </div>
      </div>

      <div className="row">
        <div className="medium-6 columns">
          <label>Opener
            <UserSelect
              name="openerId"
              multi={false}
              value={values.openerId}
              onChange={onChange}
              onBlur={setFieldTouched}
              error={errors.openerId}
              touched={touched.openerId}
            />
            {errors.openerId && touched.openerId && <small className="error">{errors.openerId}</small>}
          </label>
        </div>
        <div className="medium-6 columns">
          <label>Salesperson
            <UserSelect
              name="salespersonId"
              multi={false}
              value={values.salespersonId}
              onChange={onChange}
              onBlur={setFieldTouched}
              error={errors.salespersonId}
              touched={touched.salespersonId}
            />
            {errors.salespersonId && touched.salespersonId && <small className="error">{errors.salespersonId}</small>}
          </label>
        </div>
      </div>
      <br />

      <div className="row">
        <div className="medium-6 columns">
          <label>Distributor
            <DistributorSelect
              name="distributorId"
              multi={false}
              value={values.distributorId}
              onChange={onChange}
              onBlur={setFieldTouched}
              error={errors.distributorId}
              touched={touched.distributorId}
            />
            {errors.distributorId && touched.distributorId && <small className="error">{errors.distributorId}</small>}
          </label>
        </div>
        <div className="medium-6 columns">
          <label>Shipping Warehouse
            <WarehouseSelect
              name="warehouseId"
              multi={false}
              value={values.warehouseId}
              onChange={onChange}
              onBlur={setFieldTouched}
              error={errors.warehouseId}
              touched={touched.warehouseId}
            />
            {errors.warehouseId && touched.warehouseId && <small className="error">{errors.warehouseId}</small>}
          </label>
        </div>
      </div>
      <br />

      <div className="row">
        <div className="medium-4 columns">
          <label>ABC License Type
            <Field type="text" name="abcType" />
          </label>
        </div>
        <div className="medium-4 columns">
          <label>ABC License Number
            <Field type="text" name="abc" />
          </label>
        </div>
        <div className="medium-4 columns">
          <label>BOE Number
            <Field type="text" name="boe" />
          </label>
        </div>
      </div>
      <div className="row">
        <div className="small-12 columns">
          <label>Memo
            <Field component="textarea" rows="5" name="memo" />
          </label>
        </div>
      </div>

      <div className="row">
        <div className="small-12 columns">
          <button disabled={isSubmitting}>Submit</button>
        </div>
      </div>
    </div>
  </Form>
  )
}

export default withFormik({
  mapPropsToValues(props) {
    const { account } = props
    const { name, account_type, salesperson, opener, distributor, warehouse, address, one_line_address } = account

    const accountValues = {
      name: name || '',
      one_line_address: one_line_address || '',
      address: {
        line1: address && address.line1 || '',
        line2: address && address.line2 || '',
        city: address && address.city || '',
        state: address && address.state || '',
        zip: address && address.zip || '',
        country: address && address.country || '',
        neighborhood: address && address.neighborhood || '',
        county: address && address.county || '',
      },
      accountTypeId: account_type && account_type.id || '',
      openerId: opener && opener.id || '',
      salespersonId: salesperson && salesperson.id || '',
      distributorId: distributor && distributor.id || '',
      warehouseId: warehouse && warehouse.id || '',
      abcType: account && account.abc_type || '',
      abc: account && account.abc || '',
      boe: account && account.boe || '',
      memo: account && account.memo || ''
    }

    return accountValues
  },
  validationSchema: Yup.object().shape({
    name: Yup.string().required('Account Name is required'),
    one_line_address: Yup.string().required('One Line Address is required'),
    address: Yup.object().shape({
      line1: Yup.string().required("Line 1 is required"),
      line2: Yup.string(),
      zip: Yup.string().required("Zip is required"),
      city: Yup.string().required("City is required"),
      state: Yup.string().required("State is required")
    }),
    openerId: Yup.number().required('Account Opener is required'),
    salespersonId: Yup.number().required('Salesperson is required'),
    distributorId: Yup.number().required('Distributor is required'),
    accountTypeId: Yup.number().required('Account Type is required'),
    memo: Yup.string(),
  }),
  handleSubmit(values, { resetForm, setErrors, setSubmitting, props }) {
    const { address } = values
    const { id } = props
    if (id) {
      props.dispatch(updateAccount(id, { ...values, address: { id: props.account.address.id, ...address } } ))
    } else {
      props.dispatch(createAccount(values))
    }
    setTimeout(() => setSubmitting(false), 1000)
  }
})(AccountFormComponent)

import $ from 'jquery'
import Routes from '../../routes.js.erb'
import { snakeCaseHash } from "../../helpers/snakeCaseHash"

export function clearNotificationsOffset() {
  return {
    type: 'CLEAR_NOTIFICATIONS_OFFSET'
  }
}

function fetchNotificationsRequest() {
  return {
    type: 'FETCH_NOTIFICATIONS_REQUEST',
  }
}

function fetchNotificationsSuccess(payload) {
  return {
    type: 'FETCH_NOTIFICATIONS_SUCCESS',
    payload
  }
}

export function fetchNotifications(params) {
  const url = Routes.notifications_path({format: 'json'})
  const data = snakeCaseHash(params)

  return (dispatch) => {
    dispatch(fetchNotificationsRequest());

    $.getJSON(url, data)
      .then(payload => { dispatch(fetchNotificationsSuccess(payload)) })
      .catch(response => console.log(response));
  }
}
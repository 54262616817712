import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'
import 'moment-timezone'
import {dayNumbersOptions} from "../../../constants"
import {removeNestedRecord} from "../../../helpers/removeNestedRecord"
import {snakeCaseHash} from "../../../helpers/snakeCaseHash"
import AccountTimeWindow from "./AccountTimeWindow"
import {fetchAccountTimeWindows} from "../../../redux/actions/accounts"


class AccountTimeWindowsComponent extends Component {
  constructor(props) {
    super(props)

    const { timeWindows } = props

    this.state = {
      timeWindows: timeWindows.map(timeWindow => {
        return {...timeWindow, _id: timeWindow.id, _destroy: 0}
      }),
      timeWindowsEditMode: false,
      submitting: false,
      error: '',
    }

    this.onChange = this.onChange.bind(this)
    this.toggleTimeWindowsEditMode = this.toggleTimeWindowsEditMode.bind(this)
    this.onRemoveWindow = this.onRemoveWindow.bind(this)
    this.onAddWindow = this.onAddWindow.bind(this)
  }

  onAddWindow() {
    if (!this.state.timeWindowsEditMode) return

    const { timeWindows } = this.state
    const newTimeWindow = {
      _id: (timeWindows.length + 1),
      day_of_week: 1,
      start:  '06:00',
      finish: '09:00',
      _destroy: 0
    }

    this.setState({
      timeWindows: timeWindows.concat(newTimeWindow)
    },() => {
      // console.log('onAddWindow => this.state.timeWindows', this.state.timeWindows)
    })
  }

  onRemoveWindow(id) {
    if (!this.state.timeWindowsEditMode) return

    const { timeWindows } = this.state
    const timeWindowsWithRemoved = timeWindows.map((tw) => {
      if (tw._id != id) {
        return tw
      } else {
        return removeNestedRecord(tw)
      }
    }).filter(el => el != null)

    this.setState({
      timeWindows: timeWindowsWithRemoved
    },() => {
      // console.log('onRemoveWindow => this.state.timeWindows', this.state.timeWindows)
    })
  }

  toggleTimeWindowsEditMode() {
    const { timeWindowsEditMode } = this.state

    this.setState({
      timeWindowsEditMode: !timeWindowsEditMode
    },() => {
      // console.log('timeWindowsEditMode', timeWindowsEditMode)
    })
  }

  onSubmit() {
    const that = this
    const { accountId, dispatch } = this.props
    const { timeWindows } = this.state

    const account = snakeCaseHash({
      timeWindowsAttributes: timeWindows.map(timeWindow => {
        const {id, day_of_week, start, finish, _destroy} = timeWindow

        const momentStart = moment(start, 'HH:mm')
        const momentFinish = moment(finish, 'HH:mm')
        const startOfToday = moment().startOf('day')

        const start_seconds_since_midnight = momentStart.diff(startOfToday, 'seconds')
        const finish_seconds_since_midnight = momentFinish.diff(startOfToday, 'seconds')
        return {
          id,
          day_of_week,
          start_seconds_since_midnight,
          finish_seconds_since_midnight,
          _destroy
        }
      })
    })
    this.setState({ submitting: true })

    $.ajax({
      type: 'PATCH',
      url: Routes.account_path(accountId),
      data: { account },
      dataType: 'json'
    }).then(_response => {
      that.setState({
        submitting: false,
        error: null,
        timeWindowsEditMode: false
      })
      dispatch(fetchAccountTimeWindows(accountId, (payload) => {
        const { account_time_windows } = payload

        that.setState({
          timeWindows: account_time_windows.map(timeWindow => {
            return {...timeWindow, _id: timeWindow.id, _destroy: 0}
          })
        }, () => {
          // console.log('that.state', that.state)
        })
      }))
    }).catch(err => {
      let error = ''
      Object.values(err.responseJSON).forEach(arr => { error += arr.join(', ') })
      that.setState({
        submitting: false, error
      })
    })
  }

  onChange(index, name, val) {
    const { timeWindows } = this.state
    const updatedTimeWindows = timeWindows.map((tw, i) => {
      if (i != index) return tw

      return { ...tw, [name]: val }
    })

    this.setState({ timeWindows: updatedTimeWindows })
  }

  render() {
    const { timeWindows, timeWindowsEditMode, submitting, error } = this.state

    const cursor = timeWindowsEditMode ? 'pointer' : 'not-allowed'
    const trashStyle = {
      color: '#f04124',
      fontSize: '20px',
      cursor: cursor,
    }

    return (
      <div className="medium-12 columns AccountTimeWindowsComponent">
        <p style={{marginBottom: "0px", marginTop: "5px"}} className="tinyheader">Delivery Windows</p>

        {error && <small className="error">{error}</small>}
        <table width="100%">
          <thead>
            <tr>
              <th>Day Of Week</th>
              <th>Start Time</th>
              <th>Finish Time</th>
              <th>Delete</th>
            </tr>
          </thead>
          <tbody>
            {timeWindows.sort((prevDay, nextDay) => {
              return prevDay.day_of_week - nextDay.nextDay
            }).map((timeWindow, index) => {
              if (timeWindow._destroy === 1) return null

              const { _id, id } = timeWindow
              return (
                <AccountTimeWindow
                  key={index}
                  index={index}
                  id={_id || id}
                  editing={timeWindowsEditMode}
                  submitting={submitting}
                  timeWindow={timeWindow}
                  dayNumberOptions={dayNumbersOptions}
                  onChange={this.onChange}
                  onRemoveWindow={this.onRemoveWindow}
                  trashStyle={trashStyle}
                />
              )
            })}
          </tbody>
        </table>
        <div className="row">
          <div className="small-12 columns">
            <button disabled={!timeWindowsEditMode || submitting} type="button" onClick={this.onAddWindow} className="button tiny">
              Add Additional Delivery Window
            </button>
            <button className="tiny right" disabled={!timeWindowsEditMode || submitting} onClick={e => this.onSubmit()}>
              Save Changes
            </button>
            <hr />
          </div>
        </div>
          <div className="right">
            <label htmlFor="timeWindowsEdit" className="tinyheader" style={{float: 'left'}}>
              Edit TimeWindows
            </label>
            <span onClick={this.toggleTimeWindowsEditMode} id="timeWindowsEdit" className="switch round small" style={{float: 'right'}}>
              <input type="radio" checked={timeWindowsEditMode} />
              <label className='switch'></label>
            </span>
          </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    timeWindows: state.accounts.current.account.time_windows || [],
    accountId: state.accounts.current.account.id
  }
}

const connectedAccountTimeWindowsComponent= connect(mapStateToProps,)(AccountTimeWindowsComponent);
export default connectedAccountTimeWindowsComponent;
import React, { Component } from 'react'
import CustomSelect from '../../../components/select/base/CustomSelect.jsx'
import { removeNestedRecord } from "../../../helpers/removeNestedRecord"

class RequestedOrderItemsComponent extends Component {
  constructor(props) {
    super(props)

    this.state = { count: 0 }
  }

  onAddOrderItem = () => {
    const { handleChange, orderItems } = this.props
    const { count } = this.state
    const emptyOrderItem = { _id: count + 1, skuId: null, requestedQuantity: 1 }

    this.setState({ count: count + 1 }, () => {
      handleChange('orderItems', [...orderItems, emptyOrderItem])
    })
  }

  onRemoveOrderItem = (id) => {
    const { handleChange, orderItems } = this.props
    const orderItemsWithRemoved = orderItems.map((record) => {
      if (record._id != id) {
        return record
      } else {
        return removeNestedRecord(record)
      }
    }).filter(el => el != null)

    handleChange('orderItems', orderItemsWithRemoved)
  }

  handleChange = (_id, fieldName, fieldValue, callback) => {
    const {orderItems, handleChange, skus } = this.props

    const orderItemsUpdated = orderItems.map(orderItem => {
      if (orderItem._id != _id) return orderItem
      if (fieldName === 'skuId') {
        const selectedSku = skus.find(el => el.id === fieldValue)
        const { name, price, availableInventory } = selectedSku
        const { _id, requestedQuantity } = orderItem

        const orderItemUpdated = {
          _id,
          name,
          price,
          availableInventory,
          requestedQuantity,
          [fieldName]: fieldValue
        }

        return orderItemUpdated
      } else {
        if (orderItem.skuId) {
          return { ...orderItem, [fieldName]: fieldValue }
        } else {
          return orderItem
        }
      }
    })

    handleChange('orderItems', orderItemsUpdated, callback)
  }

  render() {
    const { skus, orderItems, fetchSkuInfo, canEdit } = this.props

    return(
      <div>
        <div className='row'>
          <div className='small-12 columns'>
            <h4 className='subheader'>Order Items</h4>
          </div>

        </div>
        <div className='row'>
          <div className='small-12 columns'>
            <button type='button' onClick={this.onAddOrderItem} className='button tiny'>Add Additional Item</button>
          </div>
        </div>
        <div className='row'>
          <div className='small-12 columns'>
            {orderItems.map((orderItem, i) => {
              if (orderItem._destroy) return null

              const { _id, id, skuId, price, availableInventory, requestedQuantity } = orderItem
              return (
                <OrderItemComponent
                  key={id || _id}
                  id={id || _id}
                  skuId={skuId}
                  canEdit={canEdit}
                  availableInventory={availableInventory}
                  price={price}
                  requestedQuantity={requestedQuantity}
                  skus={skus}
                  handleChange={this.handleChange}
                  onRemoveOrderItem={this.onRemoveOrderItem}
                  fetchSkuInfo={fetchSkuInfo}
                />
              )
            })}
          </div>
        </div>
      </div>
    )
  }
}

export default RequestedOrderItemsComponent


class OrderItemComponent extends Component {
  constructor(props) {
    super(props)

    this.state = {
      readOnly: true
    }
  }

  onChange = (e, val) => {
    const { id, handleChange, fetchSkuInfo } = this.props
    handleChange(id, 'skuId', val.value, () => {fetchSkuInfo(val.value)})
  }

  togglePriceInput = () => {
    const { canEdit } = this.props
    const { readOnly } = this.state

    if (!canEdit) return

    this.setState({ readOnly: !readOnly })
  }

  render() {
    const { id, skuId, skus, availableInventory, price, requestedQuantity } = this.props
    const { handleChange, onRemoveOrderItem } = this.props
    const { readOnly } = this.state

    const trashStyle = {
      color: '#f04124',
      fontSize: '20px'
    }

    const lockStyle = {
      color: '#f04124',
      fontSize: '20px'
    }

    return (
      <div className='row'>
        <div className='medium-6 columns'>
          <label>Item
            <CustomSelect
              options={skus}
              value={skuId}
              onChange={this.onChange}
            />
          </label>
        </div>
        <div className='medium-2 columns'>
          <label>Available
            <input readOnly type='number' value={availableInventory}/>
          </label>
        </div>
        <div className='medium-2 columns'>
          <div className='row collapse'>
            <label>Price</label>
            <div className='small-10 columns'>
              <input noValidate readOnly={readOnly} value={price}
                     onChange={(e, val) => handleChange(id, 'price', e.target.value)}/>
            </div>
            <div className='small-2 columns'>
              <span className='postfix' onClick={this.togglePriceInput}>
                {readOnly ?
                  <a style={lockStyle}><i className='fi-lock'></i></a> :
                  <a style={lockStyle}><i className='fi-unlock'></i></a>
                }
              </span>
            </div>
          </div>
        </div>
        <div className='medium-2 columns'>
          <div className='row collapse'>
            <label>Requested Amount</label>
            <div className='small-10 columns'>
              <input type='number' max={availableInventory} min={0} value={requestedQuantity}
                     onChange={(e, val) => handleChange(id, 'requestedQuantity', parseInt(e.target.value))}/>
            </div>
            <div className='small-2 columns'>
            <span className='postfix'>
              <a style={trashStyle} onClick={e => onRemoveOrderItem(id)}><i className='fi-trash'></i></a>
            </span>
            </div>
          </div>
        </div>
        <hr/>
      </div>
    )
  }
}

import React, { Component } from 'react';
import createReactClass from 'create-react-class';

var PasswordResetRequest = createReactClass({
  render: function () {
    return (
      <div>
      <div className="row">
        <br />
        <div className="small-12 columns">
          <h3 className="subheader">Password Reset Request</h3>
        </div>
      </div>
      <div className="row">
        <br />
        <div className="small-12 columns">
           <div className="signup-panel">
             <div className="row collapse">
               <div className="small-2 columns">
                 <span className="prefix"><i className="fi-mail"></i></span>
               </div>
               <div className="small-10  columns">
                 <input name="user[email]" type="text" placeholder="email" />
               </div>
             </div>
              <input className="button right" type="submit" />
           </div>
         </div>
        </div>
      </div>
    )
  }
});

export default PasswordResetRequest

import $ from 'jquery'
import Routes from '../../routes.js.erb'
import { redirectTo } from "../../helpers/redirectTo"
import {snakeCaseHash} from "../../helpers/snakeCaseHash"


function fetchFreightShipmentsSuccess(payload) {
  return {
    type: 'FETCH_FREIGHT_SHIPMENTS_SUCCESS',
    payload
  }
}

export function fetchFreightShipments(params) {
  const url = Routes.freight_shipments_path({format: 'json'})
  const data = snakeCaseHash(params)

  return (dispatch) => {
    $.getJSON(url, data).then(response => {
      dispatch(fetchFreightShipmentsSuccess(response))
    })
  }
}

function fetchFreightShipmentRequest() {
  return {
    type: 'FETCH_FREIGHT_SHIPMENT_REQUEST'
  }
}

function fetchFreightShipmentSuccess(payload) {
  return {
    type: 'FETCH_FREIGHT_SHIPMENT_SUCCESS',
    payload
  }
}

function fetchFreightShipmentError(error) {
  return {
    type: 'FETCH_FREIGHT_SHIPMENT_ERROR',
    error
  }
}

export function fetchFreightShipment(id) {
  const url = Routes.freight_shipment_path(id, {format: 'json'})

  return (dispatch) => {
    dispatch(fetchFreightShipmentRequest())

    $.getJSON(url)
      .then(response => {
        dispatch(fetchFreightShipmentSuccess(response))
      })
      .catch(error => {
        fetchFreightShipmentError(error)
        console.log(error)
      })
  }
}

function createFreightShipmentRequest(payload) {
  return {
    type: 'CREATE_FREIGHT_SHIPMENT_REQUEST',
    payload
  }
}

function createFreightShipmentError(error) {
  return {
    type: 'CREATE_FREIGHT_SHIPMENT_ERROR',
    error
  }
}

export function createFreightShipment(data) {
  const url = Routes.freight_shipments_path({format: 'json'})

  return (dispatch) => {
    dispatch(createFreightShipmentRequest())

    $.ajax({
      url,
      data,
      method: 'POST',
      dataType: 'json'
    }).then(_response => {
      redirectTo(Routes.freight_shipments_path({ format: '' }))
    }).catch(error => {
      console.log(error)
      dispatch(createFreightShipmentError(error.responseJSON))
    })
  }
}

function updateFreightShipmentRequest(payload) {
  return {
    type: 'UPDATE_FREIGHT_SHIPMENT_REQUEST',
    payload
  }
}

function updateFreightShipmentError(error) {
  return {
    type: 'UPDATE_FREIGHT_SHIPMENT_ERROR',
    error
  }
}

export function updateFreightShipment(id, data) {
  const url = Routes.freight_shipment_path(id, {format: 'json'})

  return (dispatch) => {
    dispatch(updateFreightShipmentRequest())

    $.ajax({
      url,
      data,
      method: 'PUT',
      dataType: 'json'
    }).then(_response => {
      redirectTo(Routes.freight_shipments_path({ format: '' }))
    }).catch(error => {
      console.log(error)
      dispatch(updateFreightShipmentError(error.responseJSON))
    })
  }
}
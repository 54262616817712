export function getText(reason, action, actor, recipient, notifiableType, connectedEntity, payload) {

  const textConfig = {
    comment: {
      mentioned: {
        created: "$actor mentioned $recipient in a new comment for $connectedEntity: $payload",
        updated: "$actor mentioned $recipient in an edited comment for $connectedEntity: $payload",
      },
      concerned: {
        created: "$actor made a new comment on $connectedEntity: $payload",
        updated: "$actor edited a comment on $connectedEntity: $payload"
      }
    },
    order: {
      concerned: {
        created: "$actor created a new Order for $connectedEntity",
        updated: "$actor edited an Order for $connectedEntity: $payload"
      }
    }
  }


  let placeholder = textConfig[notifiableType][reason][action]
  const replacements = { $actor: actor, $recipient: recipient, $connectedEntity: connectedEntity, $payload: payload }
  const replaceReducer = (acc, curr) => acc.replace(curr[0], curr[1])
  return Object.entries(replacements).reduce(replaceReducer, placeholder)
}

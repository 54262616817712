import $ from 'jquery'
import Routes from '../../routes.js.erb'
import { snakeCaseHash } from "../../helpers/snakeCaseHash"
import { errorsFromResponse } from "../../helpers/errorsFromResponse"

function fetchCommentsRequest() {
  return {
    type: 'FETCH_COMMENTS_REQUEST'
  }
}

function fetchCommentsSuccess(payload) {
  return {
    type: 'FETCH_COMMENTS_SUCCESS',
    payload
  }
}

export function fetchComments(params) {
  const { commentableType, commentableId,  commentsOffset, commentsPage } = params
  const kaminariOffset = parseInt(commentsOffset)
  const kaminariPage = parseInt(commentsPage) + 1
  const url = `/${commentableType}s/${commentableId}/comments.json?page=${kaminariPage}=1&offset=${kaminariOffset}`

  return (dispatch) => {
    dispatch(fetchCommentsRequest())

    $.getJSON(url)
      .then(response => {
        dispatch(fetchCommentsSuccess({ ...response }))
      })
  }
}

function fetchPinnedCommentsRequest() {
  return {
    type: 'FETCH_PINNED_COMMENTS_REQUEST'
  }
}

function fetchPinnedCommentsSuccess(payload) {
  return {
    type: 'FETCH_PINNED_COMMENTS_SUCCESS',
    payload
  }
}

export function fetchPinnedComments(params) {
  const { commentableType, commentableId, pinnedCommentsOffset, pinnedCommentsPage } = params
  const kaminariOffset = parseInt(pinnedCommentsOffset)
  const kaminariPage = parseInt(pinnedCommentsPage) + 1
  const url = `/${commentableType}s/${commentableId}/pinned_comments.json?page=${kaminariPage}=1&offset=${kaminariOffset}`

  return (dispatch) => {
    dispatch(fetchPinnedCommentsRequest())

    $.getJSON(url)
      .then(response => {
        dispatch(fetchPinnedCommentsSuccess({ ...response }))
      })
  }
}

function ignore() {
  return {
    type: 'IGNORE'
  }
}

function createCommentError(error) {
  return {
    type: 'CREATE_COMMENT_ERROR',
    payload: error
  }
}

export function createComment(comment, callback) {
  const { commentableType, commentableId } = comment
  const url = `/${commentableType}s/${commentableId}/comments.json`
  const data = { comment: snakeCaseHash(comment) }

  delete comment.commentableId

  return (dispatch) => {
    $.ajax({
      url,
      data,
      type: 'POST',
      dataType: 'json'
    }).then(response => {
      if (typeof callback === 'function') callback(response)
      return ignore()
    }).catch(err => {
      dispatch(createCommentError(errorsFromResponse((err.responseJSON))))
    })
  }
}

function updateCommentError(error) {
  return {
    type: 'UPDATE_COMMENT_ERROR',
    payload: error
  }
}

export function updateComment(comment, callback) {
  const { id } = comment
  const url = Routes.comment_path(id, {format: 'json'})
  const data = { comment: snakeCaseHash(comment) }

  delete comment.id
  delete comment.commentableId
  delete comment.commentableType

  return (dispatch) => {
    $.ajax({
      url,
      data,
      type: 'PATCH',
      dataType: 'json'
    }).then(response => {
      if (typeof callback === 'function') callback(response)
      return { type: 'IGNORE' }
    }).catch(err => {
      dispatch(updateCommentError(errorsFromResponse((err.responseJSON))))
    })
  }
}

function fetchCommentTagsSuccess(payload) {
  return {
    type: 'FETCH_COMMENT_TAGS_SUCCESS',
    payload
  }
}

export function fetchCommentTags(_startDate, _endDate) {
  const url = Routes.comment_tags_path()

  return (dispatch) => {
    $.getJSON(url)
      .then(payload => {
        dispatch(fetchCommentTagsSuccess(payload))
      })
      .catch(response => console.log(response))
  }
}

function uploadCommentAttachmentRequest(id) {
  return {
    type: 'UPLOAD_COMMENT_ATTACHMENT_REQUEST',
    payload: id
  }
}

function uploadCommentAttachmentSuccess(id) {
  return {
    type: 'UPLOAD_COMMENT_ATTACHMENT_SUCCESS',
    payload: id
  }
}

export function uploadAttachment(id, url, formData, onSuccess, onError) {
  return (dispatch) => {
    dispatch(uploadCommentAttachmentRequest(id))

    $.ajax({
      url,
      method: 'POST',
      data: formData,
      processData:  false,
      contentType:  false,
      success: (response) => {
        onSuccess(response);
        dispatch(uploadCommentAttachmentSuccess(id))
      },
      error: (response) => {
        onError(response)
      },
    })
  }
}

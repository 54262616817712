import React from 'react'
import { connect } from 'react-redux'
import { clearErrors } from "../../redux/actions/errors"

class ServerErrors extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    const { dispatch, serverErrors } = this.props

    const errors = Object.entries(serverErrors).map(kvPair => {
      return (
        <li key={kvPair[0]}>
          {kvPair[0] + " " + kvPair[1].join('; ')}
        </li>
      )
    })

    return (
      <div className="ServerErrors">
        {errors.length ?
          <div className='error-container'>
            <small className='error'>
              <ul>{errors}</ul>
            </small>
            <span className='close' onClick={() => {dispatch(clearErrors())}} >X</span>
          </div>
        : null }
      </div>
    )
  }
}

const mapStateToProps = () => {
  return {}
}

const connectedServerErrors = connect(mapStateToProps)(ServerErrors)
export default connectedServerErrors


import React, { Component } from 'react'
import { connect } from 'react-redux'
import isEqual from "react-fast-compare"
import moment from 'moment'
import 'moment-timezone'

import Spinner from "react-md-spinner"
import ServerErrors from "../../components/form/ServerErrors"
import OrderFormComponent from './components/OrderFormComponent.jsx'

import { fetchDefaultDeliveryType, fetchDeliveryTypes } from "../../redux/actions/deliveryTypes"
import { fetchOrder, updateOrder } from '../../redux/actions/orders.js'
import { fetchAccount } from '../../redux/actions/accounts.js'
import { fetchAllSkus, fetchSkuInfo } from '../../redux/actions/skus.js'
import { fetchCurrentUser } from '../../redux/actions/currentUser.js'
import { snakeCaseHash } from '../../helpers/snakeCaseHash.js'
import { isCallback } from "../../helpers/isCallback"
import { suggestDatesFor } from "../../helpers/orders/suggestedDatesFor"
import { timeWindowsAttributesFor } from "../../helpers/orders/timeWindowsAttributesFor"
import {base} from "../../timeFormats"


class OrdersEditView extends Component {
  constructor(props) {
    super(props)

    this.state = {
      deliveryTypeId: null,
      timeWindows: [],
      orderItems:  [],
      memo: '',
      ...props.initialState
    }
  }

  componentDidMount() {
    const { dispatch, match } = this.props

    dispatch(fetchCurrentUser())
    dispatch(fetchAllSkus('default'))
    dispatch(fetchOrder(match.params.id, true))
    dispatch(fetchDefaultDeliveryType())
    dispatch(fetchDeliveryTypes())
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.orderItems.length === 0) return

    const orderItems = this.state.orderItems.filter(orderItem => orderItem.skuId != null)
    const { skus, order } = nextProps
    const { account } = order

    const orderItemsUpdated = orderItems.map(orderItem => {
      const sku = skus.find(sku => sku.id === orderItem.skuId)
      if (!sku || !sku.accounts) return orderItem

      const { accounts } = sku
      const { price } = orderItem
      const accountPrice = accounts[account.id].price

      return {
        ...orderItem,
        ...accounts[account.id],
        price: price || accountPrice
      }
    })

    if (!isEqual(orderItemsUpdated, orderItems)) {
      this.setState({ orderItems: orderItemsUpdated })
    }
  }

  onChange = (name, value, callback) => {
    this.setState({ [name]: value }, () => {
      if (callback && isCallback(callback)) {
        callback()
      }
    })
  }

  onSubmit = (values) => {
    const { accountId, deliveryTypeId, timeWindows, orderItems, memo, account, purchaseOrderNumber } = values
    const { dispatch, match } = this.props
    const { status, proofOfDelivery } = this.state
    const { salesperson } = account
    const isDisableAction = (nextParams, prevParams) => {
      const nextStatusBooked = nextParams.status === "booked"
      const prevStatusPending = prevParams.status === "completed"

      return nextStatusBooked && prevStatusPending
    }
    const getDeliveryDate = (collection) => {
      const deliveryDates = (collection.map((requestedTimeWindow) => {
        return moment(requestedTimeWindow.date).toString()
      })).sort((pr, nx) => pr < nx)
      return moment(deliveryDates[0]).format(base)
    }

    const order = snakeCaseHash({
      accountId,
      deliveryTypeId,
      status,
      purchaseOrderNumber,
      proofOfDelivery,
      salespersonId: salesperson.id,
      deliveryDate: getDeliveryDate(timeWindows),
      timeWindowsAttributes: timeWindowsAttributesFor(timeWindows),
      orderLineItemsAttributes: orderItems.map(oI => {
        const { _id, id, skuId, price, requestedQuantity, _destroy } = oI
        return { id, sku_id: skuId, price, quantity: requestedQuantity, _destroy }
      }),
      memo,
    })

    const action = () => {
      dispatch(updateOrder(match.params.id, { order }))
    }

    const confirmationFromUser = () => {
      return confirm('Are you sure you want to update order with a date in the past?')
    }

    const isDeliveryDateInThePast = (timeWindows.filter(timeWindow => {
      return moment(timeWindow.date) <= moment()
    }).length > 0)

    if (isDeliveryDateInThePast && !isDisableAction(order, this.props.order)) {
      if (confirmationFromUser()) action()
    } else {
      action()
    }
  }

  deliveryTypeId(deliveryTypeId, stateDeliveryTypeId) {
    if (!stateDeliveryTypeId) {
      return deliveryTypeId
    } else {
      return stateDeliveryTypeId
    }
  }

  getRequestedTimeWindows(requestedTimeWindows, stateCollection = []) {
    if (stateCollection.length > 0) {
      return stateCollection
    } else {
      return requestedTimeWindows.map(timeWindow => {
        const { id, start, finish, memo, delivery_date } = timeWindow

        return {
          id,
          memo,
          date: delivery_date,
          startTime: start,
          endTime: finish,
          _id: timeWindow.id,
          _destroy: 0
        }
      })
    }
  }

  getOrderItems(collection, stateCollection) {
    if (stateCollection.length > 0) {
      return stateCollection
    } else {
      return collection.map(orderItem => {
        const { id, sku_id, price, quantity } = orderItem

        const { skus, order } = this.props
        const selectedSku = skus.find(sku => sku.id === sku_id)
        const { accounts } = selectedSku

        if (!accounts) {
          return orderItem
        } else {
          const { availableInventory } = accounts[order.account.id]

          return {
            id,
            price,
            availableInventory,
            _id: id,
            skuId: sku_id,
            requestedQuantity: quantity,
          }
        }
      })
    }
  }

  getMemo(memo, stateMemo) {
    if (stateMemo.length > 0) {
      return stateMemo
    } else {
      return memo
    }
  }

  render() {
    const { dispatch, skus, currentUser, order, serverErrors, defaultDeliveryTypes } = this.props
    const { isLoadingSkuInfo, isLoadingSkusInfos, isLoading } = this.props

    if (!order || !order.id || (skus.length === 0)) return null

    const { account, delivery_type, time_windows, order_line_items } = order
    const { warehouse } = account
    const warehouseId = warehouse && warehouse.id
    const suggestedTimeWindows = suggestDatesFor(account.time_windows || [])

    if (!warehouseId) return null

    const deliveryTypeId = this.deliveryTypeId(delivery_type.id, this.state.deliveryTypeId)
    const timeWindows = this.getRequestedTimeWindows(time_windows, this.state.timeWindows)
    const orderItems = this.getOrderItems(order_line_items, this.state.orderItems)
    const memo = this.getMemo(order.memo, this.state.memo)

    const isSubmitting = isLoadingSkuInfo || isLoadingSkusInfos || isLoading

    if (!defaultDeliveryTypes || !deliveryTypeId || !skus || !orderItems || !suggestedTimeWindows || !timeWindows) {
      return <Spinner />
    } else {
      return (
        <div>
          <ServerErrors serverErrors={serverErrors} />
          <OrderFormComponent
            page={this.props.page || 'edit'}
            editMode={true}
            key={account.id}
            accountId={account.id}
            callback={id => dispatch(fetchAccount(id))}
            account={account}
            deliveryTypeId={deliveryTypeId}
            dispatch={dispatch}
            timeWindows={timeWindows}
            suggestedTimeWindows={suggestedTimeWindows}
            orderItems={orderItems}
            skus={skus}
            fetchSkuInfo={skuId => dispatch(fetchSkuInfo(skuId, account.id, order.id))}
            currentUser={currentUser}
            memo={memo}
            isSubmitting={isSubmitting}
            onChange={this.onChange}
            onSubmit={this.onSubmit}
          />
        </div>
      )
    }
  }
}

const mapStateToProps = (state) => {
  return {
    order: state.orders.current.order,
    currentUser: state.currentUser.user,
    skus: state.skus.all || [],
    serverErrors: state.errors.orders.create || {},
    isLoadingSkuInfo: state.skus.loadingSkuInfo,
    isLoadingSkusInfos: state.skus.loadingSkusInfos,
    defaultDeliveryType: state.deliveryTypes.default,
    defaultDeliveryTypes: state.deliveryTypes.all,
    isLoading: state.orders.all.loading
  }
}

const connectedOrdersEditView = connect(mapStateToProps,)(OrdersEditView)
export default connectedOrdersEditView
import $ from 'jquery'
import Routes from '../../routes.js.erb'

function fetchGlsRequest() {
  return {
    type: 'FETCH_GLS_REQUEST',
  }
}

function fetchGlsSuccess(response) {
  return {
    type: 'FETCH_GLS_SUCCESS',
    payload: response,
  }
}

function fetchGlsFailure(response) {
  return {
    type: 'FETCH_GLS_FAILURE',
    error: response.error,
  }
}

export function fetchGls() {
  const url = Routes.gls_path({format: 'json'})

  return (dispatch) => {
    dispatch(fetchGlsRequest());

    $.getJSON(url)
      .then(json => { dispatch(fetchGlsSuccess(json)) })
      .catch(response => dispatch(fetchGlsFailure(response)));
  }
}

const initialstate = {
  all: [],
  loadingSkuInfo: false,
  loadingSkusInfos: false,
}

export function skus(state= initialstate, action) {
  const { payload } = action

  switch (action.type) {
    case 'FETCH_ALL_SKUS_SUCCESS':
      const all = payload.skus.map(sku => sku.attributes)

      return { ...state, all }

    case 'FETCH_SKU_INFO_REQUEST':
      return { ...state, loadingSkuInfo: true }

    case 'FETCH_SKU_INFO_SUCCESS':
      const { id, accountId, price, available_inventory } = payload

      const updatedAll = state.all.map(sku => {
        if (sku.id != id) return sku

        const { accounts } = sku
        const updatedSku =  { ...sku, accounts: { ...accounts, [accountId]: { price, availableInventory: available_inventory }}}

        return updatedSku
      })

      return { ...state, loadingSkuInfo: false, all: updatedAll }

    case 'FETCH_SKUS_INFO_REQUEST':
      return { ...state, loadingSkusInfos: true }

    case 'FETCH_SKUS_INFO_SUCCESS':
      let skusInfoHash = {};

      payload.skus.forEach((info) => { skusInfoHash[info.id] = info })

      const updatedSkus = state.all.map(sku => {
        if (!skusInfoHash[sku.id]) return sku

        const { price, available_inventory } = skusInfoHash[sku.id].attributes
        const { accounts } = sku
        const updatedSku =  { ...sku, accounts: { ...accounts, [payload.accountId]: { price, availableInventory: available_inventory }}}

        return updatedSku
      })

      return { ...state, loadingSkusInfos: false,  all: updatedSkus  }

    default:
      return state
  }
}
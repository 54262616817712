import React, { Component } from 'react'
import { connect } from 'react-redux'
import Paginate from '../../components/paginate'
import moment from 'moment'
import { capitalize, lowerCase } from 'lodash'

import NotificationsSearchForm from './components/NotificationsSearchFormComponent.jsx'
import { fetchNotifications, clearNotificationsOffset }  from '../../redux/actions/notifications.js'
import { fetchCurrentUser } from '../../redux/actions/users.js'
import { getText } from '../../helpers/notifications/getText'
import { truncateWithEllipses } from '../../helpers/truncateText.js'
import {full_with_utc} from "../../timeFormats"

class NotificationsIndexView extends Component {
  constructor(props) {
    super(props)

    this.state = { currentParams: { limit: 50 }, notifications: [] }

    this.searchNotifications = this.searchNotifications.bind(this)
    this.handlePageClick = this.handlePageClick.bind(this)
  }

  componentDidMount() {
    const { currentParams } = this.state
    const { limit } = currentParams
    const { dispatch } = this.props

    dispatch(fetchCurrentUser())
    dispatch(fetchNotifications({ limit, offset: 0, viewContext: 'index' }))
  }

  searchNotifications(params) {
    const { dispatch } = this.props
    this.setState({ currentParams: params }, () => {
      dispatch(clearNotificationsOffset())
      dispatch(fetchNotifications({ ...params, viewContext: 'index' }))
    })
  }

  handlePageClick(offset) {
    const { currentParams } = this.state
    const { dispatch } = this.props

    dispatch(fetchNotifications({ ...currentParams, offset }))
  }

  handleNotificationClick(notification) {
    const url = notification.connected_entity.url + '?focusOn=' + notification.notifiable.id
    window.location.replace(url)
  }

  toggleRead(id) {
    const self = this
    const notificationStatus = this.props.notifications.find(n => n.id === id).read_at
    const toggledReadAction = notificationStatus === null ? '/mark_as_read' : '/mark_as_unread'
    const url = Routes.notification(id, { toggledReadAction })

    $.ajax({
      url,
      method: 'POST',
      dataType: 'json',
      success: function(_response) {
        const { currentParams } = self.state
        const { dispatch, offset } = self.props
        dispatch(fetchNotifications({ ...currentParams, offset }))
      }
    })
  }

  prepareDataForTable(notificationsData) {
    const data = notificationsData
    const columns = [
      {
        Header:     'Id',
        id:         'id',
        accessor:   d => d.id,
        cell:       (val) => <span>val.val</span>,
        width:      50

      },
      {
        Header:     'Timestamp',
        id:         'timestamp',
        accessor:   d => moment(d.created_at, full_with_utc).format(full_12),
        width:      200
      },
      {
        Header:     'Actor',
        id:         'actor',
        accessor:   d => d.actor.name,
        width:      150
      },
      {
        Header:     'Recipient',
        id:         'recipient',
        accessor:   d => d.recipient.name,
        width:      150
      },
      {
        Header:     'Reason',
        id:         'reason',
        accessor:   d => capitalize(d.reason)
      },
      {
        Header:     'Notifiable Type',
        id:         'notifiableType',
        accessor:   d => d.notifiable.type
      },
      {
        Header:     'Notifiable Id',
        id:         'notifiableId',
        accessor:   d => d.notifiable.id
      },
      {
        Header:     'Action',
        id:         'action',
        accessor:   d => capitalize(d.action)
      },

    ]
    return [data, columns]
  }

  render() {
    const { notifications, totalCount, offset, loading, currentUser } = this.props
    const limit = this.state.currentParams.limit
    const [data, columns] = this.prepareDataForTable(notifications)

    const notificationsList = notifications.map(n => {
      const { id, actor, action, reason, notifiable, recipient, connected_entity, read_at } = n
      const text = getText(reason, action, actor.name, recipient.name, lowerCase(notifiable.type), connected_entity.name, truncateWithEllipses(notifiable.summary, 20))
      return (
        <div key={id}>
          <div style={{display: 'inline-block', width: '90%', cursor: 'pointer'}} onClick={(e) => this.handleNotificationClick(n)}>{text}</div>
          <div style={{display: 'inline-block', width: '10%'}}>
            <span onClick={e => this.toggleRead(id)} className="toggle" className="switch round tiny">
              <input type="checkbox" checked={!!read_at} onChange={e => console.log('changed') }/>
              <label></label>
            </span>
          </div>
          <br />
          <br />
        </div>
      )
    })


    return (
      <div className="Notifications">
        {currentUser.id && <NotificationsSearchForm {...this.props} onSubmit={this.searchNotifications} />}
        <br />

        <div className="row">
          <div className="medium-12 columns">
            <Paginate dataName="Notifications" totalCount={totalCount} limit={limit} offset={offset} onPageChange={this.handlePageClick} />
            {notificationsList}
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, ownState) => {
  return {
    loading: state.notifications.all.loading || false,
    notifications: state.notifications.all.notifications || [],
    offset: state.notifications.all.offset || 0,
    totalCount: state.notifications.all.totalCount || 0,
    currentUser: state.currentUser.user,
  }
}

const connectedNotificationsIndex = connect(mapStateToProps,)(NotificationsIndexView)
export default connectedNotificationsIndex

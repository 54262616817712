import { snakeCase } from 'lodash'

export function snakeCaseHash(hash) {
  return Object.entries(hash)
    .reduce((result, keyValPair) => {
      const [key, val] = keyValPair;
      result[snakeCase(key)] = val;
      return result;
    }, {});
}

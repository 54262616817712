import $ from 'jquery'
import Routes from '../../routes.js.erb'

function fetchAccountGroupsRequest() {
  return {
    type: 'FETCH_ACCOUNT_GROUPS_REQUEST',
  }
}

function fetchAccountGroupsSuccess(payload) {
  return {
    type: 'FETCH_ACCOUNT_GROUPS_SUCCESS',
    payload
  }
}

function fetchAccountGroupsFailure(response) {
  return {
    type: 'FETCH_ACCOUNT_GROUPS_FAILURE',
    error: response.error,
  }
}

export function fetchAccountGroups() {
  const url = Routes.account_groups_path({format: 'json'})

  return (dispatch) => {
    dispatch(fetchAccountGroupsRequest());

    $.getJSON(url)
      .then(json => { dispatch(fetchAccountGroupsSuccess(json)) })
      .catch(response => dispatch(fetchAccountGroupsFailure(response)));
  }
}

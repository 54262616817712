import React, { Component } from 'react'
import $ from 'jquery'
import { connect } from 'react-redux'
import Paginate from '../../components/paginate'
import Spinner from 'react-md-spinner'
import Select from 'react-virtualized-select'
import queryString from 'query-string'
import isEqual from 'react-fast-compare'

import AccountsSearchForm from './components/AccountsSearchFormComponent.jsx'
import AccountsTable from './components/AccountsTableComponent.jsx'
import ConfirmationButton from './components/ConfirmationButton.jsx'

import { fetchAccountsFull, fetchAccountsForCSVDownload, clearAccountsFullOffset }  from '../../redux/actions/accounts.js'
import { mergeAccount, rejectMerge } from '../../redux/actions/accounts.js'
import { uniqueBy } from '../../helpers/unique'
import { CSVLink } from "react-csv"

class AccountsIndexView extends Component {
  constructor(props) {
    super(props)

    this.state = {
      currentParams: {
        page: 0,
        limit: 200,
        sortBy: 'name',
        order: 'ASC',
        offset: 0,
        viewContext: 'index',
        currentView: 'standard',
        awaitingMerge: false
      }
    }

    this.searchAccounts = this.searchAccounts.bind(this)
    this.handlePageClick = this.handlePageClick.bind(this)
  }

  fetchAccountsForDownload = () => {
    this.props.dispatch(fetchAccountsForCSVDownload({
      ...this.state.currentParams,
      page: 0,
      offset: 0
    }))
  }

  componentDidMount() {
    const { dispatch } = this.props

    const params = queryString.parse(location.search, {arrayFormat: 'bracket'})
    for (let key in params) {
      const posArray = params[key]
      if (Array.isArray(posArray)) {
        params[key] = posArray.map(e => parseInt(e))
      }
    }

    this.setState({ currentParams: { ...this.state.currentParams, ...params }}, () => {
      dispatch(fetchAccountsFull(this.state.currentParams))
    })
  }

  viewOptions = [
      { label: "Standard",  value: 'standard'},
      { label: "Last Order", value: 'lastOrder'},
      { label: "Last Visit", value: 'lastVisit'},
      { label: "Accounts To Merge", value: 'accountsToMerge'},
  ]

  searchAccounts(params, holdOffset=false) {
    const { dispatch } = this.props
    this.setState({ currentParams: { ...this.state.currentParams, ...params } }, () => {
      if (!holdOffset) {
        dispatch(clearAccountsFullOffset())
      }
      dispatch(fetchAccountsFull(this.state.currentParams))
    })
  }

  handlePageClick(offset, page) {
    const { currentParams } = this.state
    const { dispatch } = this.props

    this.setState({ currentParams: { ...currentParams, page, offset } }, () => {
      dispatch(fetchAccountsFull(this.state.currentParams))
    })
  }

  prepareDataForTable(accountsData) {
    const data = accountsData
    const { currentView } = this.state.currentParams
    const columns = this.viewColumns[currentView]
    return [data, columns]
  }

  onViewChange = (view) => {
    const defaultSort = {
      'standard':  'name',
      'lastOrder': 'days_since_last_order',
      'lastVisit': 'days_since_last_visit',
      'accountsToMerge': 'name'
    }[view.value]

    const { currentParams } = this.state
    const awaitingMerge = view.value === 'accountsToMerge'
    this.setState({ currentParams: {
      ...currentParams,
        currentView: view.value,
        sortBy: defaultSort,
        order: 'ASC',
        awaitingMerge
    }}, () => {
      this.handlePageClick(currentParams.offset, currentParams.page)
    })
  }

  getHeaderProps = (state, rowInfo, column, instance) => {
    const nonSortableColumns = ['parent_account_salesperson']

    return {
      onClick: (e, handleOriginal) => {
        if (nonSortableColumns.indexOf(column.id) > -1) {
          alert('Cannot sort server side by this column yet')
        } else {
          const { currentParams } = this.state
          const { order, sortBy } = currentParams
          const newOrder = sortBy === column.id ? order === 'ASC' ? 'DESC' : 'ASC' : 'ASC'
          this.setState({ currentParams: {
            ...currentParams,
              sortBy: column.id,
              order: newOrder
          }}, () => {
            this.handlePageClick(currentParams.offset, currentParams.page)
          })
        }
        if (handleOriginal) {
          handleOriginal()
        }
      },
      className: (() => {
        const { currentParams } = this.state
        const { sortBy, order } = currentParams
        if (sortBy != column.id) return
        return (order === 'ASC' ? '-sort-asc' : '-sort-desc')
      })()
    }
  }

  handleMerge = (accountId, reverseFlag) => {
    const { dispatch } = this.props
    const onSuccess = (_) => {
      $($(`#modal-merge-${accountId}`).closest('.rt-tr')[0]).hide('slow')
    }
    const onError   = (error)    => alert('Merge Failed: ' + error.responseJSON['account'].join(', '))
    dispatch(mergeAccount({ accountId, reverseFlag, successCallback: onSuccess, errorCallback: onError }))
  }

  handleReject = (accountId) => {
    const { dispatch } = this.props
    const onSuccess = (_) => {
      $($(`#modal-reject-${accountId}`).closest('.rt-tr')[0]).hide('slow')
    }
    const onError   = (error)    => alert('Request Failed: ' + error.responseJSON['account'].join(', '))
    dispatch(rejectMerge({ accountId, successCallback: onSuccess, errorCallback: onError }))
  }

  getColorAndInfoUsingThresholds = (val) => {
    let color = ''
    let info  = ''
    if (val === null) {
      color = '#1779ba' // primary (blue) prospect
      info  = 'Account has never ordered.'
    } else if (val > -Infinity && val <= 21) {
      color = '#3adb76' // success (green) active 21 days
      info  = 'Account has ordered within 21 days.'
    } else if (val > 21 && val <= 30) {
      color = '#ffff00' // warning (yellow) active 30 days
      info  = 'Account has ordered within 30 days.'
    } else if (val > 30 && val <= 90) {
      color = '#ffae00' // orange inactive 30+ days
      info  = 'Account has ordered within 90 days.'
    } else if (val > 90 && val <= 180) {
      color = '#cc4b37' // alert (red) inactive 90+ days
      info  = 'Account has ordered within 180 days.'
    } else if (val > 180) {
      color = '#8a8a8a' // grey inactive 180+ days
      info  = 'Account has not ordered for over 180 days.'
    }
    return [color, info]
  }

  viewColumns = {
    'standard' : [
                    {
                      Header:   '',
                      id:       'color-code',
                      csvKey:   'id',
                      width:     50,
                      accessor:   d => d.days_since_last_order,
                      style:      { whiteSpace: 'normal', textAlign: 'center' },
                      Cell:       (row) =>  {
                        const [color, info] = this.getColorAndInfoUsingThresholds(row.value)
                        return (
                          <span data-tooltip data-options="hover_delay:50; " className="has-tip" title={info}>
                            <span className="dot" style={{backgroundColor: color}}></span>
                          </span>
                        )
                      }
                    },
                    {
                      Header:     'Name',
                      id:         'name',
                      width:      300,
                      csvKey:     'name',
                      accessor:   d => [d.id, d.name],
                      style:      { whiteSpace: 'normal' },
                      Cell:       row => <span><a href={`/accounts/${row.value[0]}`}>{row.value[1]}</a></span>,
                      getHeaderProps: this.getHeaderProps
                    },
                    {
                      Header:     'Prospect Type',
                      id:         'prospect_types.name',
                      csvKey:     'prospect_type.name',
                      width:      200,
                      accessor:   d => d.prospect_type && d.prospect_type.name,
                      getHeaderProps: this.getHeaderProps
                    },
                    {
                      Header:     'Account Type',
                      id:         'account_types.name',
                      csvKey:     'account_type.name',
                      width:      200,
                      accessor:   d => d.account_type.name,
                      getHeaderProps: this.getHeaderProps
                    },
                    {
                      Header:     'Salesperson',
                      width:      200,
                      id:         'users.first_name',
                      csvKey:     'salesperson.name',
                      style:      { whiteSpace: 'normal' },
                      accessor:   d => d.salesperson.name,
                      getHeaderProps: this.getHeaderProps
                    },
                    {
                      Header:     'County',
                      id:         'addresses.county',
                      csvKey:     'address.city',
                      style:      { whiteSpace: 'normal' },
                      accessor:   d => d.address.city,
                      getHeaderProps: this.getHeaderProps
                    },
                    {
                      Header:     'Prospect Source',
                      id:         'prospect_sources.name',
                      csvKey:     'prospect_source.name',
                      width:      200,
                      accessor:   d => d.prospect_source && d.prospect_source.name
                    },
                    {
                      Header:     'Distributor',
                      id:         'distributor.name',
                      csvKey:     'distributor.name',
                      width:      200,
                      accessor:   d => d.distributor && d.distributor.name
                    }
                  ],

    'lastOrder' : [
                    {
                      Header:   '',
                      id:       'color-code',
                      csvKey:   'id',
                      width:     50,
                      accessor:   d => d.days_since_last_order,
                      style:      { whiteSpace: 'normal', textAlign: 'center' },
                      Cell:       (row) =>  {
                        const [color, info] = this.getColorAndInfoUsingThresholds(row.value)
                        return (
                          <span data-tooltip data-options="hover_delay:50; " className="has-tip" title={info}>
                            <span className="dot" style={{backgroundColor: color}}></span>
                          </span>
                        )
                      }
                    },
                    {
                      Header:     'Name',
                      id:         'name',
                      csvKey:     'name',
                      width:      300,
                      accessor:   d => [d.id, d.name],
                      style:      { whiteSpace: 'normal' },
                      Cell:       row => <span><a href={`/accounts/${row.value[0]}`}>{row.value[1]}</a></span>,
                      getHeaderProps: this.getHeaderProps
                    },
                    {
                      Header:     'Prospect Type',
                      id:         'prospect_types.name',
                      csvKey:     'prospect_type.name',
                      width:      200,
                      accessor:   d => d.prospect_type && d.prospect_type.name,
                      getHeaderProps: this.getHeaderProps
                    },
                    {
                      Header:     'Account Type',
                      id:         'account_types.name',
                      csvKey:     'account_type.name',
                      width:      200,
                      accessor:   d => d.account_type.name,
                      getHeaderProps: this.getHeaderProps
                    },
                    {
                      Header:     'Salesperson',
                      width:      200,
                      id:         'users.first_name',
                      csvKey:     'salesperson.name',
                      style:      { whiteSpace: 'normal' },
                      accessor:   d => d.salesperson.name,
                      getHeaderProps: this.getHeaderProps
                    },
                    {
                      Header:     'Days since last order',
                      id:         'days_since_last_order',
                      csvKey:     'days_since_last_order',
                      accessor:   d => d.days_since_last_order,
                      Cell:       row => (row.value != null) ? <span>{row.value}</span> : <span>&#8734;</span>,
                      getHeaderProps: this.getHeaderProps
                    },
                    {
                      Header:     'Avg. days between orders',
                      id:         'average_days_between_orders',
                      csvKey:     'average_days_between_orders',
                      accessor:   d => d.average_days_between_orders,
                      Cell:       row => (row.value != null) ? <span>{row.value}</span> : <span>&#8734;</span>,
                      getHeaderProps: this.getHeaderProps
                    }
                  ],

    'lastVisit' : [
                    {
                      Header:   '',
                      id:       'color-code',
                      width:     50,
                      csvKey:    'id',
                      accessor:   d => d.days_since_last_order,
                      style:      { whiteSpace: 'normal', textAlign: 'center' },
                      Cell:       (row) =>  {
                        const [color, info] = this.getColorAndInfoUsingThresholds(row.value)
                        return (
                          <span data-tooltip data-options="hover_delay:50; " className="has-tip" title={info}>
                            <span className="dot" style={{backgroundColor: color}}></span>
                          </span>
                        )
                      }
                    },
                    {
                      Header:     'Name',
                      id:         'name',
                      csvKey:     'name',
                      width:      300,
                      accessor:   d => [d.id, d.name],
                      style:      { whiteSpace: 'normal' },
                      Cell:       row => <span><a href={`/accounts/${row.value[0]}`}>{row.value[1]}</a></span>,
                      getHeaderProps: this.getHeaderProps
                    },
                    {
                      Header:     'Prospect Type',
                      id:         'prospect_types.name',
                      csvKey:     'prospect_type.name',
                      width:      200,
                      accessor:   d => d.prospect_type && d.prospect_type.name,
                      getHeaderProps: this.getHeaderProps
                    },
                    {
                      Header:     'Account Type',
                      id:         'account_types.name',
                      csvKey:     'account_type.name',
                      width:      200,
                      accessor:   d => d.account_type.name,
                      getHeaderProps: this.getHeaderProps
                    },
                    {
                      Header:     'Salesperson',
                      width:      200,
                      id:         'users.first_name',
                      csvKey:     'salesperson.name',
                      style:      { whiteSpace: 'normal' },
                      accessor:   d => d.salesperson.name,
                      getHeaderProps: this.getHeaderProps
                    },
                    {
                      Header:     'Days since last visit',
                      id:         'days_since_last_visit',
                      csvKey:     'days_since_last_visit',
                      accessor:   d => d.days_since_last_visit,
                      width:      200,
                      Cell:       row => (row.value != null) ? <span>{row.value}</span> : <span>&#8734;</span>,
                      getHeaderProps: this.getHeaderProps
                    },
                    {
                      Header:     'Avg. days between visits',
                      id:         'average_days_between_visits',
                      csvKey:     'average_days_between_visits',
                      accessor:   d => d.average_days_between_visits,
                      Cell:       row => (row.value != null) ? <span>{row.value}</span> : <span>&#8734;</span>,
                      getHeaderProps: this.getHeaderProps
                    }
                  ],
        'accountsToMerge': [
                    {
                      Header : 'Real Account',
                      width  : 300,
                      id     : 'merged_into',
                      accessor: d => d.merged_into && [d.merged_into.id, d.merged_into.name] || [null, 'Does Not Exist'],
                      Cell   : row => <span><a href={row.value[0] ? `/accounts/${row.value[0]}` : null} target='_blank'>{row.value[1]}</a></span>,
                    },
                    {
                      Header : 'Real Account Salesperson',
                      width  : 300,
                      id     : 'parent_account_salesperson',
                      accessor: d => (d.merged_into && d.merged_into.salesperson &&
                                      [d.merged_into.salesperson.first_name, d.merged_into.salesperson.last_name].join(' '))
                                      || 'Does Not Exist'
                    },
                    {
                      Header : 'Duplicate Account',
                      width  : 300,
                      id     : 'name',
                      accessor: d => [d.id, d.name],
                      Cell:       row => <span><a href={`/accounts/${row.value[0]}`} target='_blank'>{row.value[1]}</a></span>,
                    },
                    {
                      Header : 'Duplicate Account Salesperson',
                      width  : 300,
                      id     : 'users.first_name',
                      accessor: d => d.salesperson && d.salesperson.name
                    },
                    {
                      Header : 'Actions',
                      width  : 300,
                      id     : 'actions',
                      accessor: d => d.id,
                      Cell   : row => <section>
                                        <ConfirmationButton
                                          accountId={row.value}
                                          type='merge'
                                          className='tiny'
                                          buttonText='Merge'
                                          confirmationText='MERGE'
                                          onSubmit={e => {
                                            this.handleMerge(row.value)
                                          }}
                                        />
                                        &nbsp;&nbsp;&nbsp;
                                        <ConfirmationButton
                                          accountId={row.value}
                                          type='reject'
                                          className='tiny'
                                          buttonText='Reject'
                                          confirmationText='REJECT'
                                          onSubmit={e => {
                                            this.handleReject(row.value)
                                          }}
                                        />
                                        &nbsp;&nbsp;&nbsp;
                                        <ConfirmationButton
                                          accountId={row.value}
                                          type='reverse-merge'
                                          className='tiny'
                                          buttonText='Reverse Merge'
                                          confirmationText='REVERSE MERGE'
                                          onSubmit={e => {
                                            this.handleMerge(row.value, true)
                                          }}
                                        />
                                      </section>
                    }
          ]
        }

  render() {
    const { currentParams } = this.state
    const { currentView, limit, page } = currentParams
    const defaultCurrentParams = { page: 0, limit: 200, sortBy: 'name', order: 'ASC', offset: 0, viewContext: 'index', currentView: 'standard', awaitingMerge: false }
    const { accounts, totalCount, offset, loading, csvLoading, csvData } = this.props
    const [data, columns] = this.prepareDataForTable(accounts)

    const _headersForCSV   = []
    Object.entries(this.viewColumns).forEach(keyVal => {
      const [view, columns] = keyVal
      const headers = columns.map(el => ({ label: el.Header, key: el.csvKey }))
      if (view != 'accountsToMerge') _headersForCSV.push(...headers)
    })
    const headersForCSV = uniqueBy(_headersForCSV, 'key')

    return (
      <div className="row Accounts">
        <div className="row fullWidth">
          <div className="small-12 columns">
            <h3 className="subheader">Accounts</h3>
          </div>
        </div>
        <div className="row fullWidth">
          <div className="medium-12 columns">
            <label>Select View Type
            <Select
              name="View Type"
              multi={false}
              value={currentView}
              options={this.viewOptions}
              onChange={this.onViewChange}
              placeholder="Report Type"
            />
            </label>
          </div>
        </div>
        { isEqual(currentParams, defaultCurrentParams)
          ? <AccountsSearchForm key={1} {...this.props} currentParams={this.state.currentParams} onSubmit={this.searchAccounts} />
          : <AccountsSearchForm key={2} {...this.props} currentParams={this.state.currentParams} onSubmit={this.searchAccounts} />
        }
        <br />

        <div className="row fullWidth">
          <div className="medium-12 columns">
            <Paginate dataName="Accounts" totalCount={totalCount} limit={parseInt(limit)} page={parseInt(page)} offset={parseInt(offset)} onPageChange={this.handlePageClick}>
              <button className="tiny button" disabled={csvLoading} onClick={() => this.fetchAccountsForDownload()}>Fetch for CSV</button><span> | </span>
              <CSVLink asyncOnClick={true} headers={headersForCSV} disabled={(csvData.length === 0)} data={csvData} className="tiny button">
                Download CSV
              </CSVLink>
            </Paginate>
            { loading
              ? <Spinner />
              : <AccountsTable data={data} columns={columns} limit={Math.min(limit, data.length)} />
            }
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, _ownState) => {
  return {
    csvLoading: state.accounts.csv.loading || false,
    csvData: state.accounts.csv.accounts || [],
    loading: state.accounts.all.loading || false,
    accounts: state.accounts.all.accounts || [],
    offset: state.accounts.all.offset || 0,
    totalCount: state.accounts.all.totalCount || 0,
  }
}

const connectedAccountsIndexView = connect(mapStateToProps,)(AccountsIndexView)
export default connectedAccountsIndexView

import React from 'react'
import { withFormik, Form, Field } from 'formik'
import Yup from "yup"
import ServiceExceptionCategoriesExeptionableSelect from './ServiceExceptionCategoriesExeptionableSelect.jsx'
import {
  createServiceExceptionCategory,
  updateServiceExceptionCategory
} from "../../../redux/actions/serviceExceptionsCategories"

const ServiceExceptionCategoriesForm = ({ values, errors, touched, isSubmitting, setFieldValue, setFieldTouched }) => {
  const onChange = (name, valObj) => {
    setFieldValue(name, valObj.value)
  }

  return (
    <Form className="ServiceExceptionCategoriesForm">
      <div className="row">
        <div className="medium-6 columns">
          <label>Service Exception Category Name
            <Field type="text" name="name" />
            {errors.name && touched.name && <small className="error">{errors.name}</small>}
          </label>
        </div>
        <div className="medium-6 columns">
          <label>Service Exception Category Type
            <ServiceExceptionCategoriesExeptionableSelect
              name="serviceExceptionCategoryType"
              multi={false}
              value={values.serviceExceptionCategoryType}
              onChange={onChange}
              onBlur={setFieldTouched}
              error={errors.serviceExceptionCategoryType}
              touched={touched.serviceExceptionCategoryType}
            />
            {errors.serviceExceptionCategoryType && touched.serviceExceptionCategoryType && <small className="error">{errors.serviceExceptionCategoryType}</small>}
          </label>

        </div>
      </div>
      <div className="row">
        <div className="medium-12 columns">
          <label>Service Exception Category Description
            <Field type="text" name="description" />
            {errors.description && touched.description && <small className="error">{errors.description}</small>}
          </label>
        </div>
      </div>

      <div className="row">
        <div className="small-12 columns">
          <button disabled={isSubmitting}>Submit</button>
        </div>
      </div>
    </Form>
  )
}
export default withFormik({
  mapPropsToValues: (props) => {
    const { serviceExceptionCategory } = props

    if (serviceExceptionCategory) {
      const { description, name, service_exception_category_type } = serviceExceptionCategory

      return {
        name: name || '',
        description: description || '',
        serviceExceptionCategoryType: service_exception_category_type || ''
      }
    }
  },
  enableReinitialize: true,
  validationSchema: Yup.object().shape({
    name: Yup.string().required('Service Exception Category Name is required'),
    serviceExceptionCategoryType: Yup.string().required('Service Exception Category Type is required'),
    description: Yup.string().required("Service Exception Category Description is required")
  }),
  handleSubmit(values, {setSubmitting, props}) {
    const { dispatch,  serviceExceptionCategory } = props

    if (serviceExceptionCategory) {
      dispatch(updateServiceExceptionCategory(serviceExceptionCategory.id, values))
    } else {
      dispatch(createServiceExceptionCategory(values))
    }

    setTimeout(() => setSubmitting(false), 1000)
  }
})(ServiceExceptionCategoriesForm)

import $ from 'jquery'
import Routes from '../../routes.js.erb'
import { redirectTo } from '../../helpers/redirectTo.js'
import { snakeCaseHash } from "../../helpers/snakeCaseHash"

function fetchServiceExceptionCategoriesRequest() {
  return {
    type: 'FETCH_SERVICE_EXCEPTIONS_CATEGORIES_REQUEST'
  }
}

function fetchServiceExceptionCategoriesSuccess(payload) {
  return {
    type: 'FETCH_SERVICE_EXCEPTIONS_CATEGORIES_SUCCESS',
    payload
  }
}

export function fetchServiceExceptionCategories(params = {}) {
  const url = Routes.api_v3_service_exception_categories_path({ ...params, format: 'json'})

  return (dispatch) => {
    dispatch(fetchServiceExceptionCategoriesRequest())

    $.getJSON(url)
      .then(response => {
        dispatch(fetchServiceExceptionCategoriesSuccess({ ...response}))
      })
  }
}

function createServiceExceptionCategorySuccess(payload) {
  return {
    type: 'CREATE_SERVICE_EXCEPTIONS_CATEGORY_SUCCESS',
    payload
  }
}

function createServiceExceptionCategoryError(payload) {
  return {
    type: 'CREATE_SERVICE_EXCEPTIONS_CATEGORY_ERROR',
    payload
  }
}

export function createServiceExceptionCategory(params = {}) {
  const url = Routes.api_v3_service_exception_categories_path({format: 'json'})
  const data = { service_exception_category: snakeCaseHash(params) }

  return (dispatch) => {
    $.ajax({
      url,
      data,
      type: 'POST',
      dataType: 'json'
    }).then(payload => {
      dispatch(createServiceExceptionCategorySuccess(payload))
      redirectTo(Routes.service_exception_category_path(payload.service_exception_category.id))
    }).catch(error => {
      dispatch(createServiceExceptionCategoryError(error.responseJSON))
    })
  }
}

function fetchServiceExceptionCategoryRequest() {
  return {
    type: 'FETCH_SERVICE_EXCEPTIONS_CATEGORY_REQUEST'
  }
}

function fetchServiceExceptionCategorySuccess(payload) {
  return {
    type: 'FETCH_SERVICE_EXCEPTIONS_CATEGORY_SUCCESS',
    payload
  }
}

export function fetchServiceExceptionCategory(id) {
  const url = Routes.api_v3_service_exception_category_path(id, {format: 'json'})

  return (dispatch) => {
    dispatch(fetchServiceExceptionCategoryRequest())

    $.getJSON(url)
      .then(response => {
        dispatch(fetchServiceExceptionCategorySuccess({ ...response}))
      })
  }
}

function updateServiceExceptionCategorySuccess(payload) {
  return {
    type: 'UPDATE_SERVICE_EXCEPTIONS_CATEGORY_SUCCESS',
    payload
  }
}

function updateServiceExceptionCategoryError(payload) {
  return {
    type: 'UPDATE_SERVICE_EXCEPTIONS_CATEGORY_ERROR',
    payload
  }
}

export function updateServiceExceptionCategory(id, params = {}) {
  const url = Routes.api_v3_service_exception_category_path(id, {format: 'json'})
  const data = { service_exception_category: snakeCaseHash(params) }

  return (dispatch) => {
    $.ajax({
      url,
      data,
      type: 'PUT',
      dataType: 'json'
    }).then(payload => {
      dispatch(updateServiceExceptionCategorySuccess(payload))
      redirectTo(Routes.service_exception_category_path(payload.service_exception_category.id))
    }).catch(error => {
      dispatch(updateServiceExceptionCategoryError(error.responseJSON))
    })
  }
}


import $ from 'jquery'
import Routes from '../../routes.js.erb'

function fetchDistributorsSuccess(payload) {
  return {
    type: 'FETCH_DISTRIBUTORS_SUCCESS',
    payload
  }
}

export function fetchDistributors() {
  const url = Routes.distributors_path({format: 'json'})

  return (dispatch) => {
    $.getJSON(url)
      .then(response => {
        dispatch(fetchDistributorsSuccess(response))
      })
  }
}
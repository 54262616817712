import React, { Component } from 'react'
import Select from "react-select/lib/Select"
import {convertToRaw, EditorState} from "draft-js"
import Editor from "draft-js-plugins-editor"
import {connect} from "react-redux"
import {fetchServiceExceptionCategories} from "../../../redux/actions/serviceExceptionsCategories"


class ServiceExceptionInput extends Component {
  constructor(props) {
    super(props)

    this.state = {
      editorState: EditorState.createEmpty(),
      selectValue: ''
    }
  }

  componentDidMount() {
    const { dispatch } = this.props

    dispatch(fetchServiceExceptionCategories())
  }

  onEditorChange = (editorState) => {
    this.setState({
      editorState
    })
  }

  handleOnSelect = (value) => {
    this.setState({
      selectValue: value
    })
  }

  handleSubmit = () => {
    const { onSubmit, serviceExceptionableId, serviceExceptionableType } = this.props
    const { editorState, selectValue } = this.state
    const { serviceExceptionCategoryId } = selectValue
    const description = convertToRaw(editorState.getCurrentContent())['blocks'][0]['text']

    onSubmit({ description, serviceExceptionableId, serviceExceptionableType, serviceExceptionCategoryId })
    setTimeout(() => this.setState({ editorState: EditorState.createEmpty(), selectValue: ''  }), 500)
  }

  render() {
    const { serviceExceptionCategoryOptions } = this.props
    const { selectValue, editorState } = this.state
    const isTextExists = convertToRaw(editorState.getCurrentContent())['blocks'][0]['text'].length > 0
    const disabled = !(!!selectValue && isTextExists)

    const plugins =  []

    const editorStyle = {
      'border': '1px solid grey',
      'marginBottom': '5px',
      'padding': '5px',
      'backgroundColor':  'white',
    }

    const selectStyle = {
      'width': '300px'
    }

    return (
      <div className="comment-input small-12 columns">
        <div className="editor" style={editorStyle}>
          <Editor
            editorState={editorState}
            onChange={this.onEditorChange}
            plugins={plugins}
            ref={(element) => {this.editor = element}}
          />
        </div>
        <div className="left" style={selectStyle} >
          <Select
            placeholder={'Exception category'}
            options={serviceExceptionCategoryOptions}
            value={selectValue}
            onChange={this.handleOnSelect}
          />
        </div>
        <button className="button tiny right" disabled={disabled} onClick={this.handleSubmit}>Submit</button>
      </div>
    )
  }
}

const mapStateToProps = (state, _props) => {
  const serviceExceptionCategories = state.serviceExceptionCategories.current.serviceExceptionCategories
  const serviceExceptionCategoryOptions = serviceExceptionCategories.map(serviceExceptionCategory => {
    return {
      value: serviceExceptionCategory.name,
      label: serviceExceptionCategory.name,
      serviceExceptionCategoryId: serviceExceptionCategory.id
    }
  })

  return {
    serviceExceptionCategories,
    serviceExceptionCategoryOptions
  }
}

const connectedServiceExceptionInput = connect(mapStateToProps,)(ServiceExceptionInput)
export default connectedServiceExceptionInput

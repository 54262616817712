import React, {Component} from "react"
import moment from "moment"
import ServiceExceptionInput from './components/ServiceExceptionInput.jsx'
import { connect } from "react-redux"
import { comment } from "../../timeFormats"
import { createServiceException, fetchServiceExceptions } from "../../redux/actions/serviceExceptions"

class ServiceExceptionsDashboard extends Component {
  constructor(props) {
    super(props)
  }

  componentDidMount() {
    const { dispatch, serviceExceptionableId, serviceExceptionableType } = this.props

    dispatch(fetchServiceExceptions({serviceExceptionableId, serviceExceptionableType}))
  }

  onSubmit = (params) => {
    const { dispatch, serviceExceptionableId, serviceExceptionableType } = this.props

    dispatch(createServiceException(params))

    setTimeout(() => {
      dispatch(fetchServiceExceptions({serviceExceptionableId, serviceExceptionableType}))
    }, 100)
  }

  renderServiceException(exception) {
    const { created_at, description, user } = exception
    const formattedCreateAt = moment(created_at).format(comment)

    return (
      <div className="service-exception panel small-12 columns comment" key={exception.id}>
        <div>
          {description}
        </div>
        <span className="left">
          <em>{user && user.name}</em>
          <span className='ml10'>{formattedCreateAt}</span>
        </span>
        <span className='right'>
           <a className='ml10' href={Routes.service_exception_category_path(exception.service_exception_category.id)}>
             {exception.service_exception_category.name}
           </a>
        </span>
      </div>
    )
  }

  render() {
    const { serviceExceptions, serviceExceptionableId, serviceExceptionableType } = this.props
    const renderedServiceExceptions = serviceExceptions && serviceExceptions.map(exception => this.renderServiceException(exception))

    return (
      <div className="service-exceptions-dashboard">
        <span className='tinyheader'>Service Exception Input</span>
        <ServiceExceptionInput
          serviceExceptionableId={serviceExceptionableId}
          serviceExceptionableType={serviceExceptionableType}
          onSubmit={this.onSubmit}
        />
        <span className='tinyheader'>Service Exceptions</span>
        {renderedServiceExceptions}
      </div>
    )
  }
}

const mapStateToProps = (state, _props) => {
  const { serviceExceptions } = state.serviceExceptions.current

  return {
    serviceExceptions
  }
}

const connectedServiceExceptionsDashboard = connect(mapStateToProps,)(ServiceExceptionsDashboard);
export default connectedServiceExceptionsDashboard
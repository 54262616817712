export function hasRole(roles, targetRole) {
  if (!roles || roles.length === 0) return false

  const role = roles.find(role => role.name === targetRole)

  if (role) {
    return true
  } else {
    return false
  }
}
import React, { Component } from 'react'
import { connect } from 'react-redux'
import Paginate from '../../components/paginate'
import queryString from 'query-string'
import Spinner from 'react-md-spinner'
import { fetchContacts } from '../../redux/actions/contacts.js'

import SearchForm from './components/index/SearchForm.jsx'
import IndexTable from './components/index/IndexTable.jsx'

class ContactsIndexView extends Component {
  constructor(props) {
    super(props)

    const currentParams = {
      currentView: 'standard',
      limit:        100,
      page:         0,
      sortBy:      'id',
      order:       'ASC'
    }

    this.state = { currentParams }
  }

  componentDidMount() {
    const params = queryString.parse(location.search, {arrayFormat: 'bracket'})
    for (let key in params) {
      const posArray = params[key]
      if (Array.isArray(posArray)) {
        params[key] = posArray.map(e => parseInt(e))
      }
    }

    this.fetchContacts(params)
  }

  onChange = (name, value) => {
    const { currentParams } = this.state
    this.setState({ currentParams: { ...currentParams, [name]: value } })
  }

  handlePageClick = (offset, page) => {
    this.fetchContacts({ offset, page })
  }

  fetchContacts = (params) => {
    const { dispatch } = this.props
    const updatedParams = { ...this.state.currentParams, ...params }

    this.setState({ currentParams: updatedParams }, () => {
      dispatch(fetchContacts(updatedParams))
    })
  }

  getHeaderProps = (state, rowInfo, column, instance) => {
    const nonSortableColumns = ['accounts']

    return {
      onClick: (e, handleOriginal) => {
        if (nonSortableColumns.indexOf(column.id) > -1) {
          alert('Cannot sort by this column yet')
        } else {
          const { currentParams } = this.state
          const { order, sortBy } = currentParams
          const newOrder = sortBy === column.id ? order === 'ASC' ? 'DESC' : 'ASC' : 'ASC'
          this.setState({ currentParams: { ...currentParams, sortBy: column.id, order: newOrder } }, () => {
            this.handlePageClick(currentParams.offset, currentParams.page)
          })
        }
        if (handleOriginal) {
          handleOriginal()
        }
      },
      className: (() => {
        const { currentParams } = this.state
        const { sortBy, order } = currentParams
        if (sortBy != column.id) return
        return (order === 'ASC' ? '-sort-asc' : '-sort-desc')
      })()
    }
  }

  viewColumns = {
    'standard': [
      {
        Header:     'Name',
        id:         'first_name',
        width:      200,
        accessor:   d => d.name,
        Cell:       row => <span>{row.value}</span>,
        getHeaderProps: this.getHeaderProps,
      },
      {
        Header:     'Email',
        id:         'email',
        width:      200,
        accessor:   d => d.email,
        Cell:       row => <span>{row.value}</span>,
        getHeaderProps: this.getHeaderProps,
      },
      {
        Header:     'Phone',
        id:         'phone',
        width:      200,
        accessor:   d => d.phone,
        Cell:       row => <span>{row.value}</span>,
        getHeaderProps: this.getHeaderProps,
      },
      {
        Header:     'Accounts',
        id:         'accounts',
        style:      { whiteSpace: 'normal' },
        accessor:   d => d.accounts.map(account => [account.id, account.name]),
        Cell:       row => <span>
                      {row.value.map(el => {
                        return (<a key={el[0]} href={`accounts/${el[0]}`}>{el[1]}</a>)
                      })}
                    </span>,
        getHeaderProps: this.getHeaderProps,
      }
    ]
  }

  render() {
    const { currentParams } = this.state
    const { loading, contacts, offset, totalCount, dispatch } = this.props
    const columns = this.viewColumns[currentParams.currentView]
    const { limit, page } = currentParams

    return (
      <div className='contacts'>
        <div className='row fullWidth'>
          <div className='small-12 columns'>
            <h3 className='subheader'>Contacts</h3>
          </div>
        </div>
        <SearchForm
          dispatch={dispatch}
          onChange={this.onChange}
          onSubmit={() => this.fetchContacts()}
          {...currentParams}
        />
        <div className='row fullWidth'>
          <div className='small-12 columns'>
            <Paginate
              dataName='contacts'
              totalCount={totalCount}
              limit={parseInt(limit)}
              page={parseInt(page)}
              offset={parseInt(offset)}
              onPageChange={this.handlePageClick}
            />
            { loading ?
              <Spinner /> :
              <IndexTable
                key={contacts.length}
                data={contacts}
                columns={columns}
                limit={Math.min(limit, contacts.length)}
              />
            }
          </div>
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    loading: state.contacts.all.loading,
    contacts:  state.contacts.all.contacts,
    offset:  state.contacts.all.offset,
    totalCount: state.contacts.all.totalCount,
  }
}

const connectedContactsIndexView = connect(mapStateToProps,)(ContactsIndexView)
export default connectedContactsIndexView

import $ from 'jquery'
import Routes from '../../routes.js.erb'
import { snakeCaseHash } from '../../helpers/snakeCaseHash.js'
import { redirectTo } from '../../helpers/redirectTo.js'
import { fetchSkusInfo } from "./skus.js"
import { isCallback } from "../../helpers/isCallback"

export function clearOrders() {
  return {
    type: 'CLEAR_ORDERS'
  }
}

function fetchOrdersFullRequest() {
  return {
    type: 'FETCH_ORDERS_FULL_REQUEST'
  }
}

function fetchOrdersSuccess(payload) {
  return {
    type: 'FETCH_ORDERS_FULL_SUCCESS',
    payload
  }
}

export function fetchOrders(params) {
  const url = Routes.orders_path({format: 'json'})
  const data = snakeCaseHash(params)

  return (dispatch) => {
    dispatch(fetchOrdersFullRequest())

    $.getJSON(url, data).then(response => {
      dispatch(fetchOrdersSuccess(response))
    })
  }
}

export function clearOrdersOffset() {
  return {
    type: 'CLEAR_ORDERS_FULL_OFFSET'
  }
}

function fetchOrderSuccess(payload) {
  return {
    type: 'FETCH_ORDER_SUCCESS',
    payload
  }
}

function getOrderSkusIds(order) {
  const { order_line_items } = order

  return  order_line_items.map((lineItem) => {
    return lineItem.sku_id
  })
}

export function fetchOrder(id, isNeedPrefetchSkus = false, callback = null) {
  const url = Routes.order_path(id, {format: 'json'})

  return (dispatch) => {
    $.ajax({
      url,
      method: 'GET',
      dataType: 'json'
    }).then(response => {
      const { order } = response

      dispatch(fetchOrderSuccess(order))

      if (isNeedPrefetchSkus) dispatch(fetchSkusInfo(getOrderSkusIds(order), order))
      if (callback && isCallback(callback)) callback(order)
    }).catch(err => {
      console.log(err)
    })
  }
}

function createOrderRequest() {
  return {
    type: 'CREATE_ORDER_REQUEST'
  }
}

function createOrderError(payload) {
  return {
    type: 'CREATE_ORDER_ERROR',
    payload
  }
}

export function createOrder(orderParams) {
  const url = Routes.orders_path({format: 'json'})
  const data = orderParams

  return (dispatch) => {
    dispatch(createOrderRequest())

    $.ajax({
      url,
      data,
      method: 'POST',
      dataType: 'json'
    }).then(response => {
      const { order } = response
      redirectTo(Routes.order_path(order.id, { format: '' }))
    }).catch(error => {
      console.log(error)
      dispatch(createOrderError(error.responseJSON))
    })
  }
}

function updateOrderRequest() {
  return {
    type: 'UPDATE_ORDER_REQUEST'
  }
}

function updateOrderError(payload) {
  return {
    type: 'UPDATE_ORDER_ERROR',
    payload
  }
}

export function updateOrder(id, orderParams) {
  const url = Routes.order_path(id, {format: 'json'})
  const data = orderParams

  return (dispatch) => {
    dispatch(updateOrderRequest())

    $.ajax({
      url,
      data,
      method: 'PATCH',
      dataType: 'json'
    }).then(response => {
      redirectTo(response.data)
    }).catch(error => {
      console.log(error.responseJSON)
      dispatch(updateOrderError(error.responseJSON))
    })
  }
}

function fetchOrdersForCSVDownloadRequest() {
  return {
    type: 'FETCH_ORDERS_FOR_CSV_DOWNLOAD_REQUEST'
  }
}

function fetchOrdersForCSVDownloadSuccess(payload) {
  return {
    type: 'FETCH_ORDERS_FOR_CSV_DOWNLOAD_SUCCESS',
    payload
  }
}

export function fetchOrdersForCSVDownload(params) {
  const url = Routes.orders_path({format: 'json'})
  const data = snakeCaseHash(params)

  return (dispatch) => {
    dispatch(fetchOrdersForCSVDownloadRequest())

    $.getJSON(url, data)
      .then(payload => {
        dispatch(fetchOrdersForCSVDownloadSuccess(payload))
      })
      .catch(error => console.log(error))
  }
}

function fetchAccountOrdersSuccess(payload) {
  return {
    type: 'FETCH_ACCOUNT_ORDERS_SUCCESS',
    payload
  }
}

export function fetchAccountOrders(params) {
  const { accountId } = params
  const url =  Routes.orders_account_path(accountId, {format: 'json'})

  return (dispatch) => {
    $.getJSON(url)
      .then(response => {
        const { orders } = response
        dispatch(fetchAccountOrdersSuccess(orders))
      })
  }
}

function createOrderAttachmentRequest() {
  return {
    type: 'CREATE_ORDER_ATTACHMENT_REQUEST'
  }
}

function createOrderAttachmentError(payload) {
  return {
    type: 'CREATE_ORDER_ATTACHMENT_ERROR',
    payload
  }
}

export function createOrderAttachment(orderId, params) {
  const url = Routes.order_attachments_path(orderId, {format: 'json'})
  const data = {order: params}

  console.log('data', data)

  return (dispatch) => {
    dispatch(createOrderAttachmentRequest())

    $.ajax({
      url,
      data,
      method: 'POST',
      dataType: 'json'
    }).then(response => {
      redirectTo(response.data)
    }).catch(error => {
      console.log(error.responseJSON)
      dispatch(createOrderAttachmentError(error.responseJSON))
    })
  }
}


const AddressComponent = ({ address }) => {
  return (
    <div>
      <div className="row">
        <div className="medium-6 columns">
          <label>Line 1 (Street Address)
            <input value={address.line1 || ''} readOnly type="text" />
          </label>
        </div>
        <div className="medium-6 columns">
          <label>Line 2 (Apt / Floor / Suite )
            <input value={address.line2 || ''} readOnly type="text" />
          </label>
        </div>
      </div>
      <div className="row">
        <div className="medium-3 columns">
          <label>City
            <input value={address.city || ''} readOnly type="text" />
          </label>
        </div>
        <div className="medium-3 columns">
          <label>State
            <input value={address.state || ''} readOnly type="text" />
          </label>
        </div>
        <div className="medium-3 columns">
          <label>Zip
            <input value={address.zip || ''} readOnly type="text" />
          </label>
        </div>
        <div className="medium-3 columns">
          <label>Country
            <input value={address.country || ''} readOnly type="text" />
          </label>
        </div>
      </div>
      <div className="row">
        <div className="medium-6 columns">
          <label>Neighborhood
            <input value={address.neighborhood || ''} readOnly type="text" />
          </label>
        </div>
        <div className="medium-6 columns">
          <label>County
            <input value={address.county || ''} readOnly type="text" />
          </label>
        </div>
      </div>
    </div>
  )
}

export default AddressComponent
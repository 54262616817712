import FormikSelect from './base/FormikSelect.jsx'
import { capitalize } from 'lodash'

const orderStatusOptions = [
  'pending',
  'booked',
  'approved',
  'shipped',
  'completed',
  'canceled',
]

const OrderStatusSelect = (props) => {
  const options = orderStatusOptions.map(e => {
    return { label: capitalize(e), value: e }
  })
  const allProps = { ...props, options  }
  return (
    <FormikSelect { ...allProps } />
  )
}

export default OrderStatusSelect
import React, { Component } from 'react'
import { connect } from 'react-redux'
import geolib from 'geolib'
import { geolocated } from 'react-geolocated';
import AccountSelect from '../../components/select/AccountSelect.jsx'

import { fetchAccount } from '../../redux/actions/accounts.js'
import { createVisit, fetchPosCords } from '../../redux/actions/visits.js'
import { fetchCurrentUser } from '../../redux/actions/users.js'


class VisitNewView extends Component {
  constructor(props) {
    super(props)

    this.state = { account_id: null, account: {}, currentPosition: {}, currentUser: {} }
  }

  fetchActions = {
    'account_id': fetchAccount,
  }

  onChange = (name, val) => {
    const { dispatch } = this.props
    const fetchAction =  this.fetchActions[name]
    const id = val.value
    fetchAction && dispatch(fetchAction(val.value))

    this.setState({ [name]: val.value })
  }

  componentDidMount() {
    const { dispatch } = this.props
    dispatch(fetchCurrentUser())
  }

  refetchCords = () => {
    const { dispatch } = this.props
    const options = { enableHighAccuracy: true, timeout: 5000, maximumAge: 0 }
    dispatch(fetchPosCords(options))
  }

  onSubmit = () => {
    const { account_id } = this.state
    const { dispatch, currentUser, coords, cords } = this.props
    const { longitude, latitude } = coords

    const visitParams = { account_id, visitor_id: currentUser.id, longitude: longitude || cords.longitude, latitude: latitude || cords.latitude }
    dispatch(createVisit(visitParams))
  }

  render() {
    const { account_id } = this.state
    const { account, currentUser, isGeolocationAvailable, coords, manualFetchedCoords } = this.props

    let longitude, latitude
    if (manualFetchedCoords) {
      longitude = manualFetchedCoords.longitude
      latitude  = manualFetchedCoords.latitude
    } else if (coords) {
      longitude = coords.longitude
      latitude  = coords.latitude
    }

    const { id, address } = account

    return (
        <div>
          <div className="row">
            <div className="small-12 columns">
              <h3 className="subheader">Visit New</h3>
            </div>
          </div>
          <div className="row">
            <div className="small-12 columns">
              <label>Account
                <AccountSelect placeholder="Select an account..." value={account_id} name="account_id" onChange={(e, val) => this.onChange(e, val)} />
              </label>
            </div>
          </div>
          <br />
          <div className="row">
            <div className="small-6 columns">
              <label>Account Sales Person
                <input value={account.salesperson && account.salesperson.name || ''} readOnly name="salesperson" />
              </label>
            </div>
            <div className="small-6 columns">
              {
              <label>Visit Logged By
                <input value={currentUser.name || ''} readOnly name="visitor" />
              </label>
              }
            </div>
          </div>
          <div className="row">
            { isGeolocationAvailable
              ? <DistanceLongLatComponent location={address} long={longitude} lat={latitude} />
              : 'Geolocation not available. Please refresh browser and allow location access'
            }
          </div>
          <br />
          <div className="row">
            <div className="small-6 columns">
              <button className="button small" onClick={this.onSubmit}>Create</button>
            </div>
            <div className="small-6 columns">
              <button className="button small right" onClick={this.refetchCords}>Refetch Position</button>
            </div>
          </div>
        </div>

    )
  }

}

const mapStateToProps = (state) => {
  const { account } = state.accounts.current
  const { visits }  = state

  return {
    currentUser: state.currentUser.user,
    manualFetchedCoords: visits.manualFetchedCoords,
    account
  }
}

const options = { positionOptions: { enableHighAccuracy: false, maximumAge: 0, timeout: 2000 }, userDecisionTimeout: 5000 }
const connectedVisitNewView = connect(mapStateToProps,)(geolocated(options)(VisitNewView))
export default connectedVisitNewView


const DistanceLongLatComponent = ({ long, lat, location }) => {

  let distance
  if (location && long && lat) {
    distance = geolib.getDistance({latitude: lat, longitude: long}, {latitude: location.latitude, longitude: location.longitude}, {unit: 'ft'})
  }

  return (
    <section>
      <div className="small-4 columns">
        <label>Longitude
        <input readOnly type="text" value={long || ''} />
        </label>
      </div>
      <div className="small-4 columns">
        <label>Latitude
        <input readOnly type="text" value={lat || ''} />
        </label>
      </div>
      <div className="small-4 columns">
        <label>Distance in Ft
        <input readOnly type="text" value={distance || ''} />
        </label>
      </div>
    </section>
  )
}


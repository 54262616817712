export function currentUser(state = { user: {} }, action) {
  const { payload } = action

  switch (action.type) {
    case 'FETCH_CURRENT_USER_SUCCESS':
      const { user } = payload
      return { user }

    default:
      return state;
  }
}



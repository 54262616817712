import CustomSelect from './base/CustomSelect.jsx'
import { fetchWarehouses } from '../../redux/actions/warehouses.js';

const WarehouseSelect = (props) => {
  const allProps = { ...props, fetchOptionsAction: () => fetchWarehouses(), selector: (state) => state["kpi"]["warehouses"] }
  return (
    <CustomSelect { ...allProps } />
  )
}

export default WarehouseSelect

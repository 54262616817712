import ReactTable from 'react-table'
import moment from 'moment'
import { full_with_utc, full_12 } from '../../../timeFormats'

const AccountVisitsTableComponent = (props) => {
  const { visits, defaultPageSize } = props
  const columns = [
    {
      Header:     'Id',
      id:         'id',
      accessor:   d => d.id,
      Cell:       row => <span><a href={`/visits/${row.value}`}>{row.value}</a></span>,
      width:      50
    },
    {
      Header:     'Created At',
      id:         'timestamp',
      accessor:   d => moment(d.created_at, full_with_utc).format(full_12),
      width:      200
    },
    {
      Header:     'Account',
      id:         'account',
      accessor:   d => d.account.name,
      width:      150
    },
    {
      Header:     'Salesperson',
      id:         'salesperson',
      accessor:   d => d.salesperson.name,
      width:      150
    },
    {
      Header:     'Visitor',
      id:         'visitor',
      accessor:   d => d.visitor.name,
      width:      150
    },
  ]

  return (
    <div className="small-12 columns">
      <ReactTable className='-striped -highlight' data={visits} columns={columns} showPagination={false} defaultPageSize={defaultPageSize} />
    </div>
  )
}

export default AccountVisitsTableComponent

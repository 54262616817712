import React, { Component } from 'react'
import { connect } from 'react-redux'
import isEqual from 'react-fast-compare'
import moment from "moment"
import 'moment-timezone'

import OrderFormComponent from './components/OrderFormComponent.jsx'
import ServerErrors from "../../components/form/ServerErrors"

import { fetchAccount, preloadAccount } from '../../redux/actions/accounts.js'
import { fetchDeliveryTypes, fetchDefaultDeliveryType } from '../../redux/actions/deliveryTypes'
import { fetchAllSkus, fetchSkuInfo } from '../../redux/actions/skus.js'
import { createOrder, fetchOrder } from '../../redux/actions/orders.js'
import { fetchCurrentUser } from '../../redux/actions/currentUser.js'
import { snakeCaseHash } from '../../helpers/snakeCaseHash.js'
import { isCallback } from '../../helpers/isCallback'
import { searchToObject } from "../../helpers/searchToObject"
import { base } from "../../timeFormats"
import { suggestDatesFor } from "../../helpers/orders/suggestedDatesFor"
import { timeWindowsAttributesFor } from "../../helpers/orders/timeWindowsAttributesFor"


class OrdersNewView extends Component {
  constructor(props) {
    super(props)

    const search = searchToObject(props.location.search)

    const state = {
      accountId: null,
      deliveryTypeId: null,
      timeWindows: [],
      orderItems:  [],
      purchaseOrderNumber: null,
      memo: ''
    }

    if (search.hasOwnProperty('accountId')) state['accountId'] = parseInt(search['accountId'])
    if (search.hasOwnProperty('orderId')) state['orderId'] = parseInt(search['orderId'])

    this.state = state
  }

  componentDidMount() {
    const { dispatch } = this.props
    const { accountId, orderId } = this.state

    dispatch(fetchDefaultDeliveryType())
    dispatch(fetchDeliveryTypes())
    dispatch(fetchCurrentUser())
    dispatch(fetchAllSkus('default'))

    if (accountId) {
      dispatch(fetchAccount(accountId, (account) => {
        this.fetchAccountCallback(account)
        dispatch(preloadAccount({
          id: accountId,
          name: account.name
        }))
      }))
    }
    if (orderId) {
      dispatch(fetchOrder(orderId, true, (order) => {
        const { order_line_items } = order
        this.setState({
          orderItems: order_line_items.map((orderItem, index) => {
            const { price, quantity, sku_id } = orderItem

            return {
              _id: index,
              requestedQuantity: quantity,
              skuId: sku_id,
              price
            }
          })
        })
      }))
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.orderItems.length === 0) return

    const orderItems = this.state.orderItems.filter(orderItem => orderItem.skuId != null)
    const { account, skus } = nextProps

    if (!account.id || !orderItems.length > 0) return

    const orderItemsUpdated = orderItems.map(orderItem => {
      const sku = skus.find(sku => sku.id === orderItem.skuId)

      if (!sku || !sku.accounts) return orderItem

      return { ...orderItem, ...sku.accounts[account.id] }
    })

    if (!isEqual(orderItemsUpdated, orderItems)) {
      this.setState({ orderItems: orderItemsUpdated })
    }
  }

  onChange = (name, value, callback) => {
    this.setState({ [name]: value }, () => {
      if (callback && isCallback(callback)) {
        callback()
      }
    })
  }

  onSubmit = (values) => {
    const { accountId, timeWindows, deliveryTypeId, orderItems, purchaseOrderNumber, memo } = values
    const { dispatch, account, defaultDeliveryType, currentUser } = this.props
    const { salesperson } = account
    const getDeliveryTypeId = () => {
      if (deliveryTypeId === undefined) {
        return defaultDeliveryType.id
      } else {
        return deliveryTypeId
      }
    }
    const getDeliveryDate = (collection) => {
      const deliveryDates = (collection.map((requestedTimeWindow) => {
        return moment(requestedTimeWindow.date).toString()
      })).sort((pr, nx) => pr < nx)
      return moment(deliveryDates[0]).format(base)
    }

    const order = snakeCaseHash({
      accountId,
      purchaseOrderNumber,
      deliveryTypeId: getDeliveryTypeId(),
      deliveryDate: getDeliveryDate(timeWindows),
      salespersonId: salesperson.id,
      userId: currentUser.id,
      timeWindowsAttributes: timeWindowsAttributesFor(timeWindows),
      orderLineItemsAttributes: orderItems.map(oI => {
        const { skuId, price, requestedQuantity } = oI
        return { sku_id: skuId, price, quantity: requestedQuantity }
      }),
      memo
    })

    const action = () => {
      dispatch(createOrder({ order }))
    }

    const confirmationFromUser = () => {
      return confirm('Are you sure you want to create order with a date in the past?')
    }

    const isDeliveryDateInThePast = (timeWindows.filter(timeWindow => {
      return moment(timeWindow.date) <= moment()
    }).length > 0)

    if (isDeliveryDateInThePast) {
      if (confirmationFromUser()) action()
    } else {
      action()
    }
  }

  soonestDate = (timeWindows) => {
    const compareFunction = (prev, next) => {
      const today = moment()

      if (today.diff(prev.date, 'days') > today.diff(next.date, 'days')) return -1;
      if (today.diff(prev.date, 'days') < today.diff(next.date, 'days')) return 1;

      return 0;
    }

    return timeWindows.sort(compareFunction)[0]
  }

  fetchAccountCallback = (account) => {
    const suggestedDates = suggestDatesFor(account.time_windows || [])
    const defaultTimeWindow = {
      startTime: '09:00',
        endTime: '17:00',
        date: moment().add(1,'days').format(base),
    }

    let timeWindows
    switch (suggestedDates.length) {
      case 0:
        timeWindows = [defaultTimeWindow]
        break;
      case 1:
        timeWindows = suggestedDates
        break;
      default:
        timeWindows = [this.soonestDate(suggestedDates)]
        break;
    }

    this.setState({ timeWindows })
  }

  render() {
    const {accounts, skus, defaultDeliveryType, defaultDeliveryTypes } = this.props

    if (!defaultDeliveryTypes || !defaultDeliveryType || !skus || !accounts) return null

    const { accountId, deliveryTypeId, memo, timeWindows, orderItems, purchaseOrderNumber } = this.state
    const { dispatch, account,  currentUser, serverErrors, isLoadingSkuInfo, isLoadingSkusInfos, isLoading } = this.props

    const isSubmitting = isLoadingSkuInfo || isLoadingSkusInfos || isLoading
    const suggestedTimeWindows = suggestDatesFor(account.time_windows || [])

    return (
      <div>
        <ServerErrors serverErrors={serverErrors} />
        <OrderFormComponent
          page={'new'}
          key={account.id}
          accountId={accountId || account.id}
          onChange={this.onChange}
          fetchAccount={id => dispatch(fetchAccount(id, this.fetchAccountCallback))}
          account={account}
          deliveryTypeId={deliveryTypeId || defaultDeliveryType.id}
          dispatch={dispatch}
          timeWindows={timeWindows}
          suggestedTimeWindows={suggestedTimeWindows}
          orderItems={orderItems}
          skus={skus}
          currentUser={currentUser}
          fetchSkuInfo={skuId => dispatch(fetchSkuInfo(skuId, account.id))}
          memo={memo}
          purchaseOrderNumber={purchaseOrderNumber}
          isSubmitting={isSubmitting}
          onSubmit={this.onSubmit}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    account: state.accounts.current.account,
    accounts: state.accounts.all.accounts,
    currentUser: state.currentUser.user,
    skus: state.skus.all || [],
    serverErrors: state.errors.orders.create || {},
    isLoadingSkuInfo: state.skus.loadingSkuInfo,
    isLoadingSkusInfos: state.skus.loadingSkusInfos,
    defaultDeliveryType: state.deliveryTypes.default,
    defaultDeliveryTypes: state.deliveryTypes.all,
    isLoading: state.orders.all.loading
  }
}

const connectedOrdersNewView = connect(mapStateToProps,)(OrdersNewView)
export default connectedOrdersNewView

import React, { Component } from 'react'
import { connect } from 'react-redux'
import ServiceExceptionsDashboard from '../serviceExceptions/ServiceExceptionsDashboard.jsx'
import CommentsDashboard from '../../components/comments/CommentsDashboard.jsx'

import { fetchCurrentUser } from "../../redux/actions/users.js"
import { fetchOrder } from "../../redux/actions/orders.js"
import { parseParams } from "../../helpers/parseParams"
import { fetchPayments } from "../../redux/actions/payments"


class OrdersShowView extends Component {
  constructor(props) {
    super(props)

    const { match } = this.props

    this.state = { orderId: match.params.id }
  }

  componentDidMount() {
    const { dispatch } = this.props
    const { orderId } = this.state
    dispatch(fetchOrder(orderId))
    dispatch(fetchPayments(orderId))
    dispatch(fetchCurrentUser())

    const params = parseParams(location.search, { 'focusOn': { parseAs: 'int' } })
    const focusOnComment = params['focusOn']

    if (focusOnComment) {
      this.setState({ focusOnComment })
    }
  }

  render() {
    const { orderId, focusOnComment } = this.state

    return (
      <div className='order-comments'>
        <ServiceExceptionsDashboard serviceExceptionableId={orderId} serviceExceptionableType={'Order'} />
        <span className='tinyheader'>Comment Input</span>
        <CommentsDashboard commentableType='order' commentableId={orderId} focusOn={focusOnComment} />
      </div>
    )
  }
}

const mapStateToProps = (state, _props) => {
  const currentUser = state.currentUser.user
  const { order } = state.orders.current

  return {
    currentUser,
    order
  }
}
const connectedOrdersShowIndex = connect(mapStateToProps,)(OrdersShowView)
export default connectedOrdersShowIndex
import $ from 'jquery'
import Routes from '../../routes.js.erb'

function fetchContactTypesRequest() {
  return {
    type: 'FETCH_CONTACT_TYPES_REQUEST',
  }
}

function fetchContactTypesSuccess(payload) {
  return {
    type: 'FETCH_CONTACT_TYPES_SUCCESS',
    payload
  }
}

function fetchContactTypesFailure(response) {
  return {
    type: 'FETCH_CONTACT_TYPES_FAILURE',
    error: response.error,
  }
}

export function fetchContactTypes() {
  const url = Routes.contact_types_path({format: 'json'})

  return (dispatch) => {
    dispatch(fetchContactTypesRequest());

    $.getJSON(url)
      .then(json => { dispatch(fetchContactTypesSuccess({ contactTypes: json })) })
      .catch(response => dispatch(fetchContactTypesFailure(response)));
  }
}

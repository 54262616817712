const initialState = {
  all: {
    loading: false,
    freightShipments: [],
    offset: 0,
    totalCount: 0
  },
  current: {},
  errors: {},
  loading: false
}

export function freightShipments(state = initialState, action) {
  const { payload } = action

  switch (action.type) {
    case 'FETCH_FREIGHT_SHIPMENTS_SUCCESS':
      const { freight_shipments, offset, count} = payload
      return { ...state, all: { ...state.all, freightShipments: freight_shipments, totalCount: count, offset } }

    case 'FETCH_FREIGHT_SHIPMENT_SUCCESS':
      return { ...state, current: payload }

    default:
      return state
  }
}
const initialState = {
  current: { 
    account: { properties: {} },
    activities: [],
    orders: [],
    salesperson: {}
  },
  all: {
    notifications: [],
    offset: 0,
    totalCount: 0
  }
}

export function notifications(state = initialState, action) {
  const { payload } = action
  const { current, all } = state

  switch (action.type) {
    case 'FETCH_NOTIFICATIONS_REQUEST':
      return { ...state, all: { ...all, loading: true } };

    case 'FETCH_NOTIFICATIONS_SUCCESS':
      const { notifications, count, offset } = payload
      return { ...state, all: { notifications, totalCount: count, offset, loading: false } };

    case 'CLEAR_NOTIFICATIONS_OFFSET':
      return { ...state, all: { ...all, offset: 0, totalCount: 0 } };

    default:
      return state;
  }
}



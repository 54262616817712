import React, { Component } from 'react'
import Payments from './components/Payments.jsx'
import { connect } from 'react-redux'
import { fetchOrder } from "../../redux/actions/orders"
import { isCallback } from "../../helpers/isCallback"
import { paymentMethods } from "../../constants"
import { snakeCaseHash } from "../../helpers/snakeCaseHash"
import { createPayments } from "../../redux/actions/payments"


class PaymentsNewViewView extends Component {
  constructor(props) {
    super(props)

    this.state = {
      paymentsAttributes: []
    }
  }

  componentDidMount() {
    const { dispatch, match } = this.props

    dispatch(fetchOrder(match.params.id))
  }

  handleChanges(index, field, value, callback) {
    const { paymentsAttributes } = this.state

    paymentsAttributes[index][field] = value

    if (field === 'paymentMethod' && paymentMethods['cash']) {
      paymentsAttributes[index].checkNumber = null
    }

    const updatedPaymentsAttributes = paymentsAttributes.filter((payment) => payment._destroy !== true)
    this.setState({ paymentsAttributes: updatedPaymentsAttributes }, () => {
      if (callback && isCallback(callback)) {
        callback()
      }
    })
  }

  handleAddPayment() {
    const { account } = this.props
    const { paymentsAttributes } = this.state
    const paymentTemplate = {
      accountId: account.id,
      amount: 0,
      paymentMethod: 10,
      checkNumber: null,
      checkImage: null,
      _id: paymentsAttributes.length + 1
    }
    const updatedPaymentsAttributes = paymentsAttributes.push(paymentTemplate)

    this.setState({ [paymentsAttributes]: updatedPaymentsAttributes })
  }

  onSubmit() {
    const { paymentsAttributes } = this.state
    const { dispatch, order } = this.props

    const payments = {
      payments_attributes: paymentsAttributes.map((payment) => {
        payment['payment_applications_attributes'] = [
          {
            amount: payment.amount,
            order_id: order.id
          }
        ]

        return snakeCaseHash(payment)
      })
    }

    dispatch(createPayments(payments, order.id))
  }

  render() {
    const { order, account } = this.props
    const { paymentsAttributes } = this.state

    if (!order || !account) return null

    const { total, current, past_due } = JSON.parse(account.ar_summary)
    const { order_line_items_total } = order
    const reducer = (total, payment) => {
      const { amount } = payment
      if (isNaN(amount)) {
        return 0
      } else {
        return total + amount
      }
    }
    const paymentReceived = paymentsAttributes.reduce(reducer, 0)
    const disabled = paymentsAttributes.some(attribute => (attribute.amount === 0) || isNaN(attribute.amount) || attribute.checkImage === null)

    return (
      <div className="PaymentsNewViewView">
        <div className="row">
          <div className="small-12 columns">
            <a href={Routes.orders_path(order)}>
              <h3>{account.name}</h3>
            </a>
            <h4 className="subheader">
              <a href={Routes.order_path(order.id)}>
                Order #{order.invoice_number} ({order.status})
              </a>
            </h4>
          </div>
        </div>
        <div className="row">
          <div className="medium-4 columns">
            <label>Past Due</label>
            <input readOnly type='text' value={past_due} />
          </div>
          <div className="medium-4 columns">
            <label>Current Due</label>
            <input readOnly type='text' value={current} />
          </div>

          <div className="medium-4 columns">
            <label>Total</label>
            <input readOnly type='text' value={total} />
          </div>
        </div>

        <div className="row">
          <div className="medium-offset-4 medium-4 columns">
            <label>Current Invoice</label>
            <input readOnly type='text' value={order_line_items_total} />
          </div>

          <div className="medium-4 columns">
            <label>Payment received</label>
            <input readOnly type='text' value={paymentReceived} />
          </div>
        </div>

        <div className="row">
          <div className="medium-offset-8 medium-4 columns">
            <button className="button tiny right" onClick={this.handleAddPayment.bind(this)}>
              Add Payment
            </button>
          </div>
        </div>

        <div className="row">
          <div className="small-12 columns">
            <Payments
              paymentsAttributes={paymentsAttributes}
              handleChanges={this.handleChanges.bind(this)}
            />
          </div>
        </div>

        <div className="row">
          {paymentsAttributes.length > 0 ?
          <div className="medium-offset-8 medium-4 columns">
            <button className="button tiny right"
                    disabled={disabled}
                    onClick={this.onSubmit.bind(this)}>
              Submit
            </button>
          </div>
            : null}
        </div>
      </div>
    )
  }
}

const mapStateToProps = (state, _props) => {
  const order = state.orders.current.order
  const account = order && order.account

  return {
    order,
    account
  }
}

const connectedPaymentsNewViewView = connect(mapStateToProps,)(PaymentsNewViewView)
export default connectedPaymentsNewViewView
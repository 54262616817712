import React, { Component } from 'react'
import CustomSelect from './base/CustomSelect.jsx'
import { fetchAccounts } from '../../redux/actions/accounts.js'

const AccountSelect = (props) => {
  const { dispatch, options } = props
  const viewContext = options && options.view || 'quick'
  const defaultSelector = (state) => state["kpi"]["accounts"]
  const selector = props.selector || defaultSelector
  const handleKeyDown = (event) => {
    const q = event.currentTarget.getElementsByTagName('input')[0].value

    if (q.length === 0) return

    dispatch(fetchAccounts(viewContext, q))
  }

  const allProps = {
    ...props,
    selector,
    onKeyDown: handleKeyDown,
  }

  return (
    <CustomSelect { ...allProps } />
  )
}

export default AccountSelect
import React, {Component} from "react"
import Paginate from "../../../components/paginate"
import {CSVLink} from "react-csv"
import Spinner from "react-md-spinner"
import OrdersIndexTableComponent from "./OrdersIndexTableComponent"
import {uniqueBy} from "../../../helpers/unique"

class OrdersIndexTable extends Component {
  viewColumns = {
    'standard': [
      {
        Header:     'Order Date',
        id:         'delivery_date',
        csvKey:     'delivery_date',
        width:      200,
        accessor:   d => d.delivery_date,
        Cell:       row => <span>{row.value}</span>,
      },
      {
        Header:     'Invoice No.',
        id:         'invoice',
        csvKey:     'invoice_number',
        width:      200,
        accessor:   d => d.invoice_number,
        Cell:       row => <span><a href={Routes.order_path(row.original.id)}>{row.value}</a></span>,
      },
      {
        Header:     'Account Name',
        id:         'accounts.name',
        csvKey:     'account.name',
        width:      200,
        accessor:   d => d.account.name,
        Cell:       row => <span>{row.value}</span>,
      },
      {
        Header:     'Warehouse Name',
        id:         'warehouses.name',
        csvKey:     'warehouse.name',
        width:      200,
        accessor:   d => d.warehouse.name,
        Cell:       row => <span>{row.value}</span>,
      },
      {
        Header:     'Status',
        id:         'status',
        csvKey:     'status',
        width:      200,
        accessor:   d => d.status,
        Cell:       row => <span>{row.value}</span>,
      },
      {
        Header:     'Order Type',
        id:         'order_types.name',
        csvKey:     'order_type',
        width:      200,
        accessor:   d => d.order_type && d.order_type.name,
        Cell:       row => <span>{row.value}</span>,
      },
      {
        Header:     'Delivery Type',
        id:         'delivery_types.name',
        csvKey:     'delivery_type',
        width:      200,
        accessor:   d => d.delivery_type && d.delivery_type.name,
        Cell:       row => <span>{row.value}</span>,
      },
      {
        Header:     'Credit Hold',
        id:         'accounts.credit_hold',
        csvKey:     'credit_hold',
        width:      100,
        accessor:   d => d.credit_hold,
        Cell:       row => <span>{row.value}</span>,
      },
      {
        Header:     'Total',
        id:         'total',
        csvKey:     'total',
        width:      200,
        accessor:   d => d.total,
        Cell:       row => <span>${row.value}</span>
      },
    ]
  }

  constructor(props) {
    super(props)
  }

  prepareDataForTable = () => {
    return [this.props.orders, this.viewColumns['standard']]
  }

  prepareHeadersForTable = () => {
    const _headersForCSV = []

    Object.entries(this.viewColumns).forEach(keyVal => {
      const [_view, columns] = keyVal
      const headers = columns.map(el => ({ label: el.Header, key: el.csvKey }))
      _headersForCSV.push(...headers)
    })

    return uniqueBy(_headersForCSV, 'key')
  }

  render() {
    const { limit, page, offset, totalCount, loading, csvLoading, csvData, fetchOrders, handlePageChange, orders } = this.props
    const [data, columns] = this.prepareDataForTable()
    const headersForCSV = this.prepareHeadersForTable()
    const ids = orders.map(order => order.id)

    if (data.length === 0) return null

    return(
      <div className="row fullWidth">
        <div className="medium-12 columns">
          <Paginate
            dataName="Orders"
            totalCount={totalCount}
            limit={parseInt(limit)}
            page={parseInt(page)}
            offset={parseInt(offset)}
            onPageChange={handlePageChange}
          >
            <button
              className="tiny button"
              disabled={csvLoading}
              onClick={() => fetchOrders()}
            >
              Fetch for CSV
            </button>
            <span> | </span>
            <CSVLink
              asyncOnClick={true}
              headers={headersForCSV}
              disabled={(csvData.length === 0)}
              data={csvData}
              className="tiny button"
            >
              Download CSV
            </CSVLink>
            <span> | </span>
            <a href={Routes.orders_invoices_path({ids: ids})}
              className="tiny button"
              disabled={csvLoading}
            >
              View Invoices
            </a>
            <span> | </span>
            <a href={Routes.orders_invoices_path({ids: ids, format: 'pdf'})}
              className="tiny button"
              disabled={csvLoading}
            >
              Download Invoices
            </a>
          </Paginate>
          {loading ?
            <Spinner /> :
            <OrdersIndexTableComponent
              data={data}
              columns={columns}
              limit={Math.min(limit, data.length)}
            />
          }
        </div>
      </div>
    )
  }
}

export default OrdersIndexTable

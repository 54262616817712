import React, { Component } from 'react'
import { connect } from 'react-redux'
import queryString from 'query-string'
import Select from 'react-virtualized-select'
import OrdersIndexFilters from "./components/OrderIndexFilters.jsx"
import OrdersIndexTable from "./components/OrderIndexTable"
import { fetchOrders, clearOrdersOffset, clearOrders } from '../../redux/actions/orders.js'
import { fetchOrdersForCSVDownload } from "../../redux/actions/orders"


class OrdersIndexView extends Component {
  constructor(props) {
    super(props)

    const viewOptions = [
      { label: 'Standard',     value: 'standard' },
      { label: 'Search by Id', value: 'ids' },
      { label: 'Search Purchase Order Number', value: 'purchaseOrderNumbers' }
    ]

    const defaultParams = {
      page:       0,
      limit:      200,
      offset:     0,
      creditHold: false,
      sortBy:     'id',
      order:      'ASC',
      viewContext: 'index',
      currentView: 'standard'
    }

    this.state = { currentParams: { ...defaultParams }, viewOptions }
  }

  componentDidMount() {
    const { dispatch } = this.props

    const params = queryString.parse(location.search, {arrayFormat: 'bracket'})
    for (let key in params) {
      const posArray = params[key]
      if (Array.isArray(posArray)) {
        params[key] = posArray.map(e => parseInt(e))
      }
    }

    this.setState({ currentParams: { ...this.state.currentParams, ...params }}, () => {
      dispatch(fetchOrders(this.state.currentParams))
    })
  }

  onViewChange = (view) => {
    const { currentParams } = this.state
    const { dispatch } = this.props

    this.setState({ currentParams: { ...currentParams, currentView: view.value }}, () => {
      dispatch(clearOrders())
    })
  }

  handlePageChange = (offset, page) => {
    const { currentParams } = this.state
    const { dispatch } = this.props

    this.setState({ currentParams: { ...currentParams, page, offset } }, () => {
      dispatch(fetchOrders(this.state.currentParams))
    })
  }

  fetchOrders = () => {
    this.props.dispatch(fetchOrdersForCSVDownload({
      ...this.state.currentParams,
      page: 0,
      offset: 0
    }))
  }

  prepareRequestParams = (params) => {
    const { currentParams } = this.state
    const { sortBy, order,  currentView } = currentParams

    let requestParams;
    switch (currentView) {
      case "standard":
        requestParams = {
          ...currentParams,
          accountIds: params.accountIds,
          accountTypeIds: params.accountTypeIds,
          creditHold: params.creditHold,
          deliveryTypeIds: params.deliveryTypeIds,
          endDate: params.endDate,
          limit: params.limit,
          orderStatusIds: params.orderStatusIds,
          salespersonIds: params.salespersonIds,
          startDate: params.startDate,
          warehouseIds: params.warehouseIds,
          page: 0,
          offset: 0
        }
        break;
      case "ids":
        requestParams = {
          sortBy,
          order,
          currentView,
          ids: params.ids,
          limit: 200,
          page: 0,
          offset: 0
        }
        break;
      case "purchaseOrderNumbers":
        requestParams = {
          sortBy,
          order,
          currentView,
          purchaseOrderNumber: params.purchaseOrderNumber,
          limit: 200,
          page: 0,
          offset: 0
        }
        break;
    }

    return requestParams;
  }

  onChange = (name, value) => {
    const { currentParams } = this.state
    this.setState({ currentParams: { ...currentParams, [name]: value } })
  }

  onSubmit = (params, holdOffset = false) => {
    const { dispatch } = this.props
    const currentParams = this.prepareRequestParams(params)

    this.setState({ currentParams }, () => {
      if (!holdOffset) dispatch(clearOrdersOffset())
      dispatch(fetchOrders(currentParams))
    })
  }

  render() {
    const { currentParams, viewOptions } = this.state
    const { limit, page, offset, currentView } = this.state.currentParams
    const { orders, totalCount, loading, csvLoading, csvData, dispatch } = this.props

    return (
      <div className='row orders'>
        <div className='row fullWidth'>
          <div className='small-12 columns'>
            <h3 className='subheader'>Orders</h3>
          </div>
        </div>
        <div className='row fullWidth'>
          <div className='medium-12 columns'>
            <label>Select View Type
            <Select
              name='View Type'
              multi={false}
              value={currentView}
              options={viewOptions}
              onChange={this.onViewChange}
            />
            </label>
          </div>
        </div>

        <OrdersIndexFilters
          currentView={currentView}
          currentParams={currentParams}
          dispatch={dispatch}
          onSubmit={this.onSubmit}
        />

        <OrdersIndexTable
          limit={limit}
          page={page}
          offset={offset}
          orders={orders}
          totalCount={totalCount}
          loading={loading}
          csvLoading={csvLoading}
          csvData={csvData}
          currentView={currentView}
          fetchOrders={this.fetchOrders}
          handlePageChange={this.handlePageChange}
        />
      </div>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    csvLoading: state.orders.csv.loading,
    csvData: state.orders.csv.orders,
    loading: state.orders.all.loading,
    orders:  state.orders.all.orders,
    offset:  state.orders.all.offset,
    totalCount: state.orders.all.totalCount,
  }
}

const connectedOrdersIndex = connect(mapStateToProps,)(OrdersIndexView)
export default connectedOrdersIndex
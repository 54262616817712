const initialState = {
  current: { 
    account: { properties: {} },
    activities: [],
    orders: [],
    salesperson: {},
    loading: false
  },
  all: {
    accounts: [],
    offset: 0,
    totalCount: 0
  },
  csv: {
    laoding: false,
    accounts: []
  }
}

export function accounts(state = initialState, action) {
  const { payload } = action
  const { current, all } = state

  switch (action.type) {
    case 'FETCH_ACCOUNT_ORDERS_SUCCESS':
      const currentWithUpdatedOrders = { ...current, orders: payload }
      return { ...state, current: currentWithUpdatedOrders };

    case 'FETCH_ACTIVITIES_SUCCESS':
      const currentWithUpdatedActivities = { ...current, activities: payload }
      return { ...state, current: currentWithUpdatedActivities };

    case 'FETCH_COMMENTS_SUCCESS':
      const currentWithUpdatedComments = { ...current, comments: payload }
      return { ...state, current: currentWithUpdatedComments };

    case 'FETCH_ACCOUNT_SUCCESS':
      const { attributes } = payload
      const currentWithUpdatedAccount = { ...current, account: attributes }
      return { ...state, current: currentWithUpdatedAccount };

    case 'FETCH_ACCOUNT_FAILURE':
      return { ...state, error: 'fetch accounts failure' };

    case 'FETCH_SALESPERSON_SUCCESS':
      const currentWithUpdatedSalesperson = { ...current, salesperson: payload }
      return { ...state, current: currentWithUpdatedSalesperson };

    case 'FETCH_ACCOUNTS_FULL_REQUEST':
      return { ...state, all: { ...all, loading: true } };

    case 'FETCH_ACCOUNTS_FULL_SUCCESS':
      const { accounts, count, offset } = payload
      const _accounts = accounts.map(account => {
        return { ...account.attributes }
      })
      return { ...state, all: { accounts: _accounts, totalCount: count, offset, loading: false } };

    case 'FETCH_ACCOUNTS_FOR_CSV_DOWNLOAD_REQUEST':
      return { ...state, csv: { loading: true } }

    case 'FETCH_ACCOUNTS_FOR_CSV_DOWNLOAD_SUCCESS':
      return { ...state, csv: { loading: false, accounts: payload.accounts.map(account => ({ ...account.attributes })) } }

    case 'CLEAR_ACCOUNTS_FULL_OFFSET':
      return { ...state, all: { ...all, offset: 0, totalCount: 0 } };

    case 'FETCH_ACCOUNT_TIME_WINDOWS_REQUEST':
      return { ...state, current: { ...current, loading: true } };

    case 'FETCH_ACCOUNT_TIME_WINDOWS_SUCCESS':
      const { account_time_windows } = payload
      const { account } = current

      const updatedAccountWithTimeWindows = { ...account, time_windows: account_time_windows }
      const updatedCurrentWithTimeWindows = { ...current, loading: false, account: updatedAccountWithTimeWindows}

      return { ...state, current: updatedCurrentWithTimeWindows }

    default:
      return state;
  }
}



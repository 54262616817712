import $ from 'jquery'

export default function ConfirmationButton({ accountId, type, buttonText, confirmationText, className, onSubmit }) {
  const inputId = ['input', type, accountId].join('-')
  const modalId = ['modal', type, accountId].join('-')

  const handleSubmit = (e) => {
    e.preventDefault()
    const $input = $('#'+inputId)
    const $modal = $('#'+modalId)

    const text   = $input.val()
    if (text === confirmationText) {
      onSubmit()
      $modal.foundation('reveal', 'close')
      $('.reveal-modal-bg').css('display', 'none')
    } else {
      alert('Dismissing')
      $modal.foundation('reveal', 'close')
      $('.reveal-modal-bg').css('display', 'none')
    }
    $input.val('')
  }


  return (
    <span>
      <a href='#' data-reveal-id={modalId} className='button tiny'>{buttonText}</a>
      <div id={modalId} className='reveal-modal confirmationDialog' data-reveal aria-labelledby='modalTitle' aria-hidden='true' role='dialog'>
        <h4>Type {confirmationText} and Submit to continue</h4>
        <form action="#" onSubmit={handleSubmit}>
          <input id={inputId}></input>
          <input className='button tiny' type='submit' value='Submit' />
        </form>
      </div>
    </span>
  )
}
